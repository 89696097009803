import React from 'react';
import { computed, observable, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { Grid } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import * as models from 'models';
import { inject, WithUserStore, WithToastStore } from 'stores';
import Api, { getErrorMsg, ApiResponse } from 'api';

import UserDetailsPanel from 'components/UserDetailsPanel';
import AddressPanel from 'components/AddressPanel';
import DevicesPanel from 'components/DevicesPanel';
import BanksPanel from 'components/BanksPanel';
import AffiliatePanel from 'components/AffiliatePanel';

import CardsPanel from 'components/CardsPanel';

import styles from './styles';
import { AxiosResponse } from 'axios';
import { Dialog } from '@mui/material';
import SearchAndSelectModal from './SearchAndSelectModal/SearchAndSelectModal';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';

import { Masonry } from '@mui/lab';
import * as Kyc from 'models/Kyc';

enum DebitCardStatus {
  CREATING = 'creating',
  REJECTED = 'rejected',
  APPROVED = 'approved',
  CONDITIONAL = 'conditional',
  UPGRADED = 'upgraded',
}

interface Card {
  id: number;
  accountBrand: string;
  cardBrand: string;
  processor: string;
  status: DebitCardStatus;
  balance: string;
  payoutInterval: string;
}
interface InfoProps extends WithStyles<typeof styles>, WithUserStore, WithToastStore {
  userId: number;
  user?: models.User;
  handleUserChanged: (u: models.User) => void;
  canViewWallet?: boolean;
}

/** The content of the info tab of the user details page */
@inject('userStore', 'toastStore')
@observer
class Info extends React.Component<InfoProps> {
  constructor(props: InfoProps) {
    super(props);
    makeObservable(this);
  }
  /** The user's banks */
  @observable banks?: models.BankAccount[];

  /** The user's devices */
  @observable devices?: models.Device[];

  /** The user's cards */
  @observable cards?: Card[];
  @observable loadingCards = true;

  @observable public payoutInterval: string = '';

  @observable public primaryCardId?: number;
  @observable public primaryBankAccountId?: number;

  /** Affiliate object for this user */
  @observable public affiliate?: models.Affiliate;

  @observable public showSearchAndSelectModal = false;

  @observable KycStatus?: Kyc.IKycStatus = undefined;

  /** Is authenticated user an admin? */
  @computed public get isAdmin(): boolean {
    return this.props.userStore!.isAdmin;
  }

  /** Fetches the user's banks */
  @action.bound public getBanks = flow(function* (this: Info) {
    const resp = yield Api.core.getUserBanks(this.props.userId);
    this.banks = resp.data && resp.data.data;
  });

  /** Fetches the user's devices */
  @action.bound public getDevices = flow(function* (this: Info) {
    const resp = yield Api.core.getUserDevices(this.props.userId);
    this.devices = resp.data && resp.data.data;
  });

  @action.bound public getCards = flow(function* (this: Info) {
    try {
      this.loadingCards = true;
      const resp = yield Api.core.getUserCards(this.props.userId);
      let cards = undefined;
      if (resp.data.data && resp.data.data.length) {
        cards = resp.data.data;
      }
      this.cards = cards;
    } catch (e: any) {
      this.cards = undefined;
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.loadingCards = false;
    }
  });

  @action.bound public getKycStatus = flow(function* (this: Info) {
    try {
      const resp = yield Api.core.getUserKycStatus(this.props.userId);
      this.KycStatus = resp?.data?.data;
    } catch (e: any) {
      this.KycStatus = undefined;
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.loadingCards = false;
    }
  });

  @action.bound public fetchPrimaryWallet = flow(function* (this: Info) {
    try {
      const resp = yield Api.tips.getPrimaryWallet(this.props.userId);
      const primaryWallet = resp?.data?.data;
      if (primaryWallet?.debitCardId) {
        this.primaryCardId = primaryWallet.debitCardId;
        this.primaryBankAccountId = undefined;
      }
      if (primaryWallet?.bankAccountId) {
        this.primaryBankAccountId = primaryWallet.bankAccountId;
        this.primaryCardId = undefined;
      }
      if (primaryWallet?.payoutInterval) {
        this.payoutInterval = primaryWallet.payoutInterval;
      }
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  });

  /** Try to fetch affiliate user object by userId */
  @action.bound public getAffiliate = async () => {
    try {
      const resp: AxiosResponse<ApiResponse<models.Affiliate>> =
        await Api.marketing.getAffiliateForUser(this.props.userId);
      this.affiliate = resp?.data?.data;
    } catch (error) {
      this.props.toastStore?.error(getErrorMsg(error));
    }
  };

  @action.bound private fetchPayoutDestinations() {
    this.fetchPrimaryWallet();
    this.getBanks();
    this.getCards();
    this.getKycStatus();
  }

  @action.bound private openShowAndSelectModal() {
    this.showSearchAndSelectModal = true;
  }

  @action.bound private closeShowAndSelectModal() {
    this.showSearchAndSelectModal = false;
  }

  @action.bound public resetKycAttempsLeft = async () => {
    try {
      await Api.core.resetUserKycAttempsLeft(this.props.userId);
      this.props.toastStore!.push({
        type: 'success',
        message: 'KYC attempts restored successfully',
      });
    } catch (err: any) {
      const errMsg = err.response?.data?.error?.message || 'An error has occurred';
      this.props.toastStore!.push({ type: 'error', message: errMsg });
    } finally {
      this.getKycStatus();
    }
  };

  componentDidMount() {
    if (this.isAdmin) {
      this.getAffiliate();
      this.fetchPayoutDestinations();
      this.getDevices();
    }
  }

  render() {
    const { user, handleUserChanged, userId } = this.props;
    return (
      <>
        <Grid container direction={'row'} spacing={3}>
          <Masonry columns={2} spacing={3} style={{ margin: 0 }}>
            <UserDetailsPanel editable={false} handleUserChanged={handleUserChanged}>
              {user}
            </UserDetailsPanel>
            <AddressPanel addressEditable={false}>{user}</AddressPanel>
            {this.isAdmin && <DevicesPanel devices={this.devices} />}
            {this.isAdmin && (
              <>
                <CardsPanel
                  userId={userId}
                  loading={this.loadingCards}
                  primaryCardId={this.primaryCardId}
                  payoutInterval={this.payoutInterval}
                  refreshCards={this.fetchPayoutDestinations}
                  updateKycStatus={this.resetKycAttempsLeft}
                  kycStatus={this.KycStatus}>
                  {this.cards}
                </CardsPanel>
                <BanksPanel
                  userId={userId}
                  refreshBanks={this.fetchPayoutDestinations}
                  primaryBankAccountId={this.primaryBankAccountId}>
                  {this.banks}
                </BanksPanel>
              </>
            )}
            {this.affiliate && (
              <AffiliatePanel
                affiliate={this.affiliate}
                onUpdateAffiliate={this.getAffiliate}
                user={user}
              />
            )}
            <Dialog
              PaperProps={{ style: { borderRadius: 16 } }}
              className={this.props.classes.dialog}
              open={this.showSearchAndSelectModal}>
              <SearchAndSelectModal
                userId={userId}
                close={this.closeShowAndSelectModal}
                toastStore={this.props.toastStore!}
              />
            </Dialog>
          </Masonry>
        </Grid>
        {this.isAdmin && <PlusFabButton onClick={this.openShowAndSelectModal} />}
      </>
    );
  }
}

export default withStyles(styles)(Info);
