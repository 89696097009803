/** Represents a single paymentMethod */
export interface PaymentMethod {
  id: number;
  accountId?: number;
  processorCustomerId?: string;
  processorSourceId?: string;
  brand?: string;
  lastFour?: string;
  validThru?: string;
  isDefault?: string;
  isActive?: boolean | null;
  createdAt?: string;
  updatedAt?: string;
  country?: string;
}

export enum PaymentMethodAction {
  ADD = 'Add',
  EDIT = 'Edit',
}
