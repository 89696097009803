import theme from 'containers/App/theme';

export const selectBasicStyles = {
  display: 'inline-flex',
  '& .MuiBox-root': {
    border: 'none',
    borderRadius: 0,
    height: 'auto',
    padding: 0,
  },
  '& .MuiFormControl-root': {
    padding: 0,
  },
  '& .MuiSelect-root': {
    color: theme.palette.text.secondary,
    fontSize: 14,
    '&:focus': {
      background: 'none',
    },
  },
  '& .MuiInputBase-input': {
    marginRight: theme.spacing(1),
  },
  '& .select-icon': {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
};

export const invoiceTypeTextStyles = {
  color: theme.palette.text.secondary,
  fontSize: 14,
};
