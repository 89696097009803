import { Box, Grid, Link, Typography, MenuItem, FormControl, Tooltip } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Api, { ApiResponse, getErrorMsg } from 'api';
import { AxiosResponse } from 'axios';
import CartPreview from 'components/CartPreview';
import DP from 'components/DashPanel';
import LocationSearch from 'components/LocationSearch';
import PaymentMethodsPanel from 'components/PaymentMethodsPanel';
import UpDownCounter from 'components/UpDownCounter';
import { Circle } from 'mdi-material-ui';
import { action, computed, flow, observable, toJS, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as models from 'models';
import moment, { Moment } from 'moment';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { paths } from 'routes';
import { inject, WithModalStore, WithToastStore, WithUserStore } from 'stores';
import styles, { useStyles } from './styles';

import MomentUtils from '@date-io/moment';
import { EmptyPanelMessage } from 'components/EmptyPanelMessage/EmptyPanelMessage';
import Button from 'components/Button/Dialog/Button';
import clsx from 'clsx';
import OutlinedInput from 'components/Input/OutlinedInput';
import { ACL, EInvoiceItemType, INVOICE_ITEM_TYPES } from 'types';
import * as Dialog from 'components/Dialog/Dialog';
import { EDateFormat } from 'utils/helper';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { copyTextToClipboard } from 'services';
import { SALES_TEAM_EMAIL, SALES_TEAM_PHONE } from 'utils/constants';

enum EDialog {
  ADD_LICENSES = 'add_licenses',
  CANCEL_LICENSE = 'cancel_license',
  DEVICE_INVENTORY = 'device_inventory',
  EXTEND_LICENSE = 'extend_license',
  CHANGE_PRODUCT = 'change_product',
}

enum ECopyToClipboad {
  EMAIL = 'email',
  PHONE_NUMBER = 'phone_number',
}

/**
 * Represents a single license in the list
 * @param {models.License[]} children Array of licenses
 * @param {(license: models.License) => void} cancelLicense Cancel license callback
 * @param {(license: models.License) => void} activateLicense Activate license callback
 * @param {(license: models.License) => void} extendLicense Extend license callback
 * @param {(license: models.License) => void} changeLicensePaymentMethod Change license
 *    payment method callback. This action is available only if auth user is admin
 *    and there are more then one available payment methods for this account
 */
const LicenseListItem = observer(
  ({
    license,
    cancelLicense,
    activateLicense,
    extendLicense,
    changeLicensePaymentMethod,
    changeLicenseProduct,
  }: {
    license: models.License;
    cancelLicense: (license: models.License) => void;
    activateLicense: (license: models.License) => void;
    extendLicense?: (license: models.License) => void;
    changeLicensePaymentMethod?: (license: models.License) => void;
    changeLicenseProduct?: (license: models.License) => void;
  }) => {
    const isLicenseExpired = new Date() > new Date(license.expiresAt);
    const expiryText = isLicenseExpired ? 'Expired on' : 'Expires on';
    const formattedDate = moment(license.expiresAt).format('MMM DD, YYYY');
    const paymentMethodText = license.paymentMethod
      ? `${license.paymentMethod.brand} ${license.paymentMethod.lastFour}`
      : 'No payment method assigned';
    const productText = license.product ? license.product.name : 'Product not assigned';
    const secondaryText = `${productText} | ${paymentMethodText}`;
    const tertiaryText = `License ${expiryText} ${formattedDate}`;
    const hasPaymentMethod = license.paymentMethod !== null;
    const canLicenseBeActivated =
      !isLicenseExpired && hasPaymentMethod && license.status !== 'active';
    const canLicenseBeCanceled = license.status === 'active';
    const canLicenseBeExtended = extendLicense && license.status === 'active';
    const classes = useStyles();
    const menu = [];
    if (changeLicensePaymentMethod) {
      menu.push({
        label: 'Change payment method',
        onClick: () => changeLicensePaymentMethod(license),
      });
    }

    if (changeLicenseProduct) {
      menu.push({
        label: 'Change product',
        onClick: () => changeLicenseProduct(license),
      });
    }

    if (canLicenseBeExtended) {
      menu.push({
        label: 'Extend',
        onClick: () => {
          extendLicense!(license);
        },
      });
    }
    if (canLicenseBeCanceled) {
      menu.push({
        label: <Typography color="error">Cancel</Typography>,
        onClick: () => cancelLicense(license),
      });
    }
    if (canLicenseBeActivated) {
      menu.push({
        label: 'Activate',
        onClick: () => activateLicense(license),
      });
    }

    return (
      <DP.ListItem
        key={license.id}
        icon={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ fontSize: '21px' }}>
            <Circle fontSize="inherit" color={license.status === 'active' ? 'primary' : 'error'} />
          </Box>
        }
        primary={
          <Link
            component={RouterLink}
            to={paths.locationDetails(license.location.id!)}
            color="textPrimary"
            className={classes.textFirst}>
            {license.location.name}
          </Link>
        }
        secondary={
          <React.Fragment>
            <Typography className={classes.textSecondary}>{secondaryText}</Typography>
            <Typography className={classes.textTertiary}>{tertiaryText}</Typography>
          </React.Fragment>
        }
        menu={menu.length ? menu : undefined}
      />
    );
  },
);

interface LicensesPanelProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithModalStore,
    WithUserStore {
  accountId: number;
  changePaymentMethodEnabled: boolean;
  sendPurchaseOrder?: boolean;
  billingEntities: models.BillingEntity[];
  refreshNextPayments?: () => void;
}

/**
 * Shows list of licenses for a specific account
 */
@inject('toastStore', 'modalStore', 'userStore')
@observer
class LicensesPanel extends React.Component<LicensesPanelProps> {
  constructor(props: LicensesPanelProps) {
    super(props);
    makeObservable(this);
  }

  private panelName = 'Licenses';

  /** The search text */
  @observable private search = '';

  @observable public inProgress = true;

  @observable private licenses?: models.License[];

  /** Whether the dialog is open */
  @observable private openAddLicenseDialog = false;

  /** Whether the cancel license dialog is open */
  @observable private openCancelLicenseDialog = false;

  /** If the device status change suggestion dialog is open*/
  @observable private openDeviceStatusDialog = false;

  /** Whether the dialog for extending a license is open */
  @observable private licenseToExtend: null | models.License = null;

  /** Whether the extending licenses API action is in progress */
  @observable private extendingLicense = false;

  /** Currently selected license to be canceled */
  @observable private licenseToCancel: null | models.License = null;

  /** Selected reason for canceling the license */
  @observable public cancellationReason: null | models.CancellationReason = null;
  @observable public customCancellationReason?: string;

  /** The cart object */
  @observable private cart?: models.Cart;

  /** Whether we are currently deleting a cart item */
  @observable private deletingCartItem = false;

  /** Whether we are currently adding a location */
  @observable private addingLocation = false;

  /** Whether we are currently sending a purchase order */
  @observable private sendingPurchaseOrder = false;

  /** The number of kiosks currently selected */
  @observable private numberOfKiosks = 1;

  /** The location that's currently selected when adding licenses */
  @observable private location: models.Location | null = null;

  @observable private products?: models.Product[];
  @observable private productsAvailable?: models.Product[];

  @observable private selectedAvailableProductId?: number;
  @observable private selectedProductId?: number;
  @observable private selectedInvoiceType?: EInvoiceItemType;
  @observable private licenseToChangeProduct: models.License | undefined;

  @observable private openChangeProductDialog = false;
  @observable private loadingProductsAvailable = false;

  @observable private currentDialog?: EDialog = undefined;

  @observable isAdmin: boolean = this.props.userStore!.scope.kind === 'admin';

  @action.bound private closeDialogChangeProduct() {
    this.openChangeProductDialog = false;
  }

  /** Licenses filtered by the search string */
  @computed private get filteredLicenses() {
    return (
      this.licenses &&
      this.licenses.filter((license) =>
        license.location.name!.toLowerCase().includes(this.search.toLowerCase()),
      )
    );
  }

  @computed private get paymentMethods() {
    return this.props.billingEntities;
  }

  /** A total count of the licenses */
  @computed private get count() {
    return this.licenses && this.licenses.length;
  }

  @action.bound public getLicensesForAccount = flow(function* (this: LicensesPanel) {
    try {
      this.inProgress = true;
      const resp = yield Api.billing.getLicensesForAccount(this.props.accountId);
      this.licenses = resp.data.data;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.inProgress = false;
    }
  });

  /** On license cancel event handler */
  @action.bound public cancelLicense = flow(function* (this: LicensesPanel) {
    try {
      if (this.licenseToCancel && this.cancellationReason) {
        const reason =
          this.cancellationReason === models.CancellationReason.OTHER
            ? this.customCancellationReason
            : this.cancellationReason;
        yield Api.billing.cancelLicense(this.licenseToCancel.id, reason);
        const canceledLicense = this.licenses!.find((e) => e.id === this.licenseToCancel!.id);
        canceledLicense!.status = 'canceled';
        this.props.toastStore!.success('License canceled successfully');
        this.closeCancelLicense();
        setTimeout(() => {
          this.openDialogDeviceStatus();
        }, 1000);
      }
    } catch (err: any) {
      this.props.toastStore!.error(getErrorMsg(err));
    }
  });

  /** On license activate event handler */
  @action.bound public activateLicense = flow(function* (
    this: LicensesPanel,
    license: models.License,
  ) {
    try {
      const modalResp = yield this.props.modalStore!.confirm(
        'Activate License',
        `Are you sure you want to activate the license for ${license.location!.name}?`,
        { confirmLabel: 'Yes', cancelLabel: 'No' },
      );
      if (modalResp) {
        yield Api.billing.activateLicense(license.id);
        const activatedLicense = this.licenses!.find((e) => e.id === license.id);
        activatedLicense!.status = 'active';
        this.props.toastStore!.success('License successfully reactivated');
      }
    } catch (err: any) {
      this.props.toastStore!.error(getErrorMsg(err));
    }
  });

  /** Updates the search text */
  @action.bound private updateSearch(e: React.ChangeEvent<HTMLInputElement>) {
    this.search = e.target.value;
  }

  @action.bound private onPaymentMethodClicked = flow(function* (
    this: LicensesPanel,
    licenseId: number,
    paymentMethod: { id: number },
  ) {
    const billingEntity = this.props.billingEntities.find(
      (be) => be.paymentMethod.id === paymentMethod.id,
    );
    const licensePatch = {
      billingEntityId: billingEntity && billingEntity.id,
    };
    yield Api.billing.patchLicense(licenseId, licensePatch);
    const license = this.licenses!.find((e) => e.id === licenseId);
    license!.paymentMethod = paymentMethod;
  });

  /** Deletes a cart item */
  @action.bound private deleteCartItem = flow(function* (
    this: LicensesPanel,
    item: models.CartItem,
  ) {
    try {
      // Cart should be defined at this point, but we still check to avoid
      // possible runtime errors
      if (!this.cart) {
        return;
      }
      this.deletingCartItem = true;
      const resp = yield Api.billing.deleteFromCart(this.cart.accountId, item.id);
      this.cart = resp.data.data;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.deletingCartItem = false;
    }
  });

  /** Opens the add licenses dialog */
  @action.bound private openAddLicenses() {
    this.currentDialog = EDialog.ADD_LICENSES;
    this.openAddLicenseDialog = true;
    this.initCart();
  }

  /** Opens the add licenses dialog */
  @action.bound private openCancelLicense(license: models.License) {
    this.currentDialog = EDialog.CANCEL_LICENSE;
    this.openCancelLicenseDialog = true;
    this.licenseToCancel = license;
  }

  /** Opens the extend licenses dialog */
  @action.bound private openExtendLicense(license: models.License) {
    this.currentDialog = EDialog.EXTEND_LICENSE;
    this.licenseToExtend = toJS(license);
  }

  /** Closes the extend licenses dialog */
  @action.bound private closeExtendLicense() {
    this.licenseToExtend = null;
  }

  /** Updates the expiresAt date for the license locally */
  @action.bound private updateLicenseExpiresAt(m: Moment | null) {
    if (this.licenseToExtend && m) {
      this.licenseToExtend.expiresAt = m.toISOString();
    }
  }

  /** Extends the license by calling the API with the new expiration date */
  @action.bound private extendLicense = flow(function* (this: LicensesPanel) {
    try {
      this.extendingLicense = true;
      if (!this.licenseToExtend) {
        return;
      }
      yield Api.billing.extendLicense(this.licenseToExtend.id, {
        expiresAt: this.licenseToExtend.expiresAt,
      });
      yield this.getLicensesForAccount();
      this.closeExtendLicense();
      this.props.toastStore!.success(`License expiration date updated!`);
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.extendingLicense = false;
    }
  });

  /** Fetches the cart and the KIOSK_YERLY product id */
  @action.bound private initCart = flow(function* (this: LicensesPanel) {
    try {
      const resp = yield Api.billing.getCart(this.props.accountId);
      this.cart = resp.data.data;
    } catch (e) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Closes the add licenses dialog */
  @action.bound private closeAddLicenses() {
    this.openAddLicenseDialog = false;
  }

  /** Closes the cancel license dialog */
  @action.bound private closeCancelLicense() {
    this.cancellationReason = null;
    this.openCancelLicenseDialog = false;
  }

  /** Closes the change status divice dialog */
  @action.bound private closeDialogDeviceStatus() {
    this.openDeviceStatusDialog = false;
  }

  @action.bound private openDialogDeviceStatus() {
    this.openDeviceStatusDialog = true;
  }

  /** Update cancel reason select value */
  @action.bound private updateCancelReason = (event: any) => {
    this.cancellationReason = event.target.value;
  };

  /** Update custom cancel reason text field value */
  @action.bound private updateCustomCancelReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.customCancellationReason = event.target.value;
  };

  /** Increases the no. of kiosks number */
  @action.bound private incKiosks() {
    this.numberOfKiosks += 1;
  }

  /** Decreases the no. of kiosks number */
  @action.bound private decKiosks() {
    if (this.numberOfKiosks <= 0) return;
    this.numberOfKiosks -= 1;
  }

  /** Sets the location that we get from the LocationSearch component */
  @action.bound private setLocation(l: models.Location | null) {
    this.location = l;
  }

  /** Adds a location to the cart */
  @action.bound private addLocation = flow(function* (this: LicensesPanel) {
    try {
      this.addingLocation = true;
      const resp = yield Api.billing.addToCart({
        accountId: this.props.accountId,
        productId: this.selectedProductId!,
        invoiceItemType: this.selectedInvoiceType,
        quantity: this.numberOfKiosks,
        locationId: this.location!.id,
      });
      // Reset the form for the location
      this.location = null;

      if (this.products && this.products.length > 1) {
        this.selectedProductId = undefined;
      }

      this.selectedInvoiceType = undefined;

      this.numberOfKiosks = 1;
      // Set the cart to be equal to the response
      this.cart = resp.data.data;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.addingLocation = false;
    }
  });

  /** Sends the purchase order */
  @action.bound private sendPurchaseOrder = flow(function* (this: LicensesPanel) {
    try {
      this.sendingPurchaseOrder = true;
      const resp: AxiosResponse<ApiResponse<models.Owner[]>> = yield Api.core.getAccountOwners(
        this.props.accountId,
      );
      const primaryOwner =
        resp.data.data &&
        resp.data.data.find((owner) => owner.owners[0] && owner.owners[0].type === 'primary');
      if (!primaryOwner) {
        throw new Error('Account does not have primary owner');
      }
      yield Api.billing.finalizeCart(
        this.props.accountId,
        primaryOwner.email,
        primaryOwner.firstName!,
      );
      this.openAddLicenseDialog = false;
      this.props.toastStore!.success(`Purchase order sent to ${primaryOwner.email}`);
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.sendingPurchaseOrder = false;
    }
  });

  @action.bound private fetchProducts = flow(function* (this: LicensesPanel) {
    try {
      const resp = yield Api.billing.getProducts();
      this.products = resp.data.data;

      if (this.products && this.products.length === 1) {
        this.selectedProductId = this.products[0].id;
      }
    } catch (e) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound private fetchProductsAvailableForLicense = async (license: models.License) => {
    try {
      return await Api.billing.getProductsAvailableByLicense(license.id);
    } catch (e) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  };

  @action.bound private fetchReplaceProductForLicense = async (
    licenseId: number,
    productId: number,
  ) => {
    try {
      const result = await Api.billing.ReplaceLicenseProduct(licenseId, productId);
      this.props.toastStore!.success('Product upgrade for license successfully');

      return result;
    } catch (e) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  };

  /** Whether the cart is currently in progress */
  @computed private get cartInProgress() {
    return this.deletingCartItem || this.addingLocation;
  }

  /** Which label to display on the main button */
  @computed private get addLicensesCtaLabel() {
    return this.props.sendPurchaseOrder ? 'Send purchase order' : 'Go to checkout';
  }

  /** Whether the main button for submitting is disabled */
  @computed private get submitLicensesDisabled() {
    return !(this.cart && this.cart.items.length !== 0);
  }

  @computed private get canExtendLicenses() {
    return (
      this.props.userStore!.isAdmin && this.props.userStore!.hasPermission(ACL.MANAGE_LICENSES)
    );
  }

  @action.bound private onChangePaymentMethod = (license: models.License) => {
    const locationName = license.location!.name || 'Location';
    this.props.modalStore!.confirm(
      '',
      <PaymentMethodsPanel
        panelName={`Add payment method for ${locationName}`}
        hideCount={true}
        hidePaper={true}
        accountId={this.props.accountId}
        itemProps={{
          onItemClick: async (paymentMethod: models.PaymentMethod) => {
            const licenseId = license.id;
            await this.onPaymentMethodClicked(licenseId, paymentMethod);
            this.props.toastStore!.success('Payment method changed successful');
            this.props.modalStore!.confirmDialog!.cancel();
          },
          selected: license.paymentMethod?.id,
          hideMenu: true,
        }}
      />,
      {
        hideCancel: true,
        confirmLabel: 'Close',
      },
    );
  };

  @action.bound private onChangeProduct = (license: models.License) => {
    this.currentDialog = EDialog.CHANGE_PRODUCT;
    this.openChangeProductDialog = true;
    this.loadingProductsAvailable = true;
    this.productsAvailable = undefined;
    this.selectedAvailableProductId = undefined;

    this.licenseToChangeProduct = license;

    this.fetchProductsAvailableForLicense(license)
      .then((res) => {
        this.productsAvailable = res?.data.data;
      })
      .finally(() => {
        this.loadingProductsAvailable = false;
      });
  };

  @action.bound private onReplaceLicenseProduct = () => {
    if (this.licenseToChangeProduct?.id && this.selectedAvailableProductId) {
      this.fetchReplaceProductForLicense(
        this.licenseToChangeProduct?.id,
        this.selectedAvailableProductId,
      ).finally(() => {
        this.licenseToChangeProduct = undefined;
        this.openChangeProductDialog = false;
        this.getLicensesForAccount();
        this.props.refreshNextPayments && this.props.refreshNextPayments();
      });
    }
  };

  @computed get numberOfLicenses() {
    if (this.licenses) {
      return this.licenses.length;
    } else {
      return 0;
    }
  }

  componentDidMount() {
    this.getLicensesForAccount();
    this.fetchProducts();
  }

  renderExtendLicense() {
    const license = this.licenseToExtend;
    if (!license) {
      return;
    }
    return (
      <Box display={'flex'} justifyContent={'center'}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            variant="static"
            value={license.expiresAt}
            onChange={this.updateLicenseExpiresAt}
            format={EDateFormat.DATE_LONG}
            openTo="date"
            views={['year', 'month', 'date']}
            label="Expires at"
            disablePast
          />
        </MuiPickersUtilsProvider>
      </Box>
    );
  }

  /** Displays the contents of the add licenses dialog */
  renderAddLicenses() {
    const { accountId, sendPurchaseOrder } = this.props;
    const disableAddLocation =
      !this.location || !this.selectedProductId || !this.selectedInvoiceType;

    return (
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Box mb={3}>
              <LocationSearch
                onChange={this.setLocation}
                accountId={accountId}
                value={this.location}
                placeholder="Search Location"
              />
            </Box>

            {this.products && this.products.length > 1 && (
              <Box mb={3}>
                <FormControl fullWidth>
                  <OutlinedInput
                    select
                    fullWidth
                    label={'Select Product'}
                    value={this.selectedProductId || ''}
                    onChange={(e) => {
                      if (e.target.value) {
                        this.selectedProductId = e.target.value as unknown as number;
                      }
                    }}>
                    {' '}
                    {this.products &&
                      this.products.map((product) => (
                        <MenuItem key={product.id} value={product.id}>
                          {product.name}
                        </MenuItem>
                      ))}
                  </OutlinedInput>
                </FormControl>
              </Box>
            )}

            <OutlinedInput
              select
              fullWidth
              label="Select Invoice Type"
              value={this.selectedInvoiceType || ''}
              onChange={(e) => {
                if (e.target.value) {
                  this.selectedInvoiceType = e.target.value as EInvoiceItemType;
                }
              }}>
              {Object.entries(INVOICE_ITEM_TYPES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </OutlinedInput>

            <Box pb={4}>
              <UpDownCounter
                label="Number of kiosks"
                value={this.numberOfKiosks}
                inc={this.incKiosks}
                dec={this.decKiosks}
              />
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                className={clsx(disableAddLocation && this.props.classes.addLocationDisabled)}
                variant="text"
                color="secondary"
                disabled={disableAddLocation}
                onClick={this.addLocation}>
                Add
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            {this.cart && (
              <>
                <CartPreview
                  cart={this.cart}
                  deleteCartItem={this.deleteCartItem}
                  cartInProgress={this.cartInProgress}
                />

                <Box display="flex" flexDirection="row" justifyContent="flex-end">
                  <Box mt={3} mr={1}>
                    <Button variant="text" color="primary" onClick={this.closeAddLicenses}>
                      Cancel
                    </Button>
                  </Box>

                  <Box mt={3}>
                    {sendPurchaseOrder ? (
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={this.submitLicensesDisabled}
                        onClick={this.sendPurchaseOrder}>
                        {this.addLicensesCtaLabel}
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        // component={RouterLink}
                        // to={paths.checkout(this.cart!.uid)}
                        disabled={this.submitLicensesDisabled}>
                        {this.addLicensesCtaLabel}
                      </Button>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }

  /** Displays the contents of the cancel licenses dialog */
  renderCancelLicense() {
    return (
      <Box>
        <Typography>What is the reason for canceling?</Typography>
        <Box mt={1}>
          <DP.Select
            items={Object.values(models.CancellationReason)}
            value={this.cancellationReason ? this.cancellationReason : ''}
            onChange={this.updateCancelReason}
            fullWidth
          />
        </Box>
        {this.cancellationReason === models.CancellationReason.OTHER && (
          <Box mt={4}>
            <OutlinedInput
              value={this.customCancellationReason}
              placeholder="Reason for canceling"
              onChange={this.updateCustomCancelReason}
              multiline
              maxRows={2}
              fullWidth
            />
          </Box>
        )}
      </Box>
    );
  }

  renderSugestionDeviceStatus() {
    return <Typography>Do you want to make devices of this location available?</Typography>;
  }

  renderChangeProdLicense() {
    return (
      <Box>
        <Typography>Do you need to change the product for the license?</Typography>
        <Box mb={3} mt={2}>
          <OutlinedInput
            select
            fullWidth
            label={'Select Product'}
            value={this.selectedAvailableProductId || ''}
            onChange={(e) => {
              if (e.target.value) {
                this.selectedAvailableProductId = e.target.value as unknown as number;
              }
            }}>
            {' '}
            {this.productsAvailable &&
              this.productsAvailable.map((product) => (
                <MenuItem key={product.id} value={product.id}>
                  {product.name}
                </MenuItem>
              ))}
          </OutlinedInput>
        </Box>
      </Box>
    );
  }

  @computed public get dialogProps(): Dialog.IDialogProps {
    switch (this.currentDialog) {
      case EDialog.ADD_LICENSES:
        return {
          open: this.openAddLicenseDialog,
          title: 'Add Licenses',
          content: this.renderAddLicenses(),
          loading: this.sendingPurchaseOrder,
          maxWidth: 'lg',
          fullWidth: true,
          onClose: this.closeAddLicenses,
        };
      case EDialog.CANCEL_LICENSE:
        return {
          open: this.openCancelLicenseDialog,
          title: 'Cancel License',
          content: this.renderCancelLicense(),
          onCancel: this.closeCancelLicense,
          disabled: !this.cancellationReason,
          onConfirm: this.cancelLicense,
          confirmActionName: 'Confirm',
          cancelActionName: 'Close',
          onClose: this.closeCancelLicense,
        };
      case EDialog.DEVICE_INVENTORY:
        return {
          open: this.openDeviceStatusDialog,
          title: 'Device Inventory',
          content: this.renderSugestionDeviceStatus(),
          onCancel: this.closeDialogDeviceStatus,
          onClose: this.closeDialogDeviceStatus,
        };
      case EDialog.EXTEND_LICENSE:
        return {
          open: !!this.licenseToExtend,
          title: 'Extend License',
          content: this.renderExtendLicense(),
          confirmActionName: 'Ok',
          width: 'auto',
          onConfirm: this.extendLicense,
          onClose: this.closeExtendLicense,
          loading: this.extendingLicense,
        };
      case EDialog.CHANGE_PRODUCT:
        return {
          open: this.openChangeProductDialog,
          title: 'Change Product',
          content: this.renderChangeProdLicense(),
          loading: this.loadingProductsAvailable,
          disabled: !this.selectedAvailableProductId,
          onConfirm: this.onReplaceLicenseProduct,
          onCancel: this.closeDialogChangeProduct,
        };
      default:
        return { open: false, title: '', content: <></> };
    }
  }

  @action.bound private copyText = (objectClicked: ECopyToClipboad) => {
    const valueToCopy =
      objectClicked === ECopyToClipboad.EMAIL ? SALES_TEAM_EMAIL : SALES_TEAM_PHONE;

    const message = objectClicked === ECopyToClipboad.EMAIL ? 'Email' : 'Phone number';
    copyTextToClipboard(valueToCopy);

    this.props.toastStore!.success(`${message} copied to clipboard`);
  };

  render() {
    const { changePaymentMethodEnabled } = this.props;
    return (
      <DP>
        <DP.Header>
          <DP.Title count={this.count} light>
            {this.panelName}
          </DP.Title>
          <DP.Actions>
            {this.isAdmin && (
              <DP.IconButton
                fontAwesomeIcon={{ icon: faPlus }}
                tooltip="Add licenses"
                primary
                onClick={this.openAddLicenses}
              />
            )}
            {!this.isAdmin && (
              <Tooltip
                interactive
                title={
                  <span>
                    Wish to add a new license? Contact our sales team at{' '}
                    <span
                      onClick={() => this.copyText(ECopyToClipboad.EMAIL)}
                      style={{ textDecoration: 'underline' }}>
                      {SALES_TEAM_EMAIL + ' or '}
                    </span>
                    <span onClick={() => this.copyText(ECopyToClipboad.PHONE_NUMBER)}>
                      {SALES_TEAM_PHONE}
                    </span>
                  </span>
                }>
                <FontAwesomeIcon
                  onMouseDown={(event) => event.stopPropagation()}
                  icon={faInfoCircle}
                  className={this.props.classes.faInfoCircle}
                />
              </Tooltip>
            )}
          </DP.Actions>
        </DP.Header>
        {this.inProgress ? (
          <DP.List>
            <DP.Loading items={5} variant="circleWithTwoLines" />
          </DP.List>
        ) : this.filteredLicenses && this.filteredLicenses.length > 0 ? (
          <Box>
            <DP.SearchInput value={this.search} onChange={this.updateSearch} />
            <DP.List
              height={
                this.numberOfLicenses < 4
                  ? 'short'
                  : this.numberOfLicenses >= 4 && this.numberOfLicenses <= 10
                  ? 'normal'
                  : 'tall'
              }>
              {this.filteredLicenses.map((location) => (
                <LicenseListItem
                  key={location.id}
                  cancelLicense={this.openCancelLicense}
                  activateLicense={this.activateLicense}
                  extendLicense={this.canExtendLicenses ? this.openExtendLicense : undefined}
                  license={location}
                  changeLicensePaymentMethod={
                    changePaymentMethodEnabled && this.paymentMethods.length > 1
                      ? this.onChangePaymentMethod
                      : undefined
                  }
                  changeLicenseProduct={this.onChangeProduct}
                />
              ))}
            </DP.List>
          </Box>
        ) : (
          <DP.Body>
            <Box display={'flex'} alignContent={'center'}>
              <EmptyPanelMessage panelTitle={this.panelName} />
            </Box>
          </DP.Body>
        )}
        <Dialog.default
          {...this.dialogProps}
          TransitionProps={{ onExited: () => (this.currentDialog = undefined) }}
        />
      </DP>
    );
  }
}

export default withStyles(styles)(LicensesPanel);
