/**
 * ACL permissions
 */
export enum ACL {
  VIEW_PERMISSIONS = 'view_permissions',
  CHANGE_PERMISSIONS = 'change_permissions',
  ISSUE_REFUND = 'issue_refund',
  POST_CORRECTION = 'post_correction',
  INITIATE_PAYOUTS = 'initiate_payouts',
  MERGE_USERS = 'merge_users',
  MANAGE_LICENSES = 'manage_licenses',
  POST_ACCOUNT_LEDGER_CORRECTION = 'post_account_ledger_correction',
  ISSUE_INVOICE_REFUND = 'issue_invoice_refund',
  VOID_INVOICE = 'void_invoice',
  TRANSFER_TIP = 'transfer_tip',
  MANAGE_INTEGRATIONS = 'manage_integrations',
  VIEW_LIABILITY = 'view_liability',
  REV_SHARE = 'rev_share',
  VIEW_REV_SHARE = 'view_rev_share',
  INITIATE_DRAWS = 'initiate_draws',
  MANAGE_TIPPY_GO = 'manage_tippy_go',
  MODIFY_POS_TRANSACTIONS = 'modify_pos_transactions',
  RESET_KYC_ATTEMPTS = 'reset_kyc_attempts',
}
