import React from 'react';
import useStyles from './styles';
import { Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
const ErrorFallback = ({ onRefresh }: { onRefresh?: (() => void) | undefined }) => {
  const { wrapper, iconWrapper, message, icon, refreshBtn } = useStyles();
  return (
    <Box className={wrapper}>
      <Box className={iconWrapper}>
        <FontAwesomeIcon className={icon} icon={faCircleExclamation} />
      </Box>
      <Typography className={message} variant="body2">
        Something went wrong. Try{' '}
        <Box onClick={onRefresh} className={refreshBtn} component={'span'}>
          refreshing
        </Box>{' '}
        the page.
      </Typography>
    </Box>
  );
};

export default ErrorFallback;
