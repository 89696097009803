import React from 'react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, FormControlLabel, MenuItem } from '@material-ui/core';
import validatorjs from 'validatorjs';
import { getErrorMsg } from 'api';
import { paths } from 'routes';

import { inject, WithUserStore, WithToastStore, WithUiStore } from 'stores';
import { Industry, Location } from 'models';
import { Address, INVOICE_ITEM_TYPES } from 'types';
import { getTimezone, setTitle } from 'services';

import AddressField from 'components/AddressField';
import CartPreview from 'components/CartPreview';
import UpDownCounter from 'components/UpDownCounter';

import SignupStore, { SignupStep } from './SignupStore';
import { ReactComponent as LocationsImg } from './locations_new.svg';

import styles from './styles';
import theme from 'containers/App/theme';
import clsx from 'clsx';
import Button from 'components/Button/Button';
import { Checkbox } from 'components/Checkbox/Checkbox';
import OutlinedInput from 'components/Input/OutlinedInput';
import AccountSignupLayout from './AccountSignupLayout/AccountSignupLayout';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

interface FormHooks {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess: (form: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClear: (form: any) => void;
}

/** Here we define what kind of props this component takes */
interface LocationsStepProps
  extends WithStyles<typeof styles>, // Adds the classes prop
    RouteComponentProps<{ accountId: string }>,
    WithUserStore, // Adds the userStore prop
    WithUiStore,
    WithToastStore {
  signupStore: SignupStore;
  nextRoute: (accountId: string | number) => string;
}

const plugins = {
  dvr: dvr({
    package: validatorjs,
  }),
};

/**
 * The locations screen of the account signup for admins
 */
@inject('userStore', 'toastStore', 'uiStore')
@observer
class LocationsStep extends React.Component<LocationsStepProps> {
  public signupStore = this.props.signupStore!;

  public constructor(props: LocationsStepProps) {
    super(props);
    makeObservable(this);

    const fields = [
      {
        name: 'name',
        label: 'Location Name',
        rules: 'required|min:2',
      },
      {
        name: 'employeeCount',
        label: 'Number of Employees',
        rules: 'numeric',
      },
      {
        name: 'address',
        label: 'Address',
        rules: 'required',
      },
      {
        name: 'shippingAddress',
        label: 'Shipping Address',
        value: null,
        rules: 'required',
      },
      {
        name: 'invoiceItemType',
        label: 'Invoice Type',
        value: null,
        rules: 'required',
      },
    ];
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }

  /** Whether we are currently submitting */
  @observable public submitting = false;

  /** The form */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @observable private form: any;

  /** Whether the address is the same as the account's */
  @observable public useAccountAddress = false;

  /** Whether the address is the same as the account's */
  @observable public useAccountAddressForShipping = true;

  /** Number of kiosks */
  @observable public kioskCount = 1;

  /** Whether the owner is a talent, controlled by a checkbox */
  @observable public ownerIsTalent = false;

  private hooks: FormHooks = {
    onSuccess: () => {
      this.handleAdd();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClear: (form: any) => {
      form.clear();
    },
  };

  /** Handles the use account address checkbox */
  @action.bound public handleUseAccountAddressChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const signupStore = this.props.signupStore;
    this.useAccountAddress = e.target.checked;
    if (this.useAccountAddress) {
      const account = signupStore.account!;
      this.updateAddress({
        address: account.address!,
        address2: account?.address2,
        zip: account.zip!,
        city: account.city!,
        state: account.state!,
      });
    } else {
      this.updateAddress(null);
    }
    this.form.$('address').validate();
  }

  /** Handles the use account address checkbox */
  @action.bound public handleUseAddressForShippingChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const signupStore = this.props.signupStore;
    this.useAccountAddressForShipping = e.target.checked;
    if (this.useAccountAddressForShipping) {
      const account = signupStore.account!;
      this.updateShippingAddress({
        address: account.address!,
        address2: account?.address2,
        zip: account.zip!,
        city: account.city!,
        state: account.state!,
      });
    } else {
      this.updateShippingAddress(null);
    }
    this.form.$('shippingAddress').validate();
  }

  /** Updates the address */
  @action.bound public updateAddress(a: Address | null) {
    this.form.$('address').set(a);
  }

  /** Updates the address */
  @action.bound public updateShippingAddress(a: Address | null) {
    this.form.$('shippingAddress').set(a);
  }

  /** Sets the kiosk count */
  @action.bound public setKioskCount(n: number) {
    this.kioskCount = n;
  }

  /** Increases the kiosk count */
  @action.bound public incKioskCount() {
    this.setKioskCount(this.kioskCount + 1);
  }

  /** Decreases the kiosk count */
  @action.bound public decKioskCount() {
    if (this.kioskCount > 1) {
      this.setKioskCount(this.kioskCount - 1);
    }
  }

  /** Handles the add another button click */
  @action.bound public handleAdd = flow(function* (this: LocationsStep) {
    // First validate the form and show any errors
    this.form.validate();
    this.form.showErrors(true);
    // If the form is valid, add a location
    if (this.form.isValid) {
      try {
        this.submitting = true;
        // Get the timezone for the location
        const timezone: string = yield getTimezone(this.location);
        // Create the location
        const createdLocation = yield this.props.signupStore.addLocation(
          { ...this.location, timezone },
          this.kioskCount,
          this.currentProductId,
          this.form.$('invoiceItemType').value,
          this.shippingAddress && {
            ...this.shippingAddress,
            default: !this.useAccountAddressForShipping,
          },
        );
        // If the checkbox is marked, add the owner as a talent too
        if (this.ownerIsTalent) {
          yield this.props.signupStore.addOwnerToLocation(createdLocation.id);
        }

        // Clear the current form and the kiosk count
        this.clearLocation();
        // If we're adding a second location, we probably don't want to use
        // the account address for its address
        this.useAccountAddress = false;

        if (this.useAccountAddressForShipping) {
          const account = this.props.signupStore.account!;
          this.updateShippingAddress({
            address: account.address!,
            address2: account?.address2,
            zip: account.zip!,
            city: account.city!,
            state: account.state!,
          });
        }
        this.useAccountAddressForShipping = true;
      } catch (e: any) {
        this.props.toastStore!.error(getErrorMsg(e));
      } finally {
        this.submitting = false;
      }
    }
  });

  /** Clears the form */
  @action.bound public clearLocation() {
    this.kioskCount = 1;
    this.ownerIsTalent = false;
    this.form.reset();
    this.form.clear();
  }

  /** Goes to the next step */
  @action.bound public next = flow(function* (this: LocationsStep) {
    if (this.form.changed) {
      this.form.validate();
      this.form.showErrors(true);
      if (this.form.isValid) {
        yield this.handleAdd();
        this.goToNextRoute();
      }
    } else {
      this.goToNextRoute();
    }
  });

  /** Handles the checkbox */
  @action.bound public handleOwnerIsTalentChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.ownerIsTalent = e.target.checked;
  }

  /** Initializes the component */
  @action.bound public init = flow(function* (this: LocationsStep) {
    const signupStore = this.props.signupStore!;
    signupStore.setStep(SignupStep.Locations);
    yield signupStore.initAccount(this.accountId);

    if (this.useAccountAddressForShipping) {
      const signupStore = this.props.signupStore;
      const account = signupStore.account!;
      this.updateShippingAddress({
        address: account.address!,
        address2: account?.address2,
        zip: account.zip!,
        city: account.city!,
        state: account.state!,
      });
    }

    // If we don't have the cart's cartProductId or a selected product,
    // go back to the previous step

    if (signupStore.adminSignup && !signupStore.affiliateSignup) {
      if (!signupStore.cartProductId && !signupStore.selectedProduct) {
        this.props.history.replace(paths.adminAccountSignup().product(this.accountId));
      }
    }
  });

  @action.bound public goToNextRoute() {
    this.props.history.push(this.props.nextRoute(this.accountId));
  }

  /** The address that's used for this location */
  @computed public get address(): Address | undefined {
    const addr: Address = this.useAccountAddress
      ? {
          ...(this.props.signupStore.account! as Address),
          ...this.props.signupStore.accountLatLong,
        }
      : this.form.$('address').value;
    return {
      address: addr.address,
      address2: addr?.address2,
      city: addr.city,
      state: addr.state,
      zip: addr.zip,
      lat: addr.lat,
      long: addr.long,
    };
  }

  /** The address that's used for this location */
  @computed public get shippingAddress(): Address | undefined {
    const addr: Address = this.useAccountAddressForShipping
      ? undefined
      : this.form.$('shippingAddress').value;

    if (addr) {
      return {
        address: addr.address,
        address2: addr?.address2,
        city: addr.city,
        state: addr.state,
        zip: addr.zip,
      };
    } else {
      return addr;
    }
  }

  /** The location that's being added */
  @computed public get location(): Partial<Location> {
    return {
      accountId: this.props.signupStore.account!.id,
      name: this.form.$('name').value,
      employeeCount: Number(this.form.$('employeeCount').value),
      timezone: 'Unknown',
      address: this.address!.address,
      address2: this.address!.address2,
      city: this.address!.city,
      zip: this.address!.zip,
      state: this.address!.state,
      lat: this.address!.lat,
      long: this.address!.long,
    };
  }

  /** Whether to show the next button */
  @computed public get showNextButton(): boolean {
    const cart = this.props.signupStore.cart;
    return Boolean(cart && cart.items && cart.items.length > 0);
  }

  @computed public get accountId(): number {
    return parseInt(this.props.match.params.accountId);
  }

  @computed public get ownerIsTalentLabel(): string {
    return this.signupStore.selfSignup
      ? 'I work here as a service professional'
      : 'The owner works here as a talent';
  }

  @computed public get currentProductId(): number {
    return (
      (this.props.signupStore.selectedProduct && this.props.signupStore.selectedProduct.id) ||
      this.props.signupStore.cartProductId ||
      this.props.signupStore.kioskProductId!
    );
  }

  componentDidMount() {
    if (this.signupStore.selfSignup) {
      setTitle(`Add Locations`);
    }
    this.init();
  }

  renderAddressSection() {
    const addressField = this.form.$('address');
    return (
      <Box pb={2}>
        <Box pb={2}>
          <FormControlLabel
            control={
              <Checkbox
                style={{ paddingBottom: 0, paddingTop: 0 }}
                checked={this.useAccountAddress}
                onChange={this.handleUseAccountAddressChanged}
                dataCy="checkbox-location-address"
              />
            }
            label="Location address is the same as company address"
          />
        </Box>
        {this.useAccountAddress ? (
          <OutlinedInput disabled value={this.props.signupStore.accountAddress} fullWidth />
        ) : (
          <AddressField
            onChange={this.updateAddress}
            label={this.form.$('address').label}
            key={
              this.props.signupStore.locationCount /** Clear input after new location is added */
            }
            error={Boolean(addressField.error)}
            helperText={addressField.error ? addressField.error : 'Start with street number'}
            onBlur={addressField.onBlur}
            onFocus={addressField.onFocus}
            enableCustomInput
            dataCy="input-address"
          />
        )}
      </Box>
    );
  }

  renderShippingAddressSection() {
    const addressField = this.form.$('shippingAddress');
    return (
      <Box pb={4}>
        <FormControlLabel
          control={
            <Checkbox
              style={{ paddingBottom: 0, paddingTop: 0 }}
              checked={this.useAccountAddressForShipping}
              onChange={this.handleUseAddressForShippingChanged}
            />
          }
          label="Shipping address is the same as company address"
        />

        {!this.useAccountAddressForShipping ? (
          <Box mt={2}>
            <AddressField
              onChange={this.updateShippingAddress}
              key={this.props.signupStore.locationCount}
              error={Boolean(addressField.error)}
              label={this.form.$('shippingAddress').label}
              helperText={addressField.error ? addressField.error : 'Start with street number'}
              onBlur={addressField.onBlur}
              onFocus={addressField.onFocus}
              enableCustomInput
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Box>
        ) : null}
      </Box>
    );
  }

  renderInvoiceTypeSection() {
    const invoiceTypeField = this.form.$('invoiceItemType');
    return (
      <Box pb={4}>
        <Box pb={2}>
          <OutlinedInput
            select
            label="Invoice Type"
            fullWidth
            {...this.form.$('invoiceItemType').bind()}
            dataCy="invoice-item-type-select"
            helperText={invoiceTypeField.error}
            error={Boolean(invoiceTypeField.error)}>
            {Object.entries(INVOICE_ITEM_TYPES).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </OutlinedInput>
        </Box>
      </Box>
    );
  }

  renderKioskCount() {
    const productLabel =
      (this.props.signupStore.selectedProduct && this.props.signupStore.selectedProduct.name) ||
      'Number of kiosks';
    return (
      <Box pb={0}>
        <UpDownCounter
          label={productLabel}
          value={this.kioskCount}
          inc={this.incKioskCount}
          dec={this.decKioskCount}
          vertical
        />
      </Box>
    );
  }

  render() {
    const { classes, signupStore } = this.props;
    if (!this.props.signupStore.accountReady) {
      return null;
    }
    return (
      <AccountSignupLayout
        title={{
          name: `Add locations to ${signupStore.account!.name}`,
          backPath: paths.adminAccountSignup().product(this.accountId),
        }}
        subtitle={{
          name: 'You can add as many locations as you wish',
        }}
        contentLeft={
          <>
            {signupStore?.showCart && (
              <CartPreview
                deleteCartItem={signupStore?.deleteCartItem}
                updateInvoiceType={signupStore?.updateCartItem}
                cart={signupStore.cart!}
                cartInProgress={signupStore.cartInProgress}
              />
            )}
          </>
        }
        image={{ svg: <LocationsImg />, hide: signupStore?.showCart }}
        contentRight={{
          children: (
            <form
              id={'location-step-form'}
              onSubmit={this.form.onSubmit}
              className={classes.accountStepForm}>
              <Box pb={2}>
                <OutlinedInput
                  {...this.form.$('name').bind()}
                  error={Boolean(this.form.$('name').error)}
                  helperText={this.form.$('name').error}
                  fullWidth
                  autoFocus
                  dataCy="location-name-input"
                />
              </Box>
              <Box pb={2}>
                <OutlinedInput
                  {...this.form.$('employeeCount').bind()}
                  error={Boolean(this.form.$('employeeCount').error)}
                  helperText={this.form.$('employeeCount').error}
                  fullWidth
                  dataCy="location-employeeCount-input"
                  type="number"
                  className={classes.accountStepInputNumber}
                />
              </Box>
              {this.props.signupStore?.industry === Industry.BEAUTY && (
                <Box pb={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        checked={this.ownerIsTalent}
                        onChange={this.handleOwnerIsTalentChanged}
                        data-cy="location-address-same"
                      />
                    }
                    label={this.ownerIsTalentLabel}
                  />
                </Box>
              )}
              {this.renderAddressSection()}
              {this.renderShippingAddressSection()}
              {this.renderInvoiceTypeSection()}
              {this.renderKioskCount()}
            </form>
          ),
          mb: 'auto',
        }}
        actionsRight={
          <Box display={'flex'} pt={2} gridGap={16} width={'100%'} flexWrap={'wrap'}>
            <Button
              data-cy="submit-button"
              className={clsx(classes.nextStepButton)}
              style={{ marginTop: theme.spacing(0) }}
              color="primary"
              form={'location-step-form'}
              variant={signupStore.showCart ? 'outlined' : 'contained'}
              loading={this.submitting}
              type="submit">
              Add location
            </Button>
            {this.showNextButton && (
              <Button
                className={clsx(classes.nextStepButton, classes.locationStepButtonMargin)}
                variant="contained"
                color="primary"
                onClick={this.next}
                data-cy="next-button">
                Next
              </Button>
            )}
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(LocationsStep);
