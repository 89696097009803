import { Drawer, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/styles/withStyles';
import Api from 'api';
import clsx from 'clsx';
import BankEvents from 'components/BankEvents';
import DP from 'components/DashPanel';
import { DotsVertical } from 'mdi-material-ui';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { BankAccount, BankAccountType } from 'models';
import React from 'react';
import { inject, WithToastStore } from 'stores';
import BankListItem from './BankListItem';
import styles from './styles';

interface BanksPanelProps extends WithStyles<typeof styles>, WithToastStore {
  children?: BankAccount[];
  primaryBankAccountId?: number;
  refreshBanks: () => void;
  userId: number;
}
@inject('toastStore')
@observer
class BanksPanel extends React.Component<BanksPanelProps> {
  constructor(props: BanksPanelProps) {
    super(props);
    makeObservable(this);
  }

  @observable public eventsOpen = false;

  @observable public menuAnchorEl: null | HTMLElement = null;

  @observable public inProgress = false;

  @action.bound public openEvents = () => {
    this.eventsOpen = true;
  };
  @action.bound public closeEvents = () => {
    this.eventsOpen = false;
  };

  @action.bound public openPanelMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.menuAnchorEl = event.currentTarget;
  };

  @action.bound public closePanelMenu = () => {
    this.menuAnchorEl = null;
  };

  @action.bound public sendLinkBankEmail = async () => {
    this.closePanelMenu();
    try {
      this.inProgress = true;
      await Api.core.sendLinkBankEmail(this.props.userId);
      this.props.toastStore!.push({
        type: 'success',
        message: 'Manual entry link successfully sent to user',
      });
    } catch (err: any) {
      const errMsg = err.response ? err.response.data.error.message : 'An error has occurred';
      this.props.toastStore!.push({ type: 'error', message: errMsg });
    } finally {
      this.inProgress = false;
    }
  };

  menuItems = [
    {
      label: 'View Logs',
      onClick: this.openEvents,
    },
    {
      label: 'Send Manual Entry Link',
      onClick: this.sendLinkBankEmail,
    },
  ];

  render() {
    const { children: banks, userId, refreshBanks, classes } = this.props;
    return (
      <DP>
        <Drawer
          open={this.eventsOpen}
          onClose={() => this.closeEvents()}
          anchor="right"
          variant="temporary">
          <BankEvents
            userId={userId}
            bankAccountType={BankAccountType.PERSONAL}
            onClose={() => this.closeEvents()}
          />
        </Drawer>
        <DP.Header>
          <DP.Title panel>Banks</DP.Title>
          <DP.Actions>
            {this.inProgress ? (
              <DP.LoadSpinner />
            ) : (
              <>
                <IconButton size="small" onClick={this.openPanelMenu}>
                  <DotsVertical fontSize="small" />
                </IconButton>
                <Menu
                  anchorEl={this.menuAnchorEl}
                  open={Boolean(this.menuAnchorEl)}
                  onClose={() => this.closePanelMenu()}>
                  {this.menuItems.map((menuItem, i) => (
                    <MenuItem dense key={i} onClick={() => menuItem.onClick()}>
                      {menuItem.label}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </DP.Actions>
        </DP.Header>
        {banks && banks?.length ? (
          <DP.List height="short">
            {banks.map((bank) => (
              <BankListItem
                key={bank.id}
                refreshBanks={refreshBanks}
                isPrimary={this.props.primaryBankAccountId === bank.id}>
                {bank}
              </BankListItem>
            ))}
          </DP.List>
        ) : null}

        {!banks ? (
          <DP.Body>
            <DP.Loading items={3} size="large" />
          </DP.Body>
        ) : null}

        {banks?.length === 0 ? (
          <DP.Body>
            <DP.Row>
              <Typography className={clsx(classes.emptyLabel)}>
                {'No bank account connected to this user.'}
              </Typography>
            </DP.Row>
          </DP.Body>
        ) : null}
      </DP>
    );
  }
}
export default withStyles(styles)(BanksPanel);
