import React from 'react';
import { action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography, Divider } from '@material-ui/core';
import { Note } from 'models';

import moment from 'moment-timezone';

import NoteStore from './NoteStore';

import DD from 'components/DashDrawer';

import styles from './styles';
import Skeleton from '@material-ui/lab/Skeleton';
import theme from 'containers/App/theme';

interface NoteListProps extends WithStyles<typeof styles> {
  noteStore: NoteStore;
  onClose: () => void;
}

/**
 * Displays a note drawer for listing all account specific notes.
 */
@observer
class NoteList extends React.Component<NoteListProps> {
  constructor(props: NoteListProps) {
    super(props);
    makeObservable(this);
  }
  /** List of notes */
  @computed get notes(): undefined | Pick<Note, 'id' | 'topic' | 'note' | 'createdAt'>[] {
    return this.props.noteStore.notes;
  }

  /** Fetch the note and push noteDetails path to history */
  @action.bound public routeToNote(noteId: number) {
    this.props.noteStore.getNote(noteId);
    this.props.noteStore.routeTo('/noteDetails');
  }

  renderLoadingSkeleton = () => (
    <>
      <Box mt={1} mb={1} display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="text" width={180} height={40} />
        <Skeleton variant="text" width={100} height={40} />
      </Box>
      <Divider />
    </>
  );

  render() {
    return (
      <Box>
        <DD.Content>
          <DD.Title fontSize={28} onClose={this.props.onClose}>
            Notes
          </DD.Title>
          <DD.List>
            {/* Loading notes: */}
            {!this.notes && this.renderLoadingSkeleton()}
            {/* || No notes for this account: */}
            {this.notes && this.notes.length === 0 && (
              <Box display="flex" justifyContent="center">
                <Typography variant="h6" style={{ color: theme.palette.text.secondary }}>
                  Nothing here yet
                </Typography>
              </Box>
            )}
            {/* || Notes list: */}
            {this.notes &&
              this.notes.length > 0 &&
              this.notes.map((note: Pick<Note, 'id' | 'topic' | 'note' | 'createdAt'>) => {
                const formattedDate = moment(note.createdAt).format('MMM DD, YYYY');
                return (
                  <Box mt={2} key={note.id} className={this.props.classes.listItem}>
                    <DD.ListItem
                      key={note.id}
                      primary={formattedDate}
                      secondary={note.topic}
                      onClick={() => this.routeToNote(note.id)}
                    />
                  </Box>
                );
              })}
          </DD.List>
        </DD.Content>
      </Box>
    );
  }
}

export default withStyles(styles)(NoteList);
