import { Theme, createStyles, makeStyles } from '@material-ui/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    backLink: {
      display: 'flex',
      alignitems: 'center',
    },
    viewStatusIcon: {
      fontSize: '96px',
      display: 'flex',
      margin: 'auto',
      marginBottom: theme.spacing(2),
    },
    viewContent: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(8),
      height: '100px',
    },
  });

  export const stylesCard = makeStyles((theme: Theme) => ({
    root: {},
    backLink: {
      display: 'flex',
      alignitems: 'center',
    },
    viewStatusIcon: {
      fontSize: '96px',
      display: 'flex',
      margin: 'auto',
      marginBottom: theme.spacing(2),
    },
    viewContent: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(8),
      height: '100px',
    },
  }));