import { TTabs } from 'components/TabBar/TabBar';
import { useStores } from 'containers/App/App';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export enum RefundTab {
  REFUND_REQUESTS = 'refund_requests',
  REFUNDS = 'refunds',
}

type UseRefunds = [RefundTab, TTabs];
export function useRefunds(): UseRefunds {
  const [tab, setTab] = useState<RefundTab>(RefundTab.REFUNDS);
  const [initialized, setInitialized] = useState(false);
  const { routerStore } = useStores();
  const history = useHistory();

  useEffect(() => {
    let search;
    if (routerStore && routerStore.location) {
      search = qs.parse(routerStore!.location.search, { ignoreQueryPrefix: true });
    }
    if (search && search.showCreated) {
      setTab(RefundTab.REFUND_REQUESTS);
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (tab !== RefundTab.REFUNDS || !initialized) return;
    let search;
    if (routerStore && routerStore.location) {
      search = qs.parse(routerStore!.location.search, { ignoreQueryPrefix: true });
    }
    const queryParams = new URLSearchParams(routerStore!.location.search);
    queryParams.delete('showCreated');
    if (search && search.showCreated) {
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [tab]);

  const tabs = [
    {
      label: 'Refunds',
      selected: tab === RefundTab.REFUNDS,
      onClick: () => setTab(RefundTab.REFUNDS),
    },
    {
      label: 'Refund Requests',
      selected: tab === RefundTab.REFUND_REQUESTS,
      onClick: () => setTab(RefundTab.REFUND_REQUESTS),
    },
  ];

  return [tab, tabs];
}
