import React, { useState, useCallback, useEffect, useRef } from 'react';
import { AutocompleteComponent } from './AutocompleteComponent';
import { ChipsList } from './ChipsList';

const TagsAutocomplete = ({
  disableCloseOnSelect,
  disabled,
  popperFullWidth,
  fetchOnInit,
  optionList,
  options,
  selectedTags,
  placeholder,
  tags,
  label,
  onKeyDown,
  humanizedChip,
}: any) => {
  const [value, setValue] = useState(tags || []);
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState(optionList || []);
  const [popperWidth, setPopperWidth] = useState(0);

  const keySearch = React.useMemo(
    () => options?.displayField?.keySearch,
    [options?.displayField?.keySearch],
  );

  const getUniqueListBy = (value = [], key: string) => {
    const collection = Array.isArray(value) ? value : [value];
    if (collection.length === 0) {
      return undefined;
    }
    return [...new Map(collection.map((c) => [c[key], c])).values()];
  };

  const handleChange = useCallback(
    (e, list) => {
      const val =
        Boolean(keySearch?.phrase) && typeof list[0] === 'string' && e.keyCode === 13
          ? { [keySearch?.phrase]: list[0] }
          : getUniqueListBy(list, keySearch?.name);
      selectedTags(val);
      setValue(val || []);
      if (
        e.keyCode &&
        e.keyCode === 13 &&
        Boolean(keySearch?.phrase) &&
        typeof list[0] === 'string'
      ) {
        onKeyDown(e);
      }
    },
    [keySearch?.name, keySearch?.phrase],
  );

  const fetchCollection = useCallback(async (e?: any, newValue?: string) => {
    if (newValue === '') {
      if (e.nativeEvent.inputType === 'deleteContentBackward') {
        setCollection([]);
      }
      return;
    }
    setLoading(true);
    try {
      const result = await options.fetch(newValue);
      setCollection(result);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (fetchOnInit) {
      fetchCollection();
    }
  }, [fetchCollection, fetchOnInit]);

  // Set dynamic width to Autocomplete Popper, considering filter block width
  const ref = useRef(null);
  useEffect(() => {
    const current = ref.current as any;

    const observer = new ResizeObserver((entries) => {
      setPopperWidth(entries[0].contentRect.width);
    });
    observer.observe(current);
    return () => current && observer.unobserve(current);
  }, []);

  return (
    <div ref={ref}>
      <ChipsList
        values={value.map((it: any) => it.name)}
        onDelete={(name: string) => {
          const res = value.filter((it: any) => name !== it.name);
          setValue(res);
          selectedTags(res);
        }}
        humanizedChip={humanizedChip}
      />

      <AutocompleteComponent
        disableCloseOnSelect={disableCloseOnSelect}
        disabled={disabled}
        inputChangeHandler={!optionList ? fetchCollection : () => null}
        handleChange={handleChange}
        value={value}
        mapper={options}
        options={!optionList ? collection : optionList}
        loading={loading}
        popperWidth={popperWidth}
        popperFullWidth={popperFullWidth}
        label={label}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TagsAutocomplete;
