import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Api, { ApiResponse, getErrorMsg } from 'api';
import { AxiosResponse } from 'axios';
import DP from 'components/DashPanel';
import theme, { colors } from 'containers/App/theme';
import { Cancel, Close, CreditCardRefund, History } from 'mdi-material-ui';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Invoice } from 'models';
import moment from 'moment';
import React from 'react';
import { inject, WithModalStore, WithToastStore, WithUserStore } from 'stores';
import RefundForm from './RefundForm';
import styles, { invoiceStyling } from './styles';
import { EmptyPanelMessage } from 'components/EmptyPanelMessage/EmptyPanelMessage';
import { ACL } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { paths } from 'routes';

interface RefundingInvoice {
  id: number;
  gross: string;
  invoiceNumber: string;
}

interface RefundResponse {
  invoiceId: number;
  gross: string;
  net: string;
  status: 'completed' | 'failed';
  tax: string;
}

interface InvoicesPanelProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    WithToastStore,
    WithModalStore {
  accountId: number;
}

/**
 * A panel displaying all the invoices for an account.
 */
@inject('toastStore', 'modalStore', 'userStore')
@observer
class InvoicesPanel extends React.Component<InvoicesPanelProps> {
  constructor(props: InvoicesPanelProps) {
    super(props);
    makeObservable(this);
  }

  private panelName = 'Invoices';

  /** The invoices are stored here */
  @observable public invoices?: Invoice[];

  @observable public inProgress = true;

  /**
   * Invoice object that holds data for refund invoice modal.
   * If object is not null we show refund modal.
   */
  @observable public refundingInvoice: RefundingInvoice | null = null;
  @observable public refundResponse: RefundResponse | null = null;

  /** Open refund modal by setting refunding tip */
  @action.bound public setRefundingInvoice = (invoice: Invoice) => {
    this.refundingInvoice = {
      id: invoice.id,
      gross: invoice.availableRefundAmountValue,
      invoiceNumber: invoice.number,
    };
  };

  @action.bound public unsetRefundingInvoice = () => {
    this.refundingInvoice = null;
    this.refundResponse = null;
  };

  /** Fetches the invoices from the API */
  @action.bound public getInvoices = flow(function* (this: InvoicesPanel) {
    try {
      this.inProgress = true;
      const resp: AxiosResponse<ApiResponse<Invoice[]>> = yield Api.billing.getInvoices(
        this.props.accountId,
      );
      this.invoices = resp.data.data;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.inProgress = false;
    }
  });

  /** Void invoice confirmation dialog */
  @action.bound public confirmInvoiceVoid = flow(function* (this: InvoicesPanel) {
    const dialogTitle = 'Void this invoice?';
    const dialogBody = (
      <>
        Please click <b>confirm</b> to proceed
      </>
    );
    return yield this.props.modalStore!.confirm(dialogTitle, dialogBody);
  });

  /** Void an invoice. This operation requires `void_invoice` ACL permission */
  @action.bound public voidInvoice = flow(function* (this: InvoicesPanel, id: string | number) {
    try {
      const confirmed = yield this.confirmInvoiceVoid();
      if (!confirmed) return;
      this.inProgress = true;
      yield Api.billing.voidInvoice(id);
      this.getInvoices();
      this.props.toastStore!.push({
        type: 'success',
        message: `Invoice was successfully voided`,
      });
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.inProgress = false;
    }
  });

  /** Refund an invoice. This operation requires `issue_invoice_refund` ACL permission */
  @action.bound public refundInvoice = flow(function* (
    this: InvoicesPanel,
    amount: number,
    reason: string,
  ) {
    try {
      if (this.refundingInvoice) {
        this.inProgress = true;
        const resp: AxiosResponse<ApiResponse<RefundResponse>> = yield Api.billing.refundInvoice(
          this.refundingInvoice.id,
          amount,
          reason,
        );
        if (resp.data.data) {
          this.refundResponse = resp.data.data;
        }
        this.getInvoices();
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.inProgress = false;
    }
  });

  /** The invoice count  */
  @computed public get invoiceCount(): number | undefined {
    return this.invoices && this.invoices.length;
  }

  renderStatusIcon(i: Invoice) {
    const classes = this.props.classes;
    return (
      <Tooltip title={i.status.replace(/_/g, ' ')} classes={{ tooltip: classes.tooltip }}>
        {(() => {
          switch (i.status) {
            case 'open':
              return <History style={{ color: colors.amber }} />;
            case 'captured':
              return (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  fontSize={24}
                  style={{ color: theme.palette.primary.main }}
                />
              );
            case 'failed':
              return <Close style={{ color: theme.palette.error.main }} />;
            case 'void':
              return <Cancel style={{ color: theme.palette.error.main }} />;
            case 'refunded':
              return <CreditCardRefund style={{ color: colors.amber }} />;
            case 'partially_refunded':
              return <CreditCardRefund style={{ color: colors.amber }} />;
            default:
              return <History style={{ color: colors.amber }} />;
          }
        })()}
      </Tooltip>
    );
  }

  @computed get numberOfInvoices() {
    if (this.invoices) {
      return this.invoices.length;
    } else {
      return 0;
    }
  }

  /** Fetch the invoices on mount */
  componentDidMount() {
    this.getInvoices();
  }

  render() {
    return (
      <DP>
        <DP.Header>
          <DP.Title count={this.invoiceCount} light>
            {this.panelName}
          </DP.Title>
        </DP.Header>

        {this.inProgress ? (
          <DP.List>
            <DP.Loading items={5} variant="circleWithTwoLines" />
          </DP.List>
        ) : this.invoices && this.invoices.length > 0 ? (
          <DP.List height={this.numberOfInvoices < 5 ? 'short' : 'normal'}>
            {this.invoices.map((invoice) => {
              // Invoice can be either voided OR refunded
              const canVoid =
                invoice.status === 'open' && this.props.userStore!.hasPermission(ACL.VOID_INVOICE);
              const canRefund =
                (invoice.status === 'captured' || invoice.status === 'partially_refunded') &&
                this.props.userStore!.hasPermission(ACL.ISSUE_INVOICE_REFUND);

              let menu;
              if (canVoid) {
                menu = [
                  {
                    label: 'Void',
                    onClick: () => this.voidInvoice(invoice.id),
                  },
                ];
              } else if (canRefund) {
                menu = [
                  {
                    label: 'Refund',
                    onClick: () => this.setRefundingInvoice(invoice),
                  },
                ];
              }

              const showRefundAmount = canRefund && invoice.refundedAmountValue !== '0';
              const secondaryText = `${invoice.gross} ${
                showRefundAmount ? `| Refunded: ${invoice.refundedAmount}` : ''
              } | Created on ${moment(invoice.createdAt).format('ll')}`;

              return (
                <DP.ListItem
                  icon={this.renderStatusIcon(invoice)}
                  key={invoice.number}
                  primary={
                    <Box sx={invoiceStyling}>
                      <Box component="span" className="invoice-number">
                        {invoice.number}
                      </Box>
                      <Link
                        component="a"
                        href={paths.sales().cartsCompletedExternal(String(invoice.identifier))}>
                        <IconButton size="small">
                          <FontAwesomeIcon icon={faEye} className="invoice-icon" />
                        </IconButton>
                      </Link>
                    </Box>
                  }
                  secondary={secondaryText}
                  menu={menu}
                />
              );
            })}
          </DP.List>
        ) : (
          <DP.Body>
            <Box display={'flex'} alignContent={'center'}>
              <EmptyPanelMessage panelTitle={this.panelName} />
            </Box>
          </DP.Body>
        )}

        {this.refundingInvoice && (
          <Dialog open={Boolean(this.refundingInvoice)} onClose={this.unsetRefundingInvoice}>
            <Box minWidth={380}>
              <DialogTitle>
                <Typography style={{ fontSize: 28, fontWeight: 400 }}>
                  Issuing refund for invoice number {this.refundingInvoice.invoiceNumber}
                </Typography>
              </DialogTitle>
              {this.inProgress ? (
                <Box display="flex" alignItems="center" justifyContent="center" minHeight="240px">
                  <CircularProgress />
                </Box>
              ) : this.refundResponse ? (
                <DialogContent>
                  <Typography>
                    Refund request successful.
                    <br /> <br />
                    Net: <b>{this.refundResponse.net}</b>
                    <br />
                    Tax: <b>{this.refundResponse.tax}</b>
                    <br />
                    Gross: <b>{this.refundResponse.gross}</b>
                  </Typography>
                  <DialogActions>
                    <Button onClick={this.unsetRefundingInvoice} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </DialogContent>
              ) : (
                <DialogContent>
                  <RefundForm
                    {...this.refundingInvoice}
                    onSubmit={this.refundInvoice}
                    closeModal={this.unsetRefundingInvoice}
                  />
                </DialogContent>
              )}
            </Box>
          </Dialog>
        )}
      </DP>
    );
  }
}

export default withStyles(styles)(InvoicesPanel);
