import { ListItem, ListItemText, MenuItem, Typography, IconButton, Box } from '@material-ui/core';
import { getFullShippingAddress, getFullAddress } from 'components/CartPreview/bl';
import OutlinedInput from 'components/Input/OutlinedInput';
import theme from 'containers/App/theme';
import { Close } from 'mdi-material-ui';
import { CartItem } from 'models';
import { EInvoiceItemType, INVOICE_ITEM_TYPES } from 'types';
import { invoiceTypeTextStyles, selectBasicStyles } from './styles';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CartItemComponentProps {
  children: CartItem;
  disableTopPadding?: boolean;
  deleteCartItem?: (item: CartItem) => Promise<unknown>;
  updateInvoiceType?: (cartItem: { invoiceItemType: EInvoiceItemType }) => void;
}

const CartItemComponent = ({
  children: item,
  deleteCartItem,
  disableTopPadding,
  updateInvoiceType,
}: CartItemComponentProps) => {
  const fullAddress = getFullShippingAddress(item) || getFullAddress(item.location);

  return (
    <ListItem divider disableGutters style={disableTopPadding ? { paddingTop: 0 } : {}}>
      <ListItemText
        primary={item.location.name}
        disableTypography
        secondary={
          <>
            <Box component={'span'} display={'flex'} flexDirection={'column'}>
              <Box component={'span'} style={{ ...theme.typography.subtitle1 }}>
                {fullAddress}
              </Box>
              <Box component={'span'} style={{ ...theme.typography.subtitle1 }}>
                {`${item.quantity} ${item.product.name}`}
              </Box>
            </Box>

            {updateInvoiceType ? (
              <Box sx={selectBasicStyles}>
                <OutlinedInput
                  className="select-blank"
                  select
                  label="Invoice Type"
                  value={item.invoiceItemType}
                  dataCy="cart-invoice-item-type-select"
                  variant="small"
                  onChange={(e) =>
                    updateInvoiceType({ invoiceItemType: e.target.value as EInvoiceItemType })
                  }
                  SelectProps={{
                    IconComponent: (props: { className: string }) => (
                      <FontAwesomeIcon
                        className="select-icon"
                        icon={
                          props.className.toString().includes('iconOpen')
                            ? faChevronUp
                            : faChevronDown
                        }
                      />
                    ),
                    MenuProps: {
                      PaperProps: {
                        variant: 'elevation',
                        style: { boxShadow: `0 0 24px ${theme.shadows[2]}` },
                      },
                    },
                  }}>
                  {Object.entries(INVOICE_ITEM_TYPES).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </OutlinedInput>
              </Box>
            ) : (
              <Box component="span" sx={invoiceTypeTextStyles}>
                {INVOICE_ITEM_TYPES[item.invoiceItemType]}
              </Box>
            )}
          </>
        }
      />
      <Box justifyContent="center" alignItems="center" flexBasis="1" pr={!deleteCartItem ? 0 : 1}>
        <Typography component="div">{item.amount}</Typography>
      </Box>

      {deleteCartItem && (
        <Box justifyContent="center" alignItems="center" flexBasis="1">
          <IconButton size="small" onClick={() => deleteCartItem(item)}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      )}
    </ListItem>
  );
};

export default CartItemComponent;
