import React, { useEffect, useState } from 'react';
import { Dialog as MuiDialog } from '@material-ui/core';
import { observer } from 'mobx-react';
import { BillingEntity } from 'models';
import { PaymentItem } from './PaymentItem/PaymentItem';
import { createStripe } from 'services';
import { useStores } from 'containers/App/App';
import { EditPaymentItem } from './EditPaymentItem/EditPaymentItem';
import { ENotificationType } from 'stores';
import Dialog from 'components/Dialog/Dialog';
import RefundRequestItem from './RefundRequestItem/RefundRequestItem';
import TransferTipRequestItem from './TransferRequestItem/TransferRequestItem';
import InvitationItem from './InvitationItem/InvitationItem';
import LocationInvitationDialog, {
  ILocationInvitationDialogProps,
} from './InvitationItem/LocationInvitationDialog/LocationInvitationDialog';

export const NotificationSystem = observer(() => {
  const {
    userStore: { isOwnerScope, isAdmin, loggedIn },
    notificationStore,
  } = useStores();
  const [locationDialogProps, setLocationDialogProps] =
    useState<ILocationInvitationDialogProps | null>(null);

  const [editBillingEntity, setEditBillingEntity] = useState<BillingEntity | undefined>();
  const [stripeLoaded, setStripeLoaded] = useState(false);

  useEffect(() => {
    createStripe().then(() => setStripeLoaded(true));
  }, []);

  useEffect(() => {
    if (!loggedIn || !notificationStore.canShowNotifications) return;
    notificationStore.initializeNotificationSystem();
  }, [notificationStore.login, isOwnerScope, isAdmin, notificationStore.canShowNotifications]);

  return (
    <>
      <Dialog
        open={
          notificationStore.openNotificationsDialog && !editBillingEntity && !locationDialogProps
        }
        title={'Notifications'}
        content={
          <>
            {notificationStore.notifications?.map((notification, index) => {
              if (notification.type === ENotificationType.EXPIRED_PAYMENT_METHODS) {
                return (
                  <>
                    <PaymentItem
                      key={index}
                      paymentMethod={notification.item}
                      editPaymentItem={setEditBillingEntity}
                    />
                  </>
                );
              }
              if (notification.type === ENotificationType.REFUND_REQUEST) {
                return <RefundRequestItem key={index} refundRequests={notification.item} />;
              }
              if (notification.type === ENotificationType.TRANSFER_TIP_REQUEST) {
                return <TransferTipRequestItem key={index} transferRequests={notification.item} />;
              }
              if (notification.type === ENotificationType.INVITATION) {
                return (
                  <InvitationItem
                    key={index}
                    invitation={notification.item}
                    setDialogProps={setLocationDialogProps}
                  />
                );
              }
            })}{' '}
          </>
        }
        onConfirm={notificationStore.notifyLater}
        confirmActionVariant="outlined"
        confirmActionName="Remind later"
      />
      <MuiDialog open={!!editBillingEntity}>
        <EditPaymentItem
          stripeLoaded={stripeLoaded}
          paymentMethod={editBillingEntity!}
          openEditPayment={false}
          editPayment={setEditBillingEntity}
        />
      </MuiDialog>
      <LocationInvitationDialog dialogProps={locationDialogProps} />
    </>
  );
});
