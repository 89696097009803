/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { observer } from 'mobx-react';
import {
  action,
  observable,
  computed,
  reaction,
  toJS,
  IReactionDisposer,
  flow,
  makeObservable,
} from 'mobx';
import * as models from 'models';
import validatorjs from 'validatorjs';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, IconButton } from '@material-ui/core';
import { Pencil, Check, Close, OpenInNew } from 'mdi-material-ui';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment'; // Material-ui date-picker dependency
import moment from 'moment';

import Api, { getErrorMsg } from 'api';
import { WithToastStore, WithUserStore, inject } from 'stores';

import DP from 'components/DashPanel';
import ImageIcon from 'components/ImageIcon';
import { paths } from 'routes';

import styles from './styles';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';
import OutlinedDatePicker from 'components/Input/OutlinedDatePicker';
import { MINIMAL_AGE_REQUIRED } from 'utils/constants';
import { EDateFormat } from 'utils/helper';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

/* Define and extend mobx-react-form DVR plugin */
const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any; form: any }) => {
      /* Add custom rule for validating US phone numbers */
      // NOTE: regex is from validatorjs library:
      // https://github.com/validatorjs/validator.js/blob/master/lib/isMobilePhone.js
      /* Overwrite specific validation errors */
      const customValidationMessages = {
        ...validator.getMessages('en'),
        required: 'This field is required',
      };
      validator.setMessages('en', customValidationMessages);
    },
  }),
};

// Define possible mobx-react-form fields for user details panel:
const fieldsOutline: any = {
  firstName: {
    label: 'First Name',
    type: 'text',
    rules: 'required|string',
    extra: {
      editable: true,
    },
  },
  lastName: {
    label: 'Last Name',
    type: 'text',
    rules: 'required|string',
    extra: {
      editable: true,
    },
  },
  nickname: {
    label: 'Nickname',
    type: 'text',
    rules: 'string',
    extra: {
      editable: true,
    },
  },
  phone: {
    label: 'Phone',
    type: 'tel',
    rules: 'string',
    extra: {
      editable: false,
    },
  },
  dob: {
    label: 'DOB',
    type: 'date',
    rules: 'date',
    extra: {
      editable: false,
    },
  },
  email: {
    label: 'Email',
    type: 'text',
    rules: 'string',
    extra: {
      editable: false,
    },
  },
  id: {
    label: 'User Id',
    type: 'text',
    rules: 'numeric',
    extra: {
      editable: false,
    },
  },
};

interface Field {
  name: string;
  editable: boolean;
}

interface UserDetailsPanelProps extends WithStyles<typeof styles>, WithToastStore, WithUserStore {
  children?: models.User;
  title?: string;
  editable?: boolean;
  handleUserChanged?: (user: models.User) => void;
  fields?: Field[];
  displayAvatar?: boolean;
  displayLink?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
}

const maxDate = moment();
maxDate.subtract(MINIMAL_AGE_REQUIRED, 'years');

/**
 * Displays user's details. By default this component is a static list of key value
 * pairs passed as props. But when the user clicks 'edit' icon this component becomes
 * a form that enables the user to modify those values.
 * @param children The user object to display. Pass undefined if it's still loading
 * @param onChange Optional onChange handler that does something with the updated data
 * @param title Optional title, `Details` by default
 * @param fields Optional form fields expressed as array of { name: string, editable: boolean } field objects
 */
@inject('toastStore', 'userStore')
@observer
class UserDetailsPanel extends React.Component<UserDetailsPanelProps> {
  static defaultProps = {
    title: 'Details',
    fields: [
      { name: 'id', editable: false },
      { name: 'firstName', editable: true },
      { name: 'lastName', editable: true },
      { name: 'nickname', editable: true },
      { name: 'email', editable: false },
      { name: 'phone', editable: false },
      { name: 'dob', editable: false },
    ],
  };
  constructor(props: UserDetailsPanelProps) {
    super(props);
    makeObservable(this);
    // Set up an autorun so that when the user in the props changes, we copy it
    // to this component's state. This achieves the same thing as implementing a
    // componentDidUpdate and seeing if the user prop has changed.
    // We push the autorun onto a disposers array so that we can dispose of all
    // of them before the component unmounts.
    this.disposers.push(
      reaction(
        () => this.props.children,
        (newUser) => {
          this.user = toJS(newUser);
        },
      ),
    );

    /* Examples of US phone numbers: */
    // (541) 754-3010 Domestic ✔
    // +1-541-754-3010 International ✔
    // 1-541-754-3010 Dialed in the US ✔
  }

  /** It's good practice to dispose of any autoruns that we set up during */
  private disposers: IReactionDisposer[] = [];

  /** Form and form dependencies */
  @observable private hooks = {
    onSuccess: (form: any) => {
      this.editing = false;
      const userData = this.prepareFormData(form);
      this.updateUser(userData);
    },
    onClear: () => {
      this.user = toJS(this.props.children);
      this.editing = false;
    },
  };

  /** Whether the user is currently editing */
  @observable private editing = false;
  @observable private updating = false;

  /** The user object is copied to this property */
  @observable private user?: models.User = toJS(this.props.children);

  @computed private get form() {
    if (this.user) {
      /* Create mobx-react-form instance using 'field[]', 'plugins' and event 'hooks' */
      return new MobxReactForm(
        { fields: this.createFormFields() },
        { plugins: plugins, hooks: this.hooks },
      );
    }
    return undefined;
  }

  /**
   * This component displays dynamic form fields passed down with the props, but
   * we have set of statically defined possible fields, because we want types and
   * rules to be predefined. If you are adding a new field via props, make sure it
   * exists in user model and as an entry in fieldsOutline array.
   */
  private createFormFields() {
    return this.props.fields!.reduce(
      (formFields, propField: any) => ({
        ...formFields,
        [propField.name]: {
          ...fieldsOutline[propField.name],
          value: this.user![propField.name as keyof models.User],
          extra: { editable: propField.editable },
        },
      }),
      {},
    );
  }

  /** Prepare form data for API consumption */
  private prepareFormData(form: any) {
    return (
      form
        // Parse user data from form fields ...
        .map(({ name, value, type }: any) => {
          if (type === 'date' && value) value = moment(value).format(EDateFormat.DATE_ISO);
          return { name, value };
        })
        // ... filter fields without values, they are not required ...
        // only "nickname" is optional and can have an empty value
        .filter(({ value, name }: any) => value || name === 'nickname')
        // ... and reduce it into single object for API consumption
        .reduce((userDataObj: any, field: any) => {
          return { ...userDataObj, [field.name]: field.value };
        }, {})
    );
  }

  /** Starts editing */
  @action.bound private edit() {
    this.editing = true;
  }

  /** When all is said and done update the user */
  @action.bound public updateUser = flow(function* (this: UserDetailsPanel, userData) {
    try {
      this.updating = true;
      const resp = yield Api.core.updateUser(this.user!.id, userData as models.User);
      this.props.userStore!.refreshTokenAndUpdateUserData();
      /* Call handleUserChanged prop event */
      this.props.handleUserChanged && this.props.handleUserChanged(resp.data.data);
      this.props.toastStore!.push({
        message: 'Personal data successfully updated',
        type: 'success',
      });
      this.updating = false;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @computed public get displayActions() {
    return Boolean(this.props.editable || this.props.displayLink);
  }

  /** Before unmounting the component, dispose of all autoruns created */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  render() {
    const {
      title,
      editable,
      displayAvatar,
      displayLink,
      children: user,
      fullHeight,
      fullWidth,
    } = this.props;
    const updating = this.updating;
    let showLabel = true;
    let showField = true;

    // Content of action menu based on component state; static || editing.
    // Either edit icon, or confirm and cancel icons.
    let actionContent;
    if (updating) {
      actionContent = <DP.LoadSpinner />;
    } else if (this.editing) {
      actionContent = (
        <>
          <DP.IconButton onClick={this.form.onClear} icon={Close} tooltip="Discard" />
          <DP.IconButton primary submit onClick={this.form.onSubmit} icon={Check} tooltip="Save" />
        </>
      );
    } else {
      actionContent = (
        <>
          {editable && <DP.IconButton primary onClick={this.edit} icon={Pencil} tooltip="Edit" />}
          {displayLink && user && (
            <IconButton
              component="a"
              href={paths.userDetails(user.id).root()}
              target="_blank"
              rel="noopener noreferrer"
              color="primary">
              <OpenInNew />
            </IconButton>
          )}
        </>
      );
    }

    /** Render plain text input field */
    const renderTextInput = (field: any) => (
      <OutlinedInput {...field.bind()} error={field.error} fullWidth />
    );

    /** Render date input field using DatePicker*/
    const renderDateInput = (field: any) => (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <OutlinedDatePicker
          style={{ width: '100%' }}
          value={field.value}
          label={'DOB'}
          onChange={(date) => date && field.set(moment(date).format(EDateFormat.DATE_ISO))}
          onAccept={() => field.validate()}
          error={field.error}
          helperText={field.error}
          format={EDateFormat.DEFAULT}
          maxDate={maxDate}
          variant="dialog"
          disableFuture
          fullWidth
          openTo="year"
          views={['year', 'month', 'date']}
        />
      </MuiPickersUtilsProvider>
    );

    if (!this.user || !this.form) {
      return (
        <DP fullHeight={fullHeight} fullWidth={fullWidth}>
          <DP.Header>
            <DP.Title panel>{title}</DP.Title>
          </DP.Header>
          <DP.Body>
            <DP.Loading items={this.props.fields ? this.props.fields.length : 4} />
          </DP.Body>
        </DP>
      );
    }
    return (
      <DP fullHeight={fullHeight} fullWidth={fullWidth}>
        <form onSubmit={this.form.onSubmit}>
          <DP.Header>
            <Box display="flex" flexDirection="row" alignItems="center">
              {displayAvatar && (
                <Box pr={2}>
                  <ImageIcon src={this.props.children && this.props.children.avatar} />
                </Box>
              )}
              <DP.Title panel>{title}</DP.Title>
            </Box>
            {this.displayActions && <DP.Actions>{actionContent}</DP.Actions>}
          </DP.Header>
          <DP.Body>
            {Array.from(this.form.fields).map(([name, field]: any) => {
              let fieldValue = field.value;
              showLabel = !!fieldValue && !this.editing; //? true : this.editing ? true : false;
              showField = this.editing ? field.extra.editable : fieldValue ? true : false;
              // If field type is a date, we have to format it for display
              // and also make sure we don't send 'undefined' to DatePicker's
              // value to avoid showing 'Invalid Date'
              if (field.type === 'date') {
                fieldValue = field.value ? moment(field.value).format(EDateFormat.DEFAULT) : '';
              }
              return (
                showField && (
                  <DP.Row key={name}>
                    {field.extra.editable && this.editing ? (
                      <>
                        {/* Plain text input field: */}
                        {field.type !== 'date' && renderTextInput(field)}
                        {/* For date field we want to use DatePicker: */}
                        {field.type === 'date' && renderDateInput(field)}
                      </>
                    ) : (
                      <DP.Value>{fieldValue} </DP.Value>
                    )}
                    {showLabel && <DP.Label>{field.label}</DP.Label>}
                  </DP.Row>
                )
              );
            })}
          </DP.Body>
        </form>
      </DP>
    );
  }
}

export default withStyles(styles)(UserDetailsPanel);
