import { Box, ListItem, ListItemText, Typography } from '@material-ui/core';
import { getFullAddress, getFullShippingAddress, getLocationName } from '../bl';
import { IInvoiceItem } from 'models';
import { useMemo } from 'react';
import { INVOICE_ITEM_TYPES } from 'types';
import theme from 'containers/App/theme';

export interface InvoiceItemComponentProps {
  invoiceItem: IInvoiceItem;
}

export const InvoiceItem = ({ invoiceItem }: InvoiceItemComponentProps) => {
  const { numKiosksLabel, fullAddress, shippingAddress, locationName } = useMemo(() => {
    const numKiosksLabel = (n: number) => `${n} ${invoiceItem.item}`;
    const location = invoiceItem.location;
    const fullAddress = getFullAddress(location);
    const shippingAddress = getFullShippingAddress(invoiceItem);
    const locationName = getLocationName(invoiceItem);

    return { numKiosksLabel, fullAddress, shippingAddress, locationName };
  }, [invoiceItem]);

  function renderAddress(type: 'Location' | 'Shipping', address?: string) {
    if (!address) return;
    return (
      <>
        <Box
          component={'span'}
          mt={0.5}
          style={{
            textDecoration: 'underline',
            ...theme.typography.subtitle1,
          }}>{`${type} address:`}</Box>
        <Box
          component={'span'}
          style={{
            ...theme.typography.subtitle1,
          }}>{`${address}`}</Box>
      </>
    );
  }

  return (
    <ListItem divider disableGutters>
      <ListItemText
        primary={locationName}
        disableTypography
        secondary={
          <>
            <Box component={'span'} display={'flex'} flexDirection={'column'}>
              {renderAddress('Location', fullAddress)}
              {renderAddress('Shipping', shippingAddress)}
              <Box component={'span'} mt={0.5} style={{ ...theme.typography.subtitle1 }}>
                {numKiosksLabel(invoiceItem.quantity)}
              </Box>
            </Box>

            <Box component="span" style={{ ...theme.typography.subtitle1 }}>
              {INVOICE_ITEM_TYPES[invoiceItem.invoiceItemType]}
            </Box>
          </>
        }
      />

      <Box justifyContent="center" alignItems="center" flexBasis="1" pr={0}>
        <Typography component="div">{`$${invoiceItem.net}`}</Typography>
      </Box>
    </ListItem>
  );
};
