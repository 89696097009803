import React from 'react';
import { observable, action, toJS, runInAction, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Radio,
  RadioGroup,
  Divider,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Account, Location } from 'models';

import DD from 'components/DashDrawer';
import AccountSearch from 'components/AccountSearch';
import { WithUserStore, inject } from 'stores';

import styles from './styles';
import LocationSearch from 'components/LocationSearch';
import Title from 'components/Title/Title';
import TDrawer from 'components/Title/Dialog';

import Close from 'mdi-material-ui/Close';

export interface UserFilters {
  roles: {
    owners: boolean;
    managers: boolean;
    talent: boolean;
    basic: boolean;
  };
  status: 'all' | 'active' | 'inactive';
  account?: Account;
  location?: Location;
}

interface UserFilterDrawerProps extends WithStyles<typeof styles>, WithUserStore {
  filters: UserFilters;
  onChange: (uf: UserFilters) => void;
  onClose: () => void;
  onReset: () => void;
  showAccountSearch?: boolean;
  hideOwnersCheckbox?: boolean;
  showInvitationStatuses?: boolean; // TODO: Always show the once we migrate admin user search to v2
  showReset: boolean;
}

/**
 * Displays a filter drawer for filtering user search results
 */
@inject('userStore')
@observer
class UserFilterDrawer extends React.Component<UserFilterDrawerProps> {
  constructor(props: UserFilterDrawerProps) {
    super(props);
    makeObservable(this);
  }
  /** The user filters */
  @observable filters: UserFilters = this.props.filters;

  /** Calls props.onChange with the current filters */
  @action.bound public updateFilters() {
    this.props.onChange(toJS(this.filters));
  }

  /** Handler for the role checkboxes */
  handleRoleChange =
    (name: keyof UserFilters['roles']) => (event: React.ChangeEvent<HTMLInputElement>) =>
      runInAction(() => {
        this.filters.roles[name] = event.target.checked;
      });

  /** Handler for the status change radio button */
  @action.bound public handleStatusChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.filters.status = event.target.value as UserFilters['status'];
  }

  @action.bound public updateAccount(account: Account | null) {
    this.filters.account = account ? account : undefined;
    this.filters.location = undefined;
  }

  @action.bound public updateLocation(location: Location | null) {
    this.filters.location = location ? location : undefined;
  }

  render() {
    const isAdmin = this.props.userStore!.authUser.isAdmin;
    const { onClose, onReset, showReset, showAccountSearch } = this.props;

    // return (
    //   <Box width={439}>
    //     <DialogTitle>
    //       <Box display="flex" alignItems="center" justifyContent="space-between">
    //         {/* <Typography variant="h5" display="inline">
    //         Settings
    //       </Typography> */}
    //       <TDrawer>
    //         Filters
    //       </TDrawer>
    //         <IconButton onClick={onClose}>
    //           <Close color="inherit" />
    //         </IconButton>
    //       </Box>
    //     </DialogTitle>
    //     <Divider />
    //     <DialogContent>
    //       <Box mt={2}>
    //         <Box mb={2}>
    //           <Title size="small" drawer mb={2}>
    //             Role
    //           </Title>
    //           <FormControl component="fieldset">
    //             <FormGroup>
    //               {!this.props.hideOwnersCheckbox && (
    //                 <FormControlLabel
    //                   control={
    //                     <Checkbox
    //                       color="primary"
    //                       checked={this.filters.roles.owners}
    //                       onChange={this.handleRoleChange('owners')}
    //                       value="owners"
    //                     />
    //                   }
    //                   label="Owners"
    //                 />
    //               )}
    //               <FormControlLabel
    //                 control={
    //                   <Checkbox
    //                     color="primary"
    //                     checked={this.filters.roles.managers}
    //                     onChange={this.handleRoleChange('managers')}
    //                     value="managers"
    //                   />
    //                 }
    //                 label="Managers"
    //               />
    //               <FormControlLabel
    //                 control={
    //                   <Checkbox
    //                     color="primary"
    //                     checked={this.filters.roles.talent}
    //                     onChange={this.handleRoleChange('talent')}
    //                     value="talent"
    //                   />
    //                 }
    //                 label="Talent"
    //               />
    //               {isAdmin && (
    //                 <FormControlLabel
    //                   control={
    //                     <Checkbox
    //                       color="primary"
    //                       checked={this.filters.roles.basic}
    //                       onChange={this.handleRoleChange('basic')}
    //                       value="basic"
    //                     />
    //                   }
    //                   label="Basic"
    //                 />
    //               )}
    //             </FormGroup>
    //           </FormControl>
    //           <Divider />
    //           <Box mt={2}>
    //             <Title size="small" drawer mb={2}>
    //               Status
    //             </Title>
    //             <FormControl component="fieldset">
    //               <RadioGroup
    //                 name="status"
    //                 value={this.filters.status}
    //                 onChange={this.handleStatusChange}>
    //                 <FormControlLabel value="all" control={<Radio color="primary" />} label="All" />
    //                 <FormControlLabel
    //                   value="active"
    //                   control={<Radio color="primary" />}
    //                   label="Active"
    //                 />
    //                 <FormControlLabel
    //                   value="inactive"
    //                   control={<Radio color="primary" />}
    //                   label="Inactive"
    //                 />
    //                 {this.props.showInvitationStatuses && (
    //                   <>
    //                     <FormControlLabel
    //                       value="pending_approval"
    //                       control={<Radio />}
    //                       label="Pending Approval"
    //                     />
    //                     <FormControlLabel value="rejected" control={<Radio />} label="Rejected" />
    //                   </>
    //                 )}
    //               </RadioGroup>
    //             </FormControl>
    //           </Box>
    //           <Divider />
    //           {showAccountSearch && (
    //             <Box mt={2} mb={2}>
    //               <Title size="small" drawer mb={3}>
    //                 Account
    //               </Title>
    //               <AccountSearch
    //                 placeholder="Enter name"
    //                 value={this.filters.account}
    //                 onChange={this.updateAccount}
    //               />
    //             </Box>
    //           )}
    //           {this.filters.account && (
    //             <Box mt={2} mb={2}>
    //               <Title size="small" drawer mb={3}>
    //                 Location
    //               </Title>
    //               <LocationSearch
    //                 accountId={this.filters?.account?.id}
    //                 placeholder="Enter name"
    //                 value={this.filters.location}
    //                 onChange={this.updateLocation}
    //               />
    //             </Box>
    //           )}
    //         </Box>
    //       </Box>

    //     </DialogContent>
    //     {/* <DD.Actions drawer>

    //     </DD.Actions> */}
    //     <DialogActions>
    //     {showReset && <DD.ResetButton onClick={onReset}>Reset filters</DD.ResetButton>}
    //       <DD.ApplyButton onClick={this.updateFilters}>Apply</DD.ApplyButton>
    //     </DialogActions>
    //   </Box>
    // );

    return (
      <>
        <DialogTitle style={{ paddingBottom: 16 }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <TDrawer>Filters</TDrawer>
            <IconButton onClick={onClose}>
              <Close color="inherit" />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DD>
          <DD.Content>
            <Box>
              <Box mb={2}>
                <Title size="small" drawer mb={2}>
                  Role
                </Title>
                <Box mb={2}>
                  <FormControl component="fieldset">
                    <FormGroup>
                      {!this.props.hideOwnersCheckbox && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={this.filters.roles.owners}
                              onChange={this.handleRoleChange('owners')}
                              value="owners"
                            />
                          }
                          label="Owners"
                        />
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={this.filters.roles.managers}
                            onChange={this.handleRoleChange('managers')}
                            value="managers"
                          />
                        }
                        label="Managers"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={this.filters.roles.talent}
                            onChange={this.handleRoleChange('talent')}
                            value="talent"
                          />
                        }
                        label="Employee"
                      />
                      {isAdmin && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={this.filters.roles.basic}
                              onChange={this.handleRoleChange('basic')}
                              value="basic"
                            />
                          }
                          label="Basic"
                        />
                      )}
                    </FormGroup>
                  </FormControl>
                </Box>
                <Divider />
                <Box mt={2} mb={2}>
                  <Title size="small" drawer mb={2}>
                    Status
                  </Title>
                  <FormControl component="fieldset">
                    <RadioGroup
                      name="status"
                      value={this.filters.status}
                      onChange={this.handleStatusChange}>
                      <FormControlLabel
                        value="all"
                        control={<Radio color="primary" />}
                        label="All"
                      />
                      <FormControlLabel
                        value="active"
                        control={<Radio color="primary" />}
                        label="Active"
                      />
                      <FormControlLabel
                        value="inactive"
                        control={<Radio color="primary" />}
                        label="Inactive"
                      />
                      {this.props.showInvitationStatuses && (
                        <>
                          <FormControlLabel
                            value="pending_approval"
                            control={<Radio />}
                            label="Pending Approval"
                          />
                          <FormControlLabel value="rejected" control={<Radio />} label="Rejected" />
                        </>
                      )}
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Divider />
                {showAccountSearch && (
                  <Box mt={2} mb={2}>
                    <Title size="small" drawer mb={3}>
                      Account
                    </Title>
                    <AccountSearch
                      label="Enter name"
                      value={this.filters.account}
                      onChange={this.updateAccount}
                    />
                  </Box>
                )}
                {this.filters.account && (
                  <Box mt={2} mb={2}>
                    <Title size="small" drawer mb={3}>
                      Location
                    </Title>
                    <LocationSearch
                      accountId={this.filters.account.id}
                      label="Enter name"
                      value={this.filters.location}
                      onChange={this.updateLocation}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </DD.Content>
          <DD.Actions drawer>
            {showReset && <DD.ResetButton onClick={onReset}>Reset filters</DD.ResetButton>}
            <DD.ApplyButton onClick={this.updateFilters}>Apply</DD.ApplyButton>
          </DD.Actions>
        </DD>
      </>
    );
  }
}

export default withStyles(styles)(UserFilterDrawer);
