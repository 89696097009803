import React from 'react';
import { observer } from 'mobx-react';
import DP from 'components/DashPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { useStyles } from './styles';
import useEmailPanel from './useEmailPanel';
import Dialog from 'components/Dialog/Dialog';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';

const EmailPanel = observer(() => {
  const {
    email,
    indicatorProps,
    open,
    submitting,
    submit,
    emailField,
    error,
    handleOpen,
    handleClose,
    onExited,
  } = useEmailPanel();
  const { editIcon } = useStyles();
  return (
    <DP>
      <DP.Header>
        <DP.Title panel>Email</DP.Title>
        <DP.Actions>
          <FontAwesomeIcon onClick={handleOpen} className={editIcon} icon={faPen} />
        </DP.Actions>
      </DP.Header>
      <DP.Body>
        <DP.Row>
          <DP.Value>
            {email} <DP.Indicator {...indicatorProps} />
          </DP.Value>
          <DP.Label>Email</DP.Label>
        </DP.Row>
      </DP.Body>
      <Dialog
        open={open}
        title={'Change Email'}
        loading={submitting}
        disabled={!!error}
        onConfirm={submit}
        onCancel={handleClose}
        content={
          <OutlinedInput
            fullWidth
            disabled={submitting}
            error={error}
            {...(emailField && emailField.bind())}
            value={emailField ? emailField.value : ''}
          />
        }
        TransitionProps={{
          onExited,
        }}
      />
    </DP>
  );
});

export default EmailPanel;
