import DP from 'components/DashPanel';
import { EmptyPanelMessage } from 'components/EmptyPanelMessage/EmptyPanelMessage';
import { observable, makeObservable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Card } from 'models';
import React from 'react';
import { inject, WithToastStore, WithUserStore } from 'stores';
import CardListItem from './CardListItem';
import Dialog from 'components/Dialog/Dialog';
import { DotsVertical } from 'mdi-material-ui';
import { IconButton, Menu, MenuItem, Typography, Box, Tooltip } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/styles/withStyles';
import styles from './styles';
import { IKycStatus } from 'models/Kyc';
import { ACL } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

interface CardsPanelProps extends WithStyles<typeof styles>, WithToastStore, WithUserStore {
  children?: Card[];
  kycStatus?: IKycStatus;
  updateKycStatus: () => void;
  primaryCardId?: number;
  payoutInterval: string;
  userId: number;
  loading: boolean;
  refreshCards: () => void;
}

@inject('toastStore', 'userStore')
@observer
class CardsPanel extends React.Component<CardsPanelProps> {
  constructor(props: CardsPanelProps) {
    super(props);
    makeObservable(this);
  }

  @observable public primaryCardId?: number;
  @observable public inProgress = false;
  @observable public showModal = false;

  @observable public menuAnchorEl: null | HTMLElement = null;

  @action.bound public resetKycAttempsLeft = async () => {
    this.showModal = false;
    this.closePanelMenu();
    this.inProgress = true;
    this.props.updateKycStatus();
    this.inProgress = false;
  };

  @action.bound public closePanelMenu = () => {
    this.menuAnchorEl = null;
  };

  @action.bound renderAttempsLeft = () => {
    return this.props?.kycStatus?.attemptsLeft ?? 0 > 0 ? (
      <Box mr={2}>
        <Typography variant="body2">
          ( {this.props?.kycStatus?.attemptsLeft} KYC attempts left )
        </Typography>
      </Box>
    ) : (
      <Tooltip
        title={
          'User used all KYC attempts or has KYC blocked for some other reason. You can find reset button in panel’s menu.'
        }
        placement="top"
        enterDelay={500}>
        <Box mr={2}>
          <FontAwesomeIcon
            onMouseDown={(event) => event.stopPropagation()}
            icon={faInfoCircle}
            className={this.props.classes.faInfoCircle}
          />
        </Box>
      </Tooltip>
    );
  };

  @action.bound public openPanelMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.menuAnchorEl = event.currentTarget;
  };

  menuItems = [
    {
      label: 'Reset KYC Attempts',
      onClick: () => (this.showModal = true),
    },
  ];

  render() {
    const { children: cards, loading } = this.props;
    const disabled = !!this.props.kycStatus?.resetBlocked;

    return (
      <>
        <DP>
          <DP.Header>
            <DP.Title panel>Cards</DP.Title>
            <DP.Actions>
              {this.inProgress ? (
                <DP.LoadSpinner />
              ) : (
                <>
                  {this.renderAttempsLeft()}
                  <IconButton size="small" onClick={this.openPanelMenu}>
                    <DotsVertical fontSize="small" />
                  </IconButton>
                  <Menu
                    anchorEl={this.menuAnchorEl}
                    open={Boolean(this.menuAnchorEl)}
                    onClose={() => this.closePanelMenu()}>
                    {this.menuItems.map(
                      (menuItem, i) =>
                        this.props.userStore!.hasPermission(ACL.RESET_KYC_ATTEMPTS) && (
                          <Tooltip
                            title={disabled && 'Conditions for KYC attempts reset are not met'}>
                            <Box component={'span'}>
                              <MenuItem
                                dense
                                key={i}
                                disabled={disabled}
                                onClick={() => (!disabled ? menuItem.onClick() : null)}
                                className={
                                  !disabled ? this.props.classes.menuItemEnabled : undefined
                                }>
                                {menuItem.label}
                              </MenuItem>
                            </Box>
                          </Tooltip>
                        ),
                    )}
                  </Menu>
                </>
              )}
            </DP.Actions>
          </DP.Header>
          {!loading ? (
            <DP.List>
              {cards ? (
                cards.map((card) => (
                  <CardListItem
                    key={card.id}
                    isPrimary={this.props.primaryCardId === card.id}
                    payoutInterval={this.props.payoutInterval}
                    refreshCards={this.props.refreshCards}>
                    {card}
                  </CardListItem>
                ))
              ) : (
                <DP.Body>
                  <EmptyPanelMessage panelTitle="Cards" />
                </DP.Body>
              )}
            </DP.List>
          ) : (
            <DP.Loading items={3} size="large" />
          )}
        </DP>
        <Dialog
          open={this.showModal}
          title={'Rest KYC Attempts'}
          content={
            <Box>
              <Typography variant="body2">You will add 3 more attempts.</Typography>
            </Box>
          }
          onCancel={() => (this.showModal = false)}
          onConfirm={this.resetKycAttempsLeft}
          cancelActionName={'Cancel'}
          confirmActionName={'Add'}
          loading={this.inProgress}
        />
      </>
    );
  }
}

// export default CardsPanel;
export default withStyles(styles)(CardsPanel);
