// noinspection JSUnusedLocalSymbols

import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import Button from '@material-ui/core/Button';

const PlaidTokenRedirect = ({ token, onSuccess }: { token: any; onSuccess: any }) => {
  const config: PlaidLinkOptions = {
    // When re-initializing Link after OAuth redirection, the same
    // Link token from the first initialization must be used
    token: token!,
    onSuccess,
    // receivedRedirectUri: document.location.href, // required for OAuth
    // onExit
    // onEvent
  };

  const { open, ready, error } = usePlaidLink(config);

  // this opens link as soon as it's ready
  useEffect(() => {
    if (!ready || !token) {
      return;
    }
    open();
  }, [ready, open, token]);

  return <div></div>;
};

const PlaidRedirect: FunctionComponent = () => {
  // @ts-ignore
  const onSuccess = useCallback((public_token, metadata) => {
    // send public_token to server
  }, []);

  const [showButton, setShowButton] = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined | null>(
    window.localStorage.getItem('plaidLinkToken'),
  );

  useEffect(() => {
    if (token) {
      setToken(token);
    } else {
      setShowButton(true);
      window.location.href = 'tippy://';
    }
  }, []);

  // eslint-disable-next-line no-constant-condition
  if (showButton) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
          alignItems: 'center',
          alignContent: 'center',
        }}>
        <Button
          color={'primary'}
          variant={'contained'}
          onClick={() => {
            window.location.href = 'tippy://';
          }}>
          Back to app
        </Button>
      </div>
    );
  }

  if (token) {
    return <PlaidTokenRedirect token={token} onSuccess={onSuccess} />;
  }

  // don't render anything, just open Link
  return <div></div>;
};

export default PlaidRedirect;
