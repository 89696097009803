import { Grid } from '@mui/material';

import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldErrors,
  FieldValues,
} from 'react-hook-form';

import { IFormFieldsConfig } from './FormsConfig';
import OutlinedInput from 'components/Input/OutlinedInput';

interface FormBuilderProps {
  formFieldsConfig: IFormFieldsConfig[];
  control: Control<FieldValues, unknown>;
  errors: FieldErrors<FieldValues>;
  marginBottom?: number;
}

export const FormBuilder = ({
  formFieldsConfig,
  control,
  errors,
  marginBottom = 16,
}: FormBuilderProps) => (
  <Grid container direction={'row'} spacing={1} sx={{ marginBottom: `${marginBottom}px` }}>
    {formFieldsConfig.map((formItem: IFormFieldsConfig) => (
      <Grid item key={formItem.name} {...(formItem.size || { xs: 12 })}>
        <Controller
          key={formItem.label}
          name={formItem.name}
          control={control}
          rules={formItem.rules}
          render={({ field }: { field: ControllerRenderProps<FieldValues, string> }) => (
            <OutlinedInput
              {...field}
              label={formItem.label}
              autoFocus={formItem.autoFocus}
              error={!!errors[formItem.name]}
              control={control}
              required={formItem.required}
              disabled={formItem.disabled}
              fullWidth={formItem.fullWidth}
              variant={formItem.variant}
            />
          )}
        />
      </Grid>
    ))}
  </Grid>
);
