import React from 'react';
import { Button as MuiButton, ButtonProps, CircularProgress } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';

export default function Button({
  children,
  loading,
  ...props
}: ButtonProps & { loading?: boolean }) {
  const classes = useStyles();
  const btnStyle = props.variant !== 'contained' ? classes.outlined : classes.contained;
  if (loading) {
    props.disabled = true;
  }
  return (
    <MuiButton {...props} className={clsx(classes.button, btnStyle, props.className)}>
      {loading ? (
        <CircularProgress size={'60%'} classes={{ circle: classes.progress }} />
      ) : (
        children
      )}
    </MuiButton>
  );
}
