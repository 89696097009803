import { Typography, TypographyProps, useTheme } from '@mui/material';

export enum ETitleVariant {
  PAGE = 'page',
  DIALOG = 'dialog',
  PANEL = 'panel',
  SIGNUP = 'signup',
}

export interface ITitleProps {
  children?: string;
  variant?: ETitleVariant;
  mb?: number;
  center?: boolean;
  fontWeight?: TypographyProps['fontWeight'];
}

const Title = ({
  children,
  mb = 0,
  variant = ETitleVariant.PAGE,
  center,
  fontWeight,
}: ITitleProps) => {
  const theme = useTheme();

  if (!children) return null;

  return (
    <Typography
      variant={variant}
      fontWeight={fontWeight}
      align={center ? 'center' : 'inherit'}
      style={{ marginBottom: theme.spacing(mb) }}>
      {children}
    </Typography>
  );
};

export default Title;
