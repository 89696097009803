import React from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps } from '@material-ui/core';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';

interface ICheckbox {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onChange?: CheckboxProps['onChange'];
  disabled?: CheckboxProps['disabled'];
  checked?: CheckboxProps['checked'];
  icon?: CheckboxProps['icon'];
  checkedIcon?: CheckboxProps['checkedIcon'];
  className?: string;
  name?: string | undefined;
  style?: React.CSSProperties | undefined;
  dataCy?: string;
  disablePadding?: boolean;
}

export function Checkbox(props: ICheckbox) {
  const { onClick, onChange, disabled, checked, name, style, dataCy, disablePadding } = props;

  let { icon, checkedIcon, className } = props;

  const classes = useStyles({ disablePadding });

  className = clsx(className, disablePadding && classes.padding);
  icon = icon || (
    <FontAwesomeIcon className={clsx(classes.icon, classes.unchecked)} icon={faSquare} />
  );
  checkedIcon = checkedIcon || <FontAwesomeIcon className={classes.icon} icon={faSquareCheck} />;

  return (
    <MuiCheckbox
      color="primary"
      style={style}
      name={name}
      classes={{ root: classes.checkbox }}
      className={className}
      icon={icon}
      checkedIcon={checkedIcon}
      onClick={onClick}
      onChange={onChange}
      disabled={disabled}
      checked={checked}
      inputProps={
        {
          'data-cy': dataCy,
        } as any
      }
    />
  );
}
