import React from 'react';
import DP from 'components/DashPanel';
import { Avatar, Box, Typography } from '@material-ui/core';
import { BankAccountStatus, parseStatus } from '../../services';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import styles from '../../components/BankAccountPanel/styles';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import TippyPlaidLink from '../../components/PlaidLink';
import Api, { getErrorMsg } from '../../api';
import { inject, WithAccountInfoStore, WithModalStore, WithToastStore } from '../../stores';
import { BankAccount } from 'models';
import { default as DialogButton } from 'components/Button/Dialog/Button';

interface BanksPanelItemProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithAccountInfoStore,
    WithModalStore {
  bankAccount: any;
  onClickManage: (bankAccount: any) => void;
  onManualVerificationSuccess: (bankAccount: any) => void;
  refetchAccounts: () => void;
}

@inject('toastStore', 'modalStore', 'accountInfoStore')
@observer
class BanksPanelItem extends React.Component<BanksPanelItemProps> {
  constructor(props: BanksPanelItemProps) {
    super(props);
    makeObservable(this);
  }

  @observable public fullBankData?: any;
  @observable public fetchingToken = false;
  @observable public reconnecting = false;

  @computed public get showPlaidLink(): boolean {
    return this.props.bankAccount.verificationStatus === 'pending_manual_verification';
  }

  @computed public get linkToken() {
    return this.fullBankData && this.fullBankData.linkToken;
  }

  @computed public get publicToken() {
    return this.fullBankData && this.fullBankData.publicToken;
  }

  @action.bound public fetchFullBankData = flow(function* (this: BanksPanelItem) {
    try {
      const accountId = this.props.bankAccount.id!;
      const resp = yield Api.core.getBankAccount(accountId);
      this.fullBankData = resp.data.data;
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.fetchingToken = false;
    }
  });

  @action.bound public onVerificationSuccess = flow(function* (this: BanksPanelItem) {
    this.props.accountInfoStore!.setCheckingForPendingIntegrations(true);
    try {
      const accountId = this.props.bankAccount.id!;
      yield Api.core.activateBankAccountV2(accountId);
      this.props.onManualVerificationSuccess(this.props.bankAccount);
      this.props.refetchAccounts();
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.props.accountInfoStore!.setCheckingForPendingIntegrations(false);
    }
  });

  /**
   * Calls the API endpoint for reconnecting the bank account, which essentially
   * gets all the data from the dwolla and plaid again and writes that into the DB.
   */
  @action.bound public reconnect = flow(function* (this: BanksPanelItem) {
    try {
      // Trigger a warning
      const confirmed: boolean = yield this.props.modalStore!.confirm(
        'Reconnect bank account?',
        <span>
          Doing this will fetch the bank account data from Plaid and Dwolla again. If a user is
          having issues with their bank account, this might help them.
        </span>,
      );
      if (confirmed) {
        // Call the API endpoint and display a toast notification
        const bankAccount = this.props.bankAccount;
        this.reconnecting = true;
        yield Api.core.reconnectBankAccount(bankAccount.id);
        yield this.props.refetchAccounts();
        this.props.toastStore!.success(`Bank account successfully reconnected!`);
      }
    } catch (e) {
      this.props.modalStore!.confirm(
        `Verification failed`,
        `Unable to verify bank account at this time: ${getErrorMsg(e)}`,
        {
          hideCancel: true,
          confirmLabel: 'OK',
        },
      );
    } finally {
      this.reconnecting = false;
    }
  });

  componentDidMount() {
    if (this.showPlaidLink) {
      this.fetchFullBankData();
    }
  }

  renderPlaidLink() {
    const checkingForPendingIntegrations =
      this.props.accountInfoStore!.checkingForPendingIntegrations;
    if (!this.showPlaidLink || checkingForPendingIntegrations) {
      return <div />;
    }
    if (!this.publicToken || this.fetchingToken) {
      return <DP.LoadSpinner />;
    }
    return (
      <TippyPlaidLink
        className={this.props.classes.verifyButton}
        token={this.linkToken}
        publicToken={this.publicToken}
        onSuccess={this.onVerificationSuccess}>
        <DialogButton variant={'contained'}>verify</DialogButton>
      </TippyPlaidLink>
    );
  }

  isBankAccountVerified = (bank: BankAccount) => {
    const verificationStatus = bank.verificationStatus;
    if (
      verificationStatus === BankAccountStatus.AUTOMATICALLY_VERIFIED ||
      verificationStatus === BankAccountStatus.MANUALLY_VERIFIED ||
      verificationStatus === BankAccountStatus.VERIFIED
    ) {
      return true;
    }
    return false;
  };

  render() {
    const bank = this.props.bankAccount;
    let menu: any[] = [{ label: 'Re-verify', disabled: false, onClick: this.reconnect }];

    if (this.isBankAccountVerified(bank)) {
      menu.unshift({
        label: 'Manage',
        onClick: () => this.props.onClickManage(bank),
      });
    }

    const icon = (
      <Avatar
        src={bank.institutionLogo ? `data:image/png;base64,${bank.institutionLogo}` : undefined}
        alt={bank.institutionName ? bank.institutionName : 'Unknown bank name'}
      />
    );

    const secondaryText = (
      <Box component={'span'} pt={1} display="flex" flexDirection="row" alignItems="center">
        <Typography component={'span'}>{parseStatus(bank)}</Typography>
      </Box>
    );

    return (
      <DP.ListItem
        key={bank.accountId}
        icon={icon}
        primary={
          <span>
            {bank.institutionName} - {bank.accountName} - {bank.accountMask}
          </span>
        }
        secondary={secondaryText}
        menu={menu.length ? menu : undefined}
        rightContent={this.renderPlaidLink()}
        indicator={{
          color: bank.isActive ? 'green' : 'red',
          tooltip: bank.isActive ? 'ACTIVE' : 'INACTIVE',
        }}
      />
    );
  }
}

export default withStyles(styles)(BanksPanelItem);
