import { Product } from './Product';
import { Location } from './Location';
import { Account } from './Account';
import { User } from './User';
import { Affiliate } from './Affiliate';
import { ShippingAddress } from 'models';
import { EInvoiceItemType } from 'types';

export enum PromotionType {
  PERCENT = 'percent_discount',
  AMOUNT = 'amount_discount',
  TIME_FREE = 'time_free',
  SHIPPING_FREE = 'shipping_free',
  QUANTITY_FREE = 'quantity_free',
}

export interface Shipping {
  /**
   * Represents shipping product id
   */
  id: number;
  /**
   * Represents product name
   */
  name: string;
  /**
   * Represents product description
   */
  description: string;
  /**
   * Represents product price
   */
  price: string;
  /**
   * Represents if product is active
   */
  isActive: boolean;
}

export interface CartPromotion {
  id: number;
  promotion: Promotion;
  promotionId: number;
  type: PromotionType;
}

export interface Promotion {
  id: number;
  /**
   * Product id that promotion should be applied to
   */
  affiliateId: number | null;
  productId: number;
  /**
   * Promotion code
   */
  code: string;
  /**
   * Promotion description
   */
  description: string;
  /**
   * Type of a promotion
   */
  type: PromotionType;
  /**
   * Promotion discount value for different types
   * @PERCENT Type is represented as decimal percent value, e.g. 40%
   * @AMOUNT Type is represented as decimal value of $, e.g. $99
   * @TIME_FREE Type is represented as integer value of MONTHS free, e.g. 2
   * @SHIPPING_FREE Type is represented as integer set to 0 as it is not used
   * @QUANTITY_FREE Type is represented as integer of items free, e.g. 1 (at buy two get 1 free)
   */
  discount: string;
  /**
   * Promotion items bought to get discounted quantity
   * @QUANTITY_FREE Available on this type only and represents items that should be bought to get free item
   */
  quantityFreeItems: number;
  /**
   * Promotion expiration date
   */
  expiresAt: string;
  /**
   * Calculated discount amount applied to item
   * Be noticed that all promotions are calculated from item net amount
   */
  discountAmount: string;
  isApplied?: boolean;
  canBeApplied?: boolean;
}

export interface CartItem {
  /**
   *  Represents cart item id
   */
  id: number;
  /**
   *  Represents cart item locationId
   */
  locationId: number;
  /**
   * Represents item quantity
   */
  quantity: number;
  /**
   * Represents item amount
   */
  amount: string;
  /**
   * Represents item discount
   */
  discount: string;
  /**
   * Represents discounted amount
   */
  net: string;
  /**
   * Represents item tax amount
   */
  tax?: string;
  /**
   * Represents item gross amount
   */
  gross?: string;
  /**
   * Total shipping cost for cart item
   */
  shippingAmount: string;
  /**
   * Shipping discount amount
   */
  shippingDiscount?: string;
  /**
   * Represents discounted shipping amount
   */
  shippingNet: string;
  /**
   * Shipping tax
   */
  shippingTax?: string;
  /**
   * Shipping net with tax
   */
  shippingGross?: string;
  /**
   * Item due on date
   */
  dueOn: string;
  /**
   * Product license expiration date based
   * on subscription interval months
   */
  expiresAt: string;
  /**
   * Represents shipping data
   */
  shipping: Shipping;
  /**
   * Represents product data
   */
  product: Product;
  /**
   * Represents location data
   */
  location: Location;
  /**
   * Represents promotions applied to cart item
   */
  promotions: Promotion[];
  /**
   * Represents address to which an item is shipped
   */
  shippingAddress: ShippingAddress;
  /**
   * Represents invoice item type
   */
  invoiceItemType: EInvoiceItemType;
}

export interface Cart {
  id: number;
  uid: string;
  accountId: number;
  account: Account;
  cartItem: CartItem;
  amount?: string;
  discount?: string;
  tax?: string;
  net: string;
  gross: string;
  dueOn: string;
  totalShipping?: string;
  promotions: CartPromotion[];
  items: CartItem[];
  isTaxApplied: boolean;
  payLater?: string;
  affiliateUserId: number | null;
  affiliate?: Affiliate;
  owner?: Pick<User, 'firstName' | 'lastName' | 'email'>;
  requiresCreditCard?: boolean;
  expiresAt?: string;
  ecc: boolean;
}
