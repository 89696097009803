import Api from 'api';
import { action, makeObservable, observable, runInAction } from 'mobx';
import react from 'react';
import BaseStore from './BaseStore';
import RootStore from './RootStore';

/** Represents a single confirmation dialog */
interface ConfirmDialog {
  /** The title of the confirmation dialog */
  title: string | react.ReactNode;
  /** The confirmation message to display */
  msg: react.ReactNode;
  /** The label for the confirm button */
  confirmLabel?: string | react.ReactNode;
  /** The label for the cancel button */
  cancelLabel?: string | react.ReactNode;
  /** The callback to confirm the action */
  confirm: () => void;
  /** The callback to cancel the action */
  cancel: () => void;
}

/**
 * Represents a dialog for confirming a user's identity
 * via a 2FA-like code
 */
interface IdentityConfirmDialog {
  /** An optional message to accompany this dialog */
  msg?: string;
  /** Send the verification with optional destination */
  send: (destination?: 'phone' | 'email') => void;
  /** The callback to confirm the identity verification successfully */
  confirm: (code: string | undefined) => void;
  /** The callback to cancel the identity verification */
  cancel: () => void;
}

/**
 * Use this store to trigger dialog windows in modals.
 */
export default class ModalStore extends BaseStore {
  constructor(props: RootStore) {
    super(props);
    makeObservable(this);
  }
  /** Represents the currently displayed confirmation modal */
  @observable public confirmDialog?: ConfirmDialog;
  /**
   * Creates a modal confirmation dialog, with the only options
   * being confirming the action or canceling. Returns a promise that
   * resolves to true if the user confirms or false if the user
   * cancels.
   * @param title The title of the dialog
   * @param msg The JSX to display in the confirmation dialog
   * @param cancelLabel Optional label for the cancel button, default: Cancel
   * @param confirmLabel Optional label for the confirm button, default: Continue
   */
  @action.bound public confirm(
    title: string | react.ReactNode,
    msg: react.ReactNode,
    options?: {
      cancelLabel?: string | react.ReactNode;
      confirmLabel?: string | react.ReactNode;
      hideConfirm?: boolean;
      hideCancel?: boolean;
    },
  ): Promise<boolean> {
    const defaultOptions = {
      cancelLabel: 'Cancel',
      confirmLabel: 'Confirm',
      hideConfirm: false,
      hideCancel: false,
    };
    const { cancelLabel, confirmLabel, hideConfirm, hideCancel } = {
      ...defaultOptions,
      ...options,
    };
    return new Promise((resolve) =>
      // We need to use runInAction because we're making a promise directly
      // instead of using `flow`
      runInAction(() => {
        // Check to see if a dialog is already being displayed, although it
        // shouldn't be. If it is, call its canceled callback.
        if (this.confirmDialog) {
          this.confirmDialog.cancel();
        }
        // Set the current confirmDialog and create the callbacks
        // for confirming and rejecting and save them to the store.
        this.confirmDialog = {
          confirmLabel: hideConfirm ? undefined : confirmLabel,
          cancelLabel: hideCancel ? undefined : cancelLabel,
          title,
          msg,
          confirm: () =>
            runInAction(() => {
              this.confirmDialog = undefined;
              resolve(true);
            }),
          cancel: () =>
            runInAction(() => {
              this.confirmDialog = undefined;
              resolve(false);
            }),
        };
      }),
    );
  }

  @observable public identityConfirmDialog?: IdentityConfirmDialog;

  /**
   * Opens a dialog for 2FA authentication and returns a promise
   * that reports whether the 2FA code was entered successfully
   * or not.
   * @param msg An optional message to display in the dialog
   */
  @action.bound public identityConfirm(
    msg?: string,
  ): Promise<{ code: string | undefined; ok: boolean }> {
    return new Promise((resolve) => {
      runInAction(() => {
        // Cancel if one is already active
        if (this.identityConfirmDialog) {
          this.identityConfirmDialog.cancel();
        }
        // If the user is an admin, just return undefined, as admin can pass undefined
        // codes to the API and it will let them through
        if (this.rootStore.userStore.isAdmin) {
          resolve({ code: undefined, ok: true });
          return;
        }
        this.identityConfirmDialog = {
          msg,
          send: (destination?: 'phone' | 'email') => {
            // Call the API to send a confirmation number
            const { id } = this.rootStore.userStore.authUser;
            Api.core.sendIdentityVerification(id, destination);
          },
          confirm: (code) =>
            runInAction(() => {
              this.identityConfirmDialog = undefined;
              resolve({ code, ok: true });
            }),
          cancel: () =>
            runInAction(() => {
              this.identityConfirmDialog = undefined;
              resolve({ code: undefined, ok: false });
            }),
        };
      });
    });
  }
  public init() {}
}

export interface WithModalStore {
  modalStore?: ModalStore;
}
