import React from 'react';
import { Box, Chip, CircularProgress, Drawer, ListItem, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Api, { getErrorMsg, RequestMetaData } from 'api';
import DD from 'components/DashDrawer';
import DP, { IListItemMenuItem } from 'components/DashPanel';
import { action, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as models from 'models';
import moment from 'moment-timezone';
import { numericStringToUsd } from 'services/currency';
import { inject, WithModalStore, WithToastStore, WithUserStore } from 'stores';
import styles from './styles';
import clsx from 'clsx';
import { ACL } from 'types';

interface CardListItemProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithModalStore,
    WithUserStore {
  children: models.Card;
  payoutInterval: string;
  isPrimary: boolean;
  refreshCards: () => void;
}

/** Represents a single item in the CardsPanel list of cards */
@inject('toastStore', 'modalStore', 'userStore')
@observer
class CardListItem extends React.Component<CardListItemProps> {
  constructor(props: CardListItemProps) {
    super(props);
    makeObservable(this);
  }

  /**
   * We get last payout on componentMount because we want to show error
   * indicator on card list item if this cards last payout failed.
   */
  @observable lastPayout?: models.CardPayout;

  /** List of cards. Loads on payouts history drawer open event */
  @observable payouts?: models.CardPayout[];

  /** Is payout history drawer opened? */
  @observable payoutsDrawerOpen = false;

  @observable isPrimary: boolean = this.props.isPrimary;

  /**
   * Toggle payouts drawer for this card. If drawer was opened
   * fetch the payouts, if it was closed clear them.
   */
  @action.bound private togglePayoutsDrawer() {
    this.payoutsDrawerOpen = !this.payoutsDrawerOpen;
    if (this.payoutsDrawerOpen) {
      this.getPayouts();
    } else {
      this.payouts = undefined;
    }
  }

  /** Fetch payouts for this card */
  @action.bound public getPayouts = flow(function* (this: CardListItem) {
    try {
      const resp = yield Api.tips.getCardPayouts(this.props.children.id);
      this.payouts = resp.data.data;
    } catch (e: any) {
      this.payouts = [];
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  });

  /** Fetch only last payout for this card. */
  @action.bound public getLastPayout = flow(function* (this: CardListItem) {
    try {
      const rmd: RequestMetaData = { pagination: { take: 1, skip: 0 } };
      const resp = yield Api.tips.getCardPayouts(this.props.children.id, rmd);
      const payouts = resp.data.data;
      if (payouts.length > 0) this.lastPayout = payouts[0];
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  });

  /** Set card as primary payout destination */
  @action.bound public setCardAsPrimary = flow(function* (this: CardListItem, cardId: number) {
    try {
      yield Api.core.makeCardPrimary(cardId);
      this.props.toastStore!.success('Debit card set as primary!');
      yield this.props.refreshCards();
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  });

  /** Set card as primary payout destination */
  @action.bound public removeCard = flow(function* (this: CardListItem, cardId: number) {
    try {
      const debitCard = this.props.children;
      // Trigger a warning
      const confirmed: boolean = yield this.props.modalStore!.confirm(
        'Remove debit card',
        <span>
          Are you sure you want to remove the{' '}
          <Typography color="textPrimary">{`${debitCard.accountBrand}  -  ${debitCard.cardBrand}`}</Typography>
        </span>,
        {
          confirmLabel: <Typography color="error">Remove</Typography>,
        },
      );

      if (confirmed) {
        yield Api.core.deleteCard(cardId);
        yield this.props.refreshCards();
        this.props.toastStore!.success('Debit card successfully deleted!');
      }
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  });

  componentDidMount() {
    this.getLastPayout();
  }

  render() {
    const { children: card, classes } = this.props;
    const menu: IListItemMenuItem[] = [
      { label: 'History', onClick: this.togglePayoutsDrawer, disabled: false },
    ];

    if (this.props.userStore?.hasPermission(ACL.RESET_KYC_ATTEMPTS)) {
      menu.unshift({
        label: <Typography color="error">Remove</Typography>,
        onClick: () => this.removeCard(card.id),
        disabled: false,
      });
    }

    // If card was removed it cannot be set as primary
    if (card.status !== 'deleted') {
      menu.unshift({
        label: 'Set as primary',
        onClick: () => this.setCardAsPrimary(card.id),
        disabled: this.props.isPrimary,
      });
    }

    const secondaryText = (
      <Box className={clsx(classes.secondaryText)}>
        {card.status.toUpperCase()}
        <Typography color={card.status === 'deleted' ? 'error' : 'initial'}></Typography>
        <Typography className={clsx(classes.regularText)}>
          <span className={classes.verticalLine}></span>
          {this.props.payoutInterval.charAt(0).toUpperCase() + this.props.payoutInterval.slice(1)}
          {''}
          {''} {'payout'}
        </Typography>
        {this.props.isPrimary && (
          <Box mr={2} mb={3} ml={'auto'} alignItems="right" fontStyle="normal">
            <Chip label="Primary" size="small" variant="outlined" color="primary" />
          </Box>
        )}
      </Box>
    );

    return (
      <>
        {card.status !== 'deleted' && (
          <DP.ListItem
            key={card.id}
            primary={`${card.accountBrand}  -  ${card.cardBrand}`}
            secondary={secondaryText}
            menu={menu}
            indicator={
              this.lastPayout && this.lastPayout.status === 'failed'
                ? { color: 'red', tooltip: 'Last payout failed' }
                : undefined
            }
          />
        )}
        <Drawer
          open={this.payoutsDrawerOpen}
          onClose={this.togglePayoutsDrawer}
          anchor="right"
          variant="temporary">
          <DD>
            <Box p={2}>
              <DD.Title onClose={this.togglePayoutsDrawer}>Payouts</DD.Title>
            </Box>
            <Box minWidth={520} height="100%">
              {this.payouts ? (
                this.payouts.map((payout) => {
                  return (
                    <ListItem key={payout.id} divider>
                      <Box display="flex" flexDirection="column" width="100%">
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                          <Box display="flex" flexDirection="column">
                            <Typography className={classes.payoutDate}>
                              {moment(payout.createdAt)
                                .tz('America/New_York')
                                .format('MMM DD, YYYY h:mm A')
                                .toUpperCase()}
                            </Typography>
                            <Typography
                              className={classes.payoutStatus}
                              color={payout.status === 'processed' ? 'primary' : 'error'}>
                              {payout.status.toUpperCase()}
                            </Typography>
                          </Box>
                          <Box display="flex" flexDirection="column">
                            <Typography
                              className={classes.payoutAmount}
                              align="right"
                              color="primary">
                              {numericStringToUsd(payout.amount)}
                            </Typography>
                            <Typography className={classes.payoutTrxId} align="right">
                              REF#: {payout.transactionId}
                            </Typography>
                          </Box>
                        </Box>
                        {payout.errorMessage && (
                          <Typography className={classes.payoutError} color="error">
                            {payout.errorCode && `[${payout.errorCode}]`} {payout.errorMessage}
                          </Typography>
                        )}
                      </Box>
                    </ListItem>
                  );
                })
              ) : (
                <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </DD>
        </Drawer>
      </>
    );
  }
}

export default withStyles(styles)(CardListItem);
