import * as models from 'models';

export function getSecondaryText(paymentMethod: models.BillingEntity | undefined) {
  if (!paymentMethod) {
    return;
  }
  const [month, year] = (paymentMethod.paymentMethod.validThru || '').split('/');
  let secondaryText = null;
  if (month && year) {
    const expirationDate = new Date();
    expirationDate.setMonth(parseInt(month) - 1);
    expirationDate.setFullYear(parseInt(year));

    const isExpired = new Date() > expirationDate;

    secondaryText = `${isExpired ? 'Expired' : 'Expires'} on ${
      paymentMethod.paymentMethod.validThru
    }`;
  }
  return secondaryText;
}
