import { isEmail, isPhone } from 'services';
import {
  EFormFieldLabels,
  IFormFieldsConfig,
  EFormFields,
  ErrorMessagesForms,
} from './FormsConfig';

/**
 * Text form field config
 */
export const FORM_TEXT = (
  required = true,
  disabled = false,
  label = EFormFieldLabels.NAME,
  name = EFormFields.NAME,
): IFormFieldsConfig => ({
  name,
  rules: {
    required: required && ErrorMessagesForms.FIELD_REQUIRED(label),
  },
  label,
  required,
  disabled,
  fullWidth: true,
  variant: 'normal',
});

/**
 * Email form field config
 */
export const FORM_EMAIL = (
  required = true,
  disabled = false,
  label = EFormFieldLabels.EMAIL,
): IFormFieldsConfig => ({
  name: EFormFields.EMAIL,
  rules: {
    required: required && ErrorMessagesForms.FIELD_REQUIRED(label),
    validate: (val: string) => isEmail(val) || ErrorMessagesForms.EMAIL_NOT_VALID,
  },
  label,
  required,
  disabled,
  fullWidth: true,
  variant: 'normal',
});

/**
 * Phone form field config
 */
export const FORM_PHONE = (
  required = true,
  disabled = false,
  label = EFormFieldLabels.PHONE,
): IFormFieldsConfig => ({
  name: EFormFields.PHONE,
  rules: {
    required: required && ErrorMessagesForms.FIELD_REQUIRED(label),
    validate: (val: string) => isPhone(val) || ErrorMessagesForms.PHONE_NOT_VALID,
  },
  label,
  required,
  disabled,
  fullWidth: true,
  variant: 'phone',
});
