import { Box, Typography } from '@material-ui/core';
import { useStyles } from '../styles';

export const InvoiceFooter = () => {
  const classes = useStyles();
  return (
    <Box my={5} px={3}>
      <Box mb={2}>
        <Typography align="center" variant="body2">
          If you have any questions or concerns, don’t hesitate to contact your Tippy Success Agent
          at 1.855.831.2323 or support@meettippy.com.
        </Typography>
      </Box>
      <Box>
        <Typography align="center" variant="body2" className={classes.miniText}>
          Made by Direct Tips Operations LLC | 2755 E Oakland Park Blvd. Ste 300, Ft. Lauderdale, FL
          33306
        </Typography>
      </Box>
    </Box>
  );
};
