import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import {
  ReactStripeElements,
  injectStripe,
  CardElement,
  Elements,
  StripeProvider,
} from 'react-stripe-elements';
import { Box, Typography, Button, Icon, Divider, FormControl } from '@material-ui/core';

import { inject, WithToastStore } from 'stores';
import config from 'config';
import { getErrorMsg } from 'api';

import {stylesCard} from './styles';
interface CreditCardFormProps {
  // Trigger loading
  startLoader: () => void;
  // Finish loading
  endLoader: () => void;
  // Called with the source id once it's successfully captured
  onSourceId: (s: string) => unknown;
  lastFour?: number;
  updateSuccess?: boolean;
  accountId?: number;
}

@inject('toastStore')
@observer
class CreditCardFormBase extends React.Component<CreditCardFormProps & WithToastStore & ReactStripeElements.InjectedStripeProps> {
  /** Submit updated credit card*/
  constructor(props: CreditCardFormProps & WithToastStore & ReactStripeElements.InjectedStripeProps){
    super(props);
    makeObservable(this);
  }
  @action.bound public submit = flow(function* (this: CreditCardFormBase) {
    try {
      this.props.startLoader();
      const resp = yield this.props.stripe!.createSource({ type: 'card', currency: 'USD' });
      if (resp.source && resp.source.id) {
        yield this.props.onSourceId(resp.source.id);
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.props.endLoader();
    }
  });
  /**
   * Handles the main form submission.
   * @param e The form submitted event
   */
  @action.bound public handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.submit();
  }

  renderSuccessView() {
   // const { classes } = this.props;
   const classes = stylesCard();
    return (
      <FormControl fullWidth margin="normal">
        <Box>
          <Typography variant="h3" component="h1" align="center">
            Sucess
          </Typography>
          <Box className={classes.viewContent}>
            <Icon color="primary" fontSize="large" className={classes.viewStatusIcon}>
              check
            </Icon>
            <Typography align="center">{`The card ****${this.props.lastFour} was successfully updated.`}</Typography>
          </Box>
          <Button
            component={RouterLink}
            to={`/accounts/${this.props.accountId}/billing`}
            size="large"
            variant="contained"
            color="primary"
            fullWidth>
            Go to dashboard
          </Button>
        </Box>
      </FormControl>
    );
  }

  renderStripeForm() {
    //const { classes } = this.props;
    const classes = stylesCard();
    return (
      <form onSubmit={this.handleSubmit}>
        <FormControl fullWidth margin="normal">
          <Box>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              Update payment method
            </Typography>
            <Box mt={4} mb={2}>
              <Typography color="textSecondary">
                Once you update a payment method, you can use it to pay for licenses
              </Typography>
            </Box>
            <Box className={classes.viewContent}>
              <Divider />
              <Box mt={3} mb={3}>
                <CardElement />
              </Box>
              <Divider />
            </Box>
            <Button size="large" type="submit" variant="contained" color="primary" fullWidth>
              Save
            </Button>
          </Box>
        </FormControl>
      </form>
    );
  }

  render() {
    return <>{this.props.updateSuccess ? this.renderSuccessView() : this.renderStripeForm()}</>;
  }
}
const CreditCardForm = injectStripe(CreditCardFormBase);

const StripeFormWrapper = ({startLoader, endLoader, onSourceId, lastFour, updateSuccess, accountId}: CreditCardFormProps) => (
      <StripeProvider apiKey={config.stripe.publicKey}>
        <Elements>
          <CreditCardForm
            startLoader={startLoader}
            endLoader={endLoader}
            onSourceId={onSourceId}
            lastFour={lastFour}
            updateSuccess={updateSuccess}
            accountId={accountId}
          />
        </Elements>
      </StripeProvider>
    );

export default StripeFormWrapper;
