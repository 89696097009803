import { Observer } from 'mobx-react';
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  Divider,
  ListItemText,
  Typography,
} from '@material-ui/core';
import moment from 'moment-timezone';
import clsx from 'clsx';

import { CartItem, Cart } from 'models';
import CartItemComponent from 'components/CartItem';
import { useStyles } from './styles';

function BigProgress() {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center" alignItems="center" className={classes.bigProgress}>
      <CircularProgress />
    </Box>
  );
}

interface CartPreviewProps {
  cart: Cart;
  cartInProgress?: boolean;
  deleteCartItem?: (item: CartItem) => Promise<void>;
  updateInvoiceType?: (cartItemId: number, cartItem: Partial<CartItem>) => Promise<void>;
}

/**
 * Displays the cart preview of the provided cart
 */
export default function CartPreview(props: CartPreviewProps) {
  const { deleteCartItem, updateInvoiceType, cartInProgress, cart } = props;
  const showTaxes = cart.isTaxApplied;
  const dueOnFormat = moment(cart.dueOn).format('LL');
  const classes = useStyles();
  const deleteDisabled = Boolean(!deleteCartItem);
  const totalLabel = cart.payLater ? (
    <>
      <Typography
        className={clsx(classes.itemAmount)}
        style={{ textTransform: 'uppercase' }}
        component="span">
        Due now
      </Typography>{' '}
      {!showTaxes && <>(before taxes)</>}
    </>
  ) : (
    <>
      <Typography
        className={clsx(classes.itemAmount)}
        style={{ textTransform: 'uppercase' }}
        component="span">
        Total
      </Typography>{' '}
      {!showTaxes && <>(before taxes)</>}
    </>
  );

  return (
    <Observer>
      {() => {
        // The right padding on items. If delete is disabled we don't
        // have to make room for the delete button
        const pr = deleteDisabled ? 0 : 5;
        if (!cart) return <Box />;
        return (
          <Box display="block" style={{ width: '100%', position: 'relative' }}>
            {cartInProgress && <BigProgress />}
            <List disablePadding>
              {cart.items.length === 0 && (
                <Box color="text.secondary" mb={2}>
                  The cart is empty
                </Box>
              )}
              {cart.items.map((item, i) => (
                <CartItemComponent
                  key={item.id}
                  deleteCartItem={deleteCartItem}
                  disableTopPadding={i === 0}
                  updateInvoiceType={
                    updateInvoiceType
                      ? (cartItem) => updateInvoiceType(item.id, cartItem)
                      : undefined
                  }>
                  {item}
                </CartItemComponent>
              ))}
              {cart.totalShipping && (
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography component="span" style={{ textTransform: 'uppercase' }}>
                        Shipping
                      </Typography>
                    }
                  />
                  <Box justifyContent="center" alignItems="center" flexBasis="1" pr={pr}>
                    <Typography component="div">{cart.totalShipping}</Typography>
                  </Box>
                </ListItem>
              )}
              {cart.discount && cart.discount !== '$0.00' && (
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography component="span" style={{ textTransform: 'uppercase' }}>
                        Discount
                      </Typography>
                    }
                  />
                  <Box justifyContent="center" alignItems="center" flexBasis="1" pr={pr}>
                    <Typography component="div">-{cart.discount}</Typography>
                  </Box>
                </ListItem>
              )}
              {showTaxes && cart.tax && (
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography component="span" style={{ textTransform: 'uppercase' }}>
                        Tax
                      </Typography>
                    }
                  />
                  <Box justifyContent="center" alignItems="center" flexBasis="1" pr={pr}>
                    <Typography component="div">{cart.tax}</Typography>
                  </Box>
                </ListItem>
              )}
              <Divider />
              {cart.gross && (
                <ListItem divider disableGutters>
                  <ListItemText primary={totalLabel} />
                  <Box justifyContent="center" alignItems="center" flexBasis="1" pr={pr}>
                    <Typography className={classes.itemAmount} component="div">
                      {cart.gross}
                    </Typography>
                  </Box>
                </ListItem>
              )}
              {cart.payLater && (
                <ListItem disableGutters>
                  <Box pt={2} textAlign="center" width="100%">
                    <Typography variant="body2">
                      Next payment for {cart.payLater} due on {dueOnFormat.split(',')[0]},{' '}
                      <b>{dueOnFormat.split(',')[1]}</b>
                    </Typography>
                  </Box>
                </ListItem>
              )}
            </List>
          </Box>
        );
      }}
    </Observer>
  );
}
