import { Box, Button, Icon, Input, Link } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Api, { getErrorMsg } from 'api';
import DP from 'components/DashPanel';
import { Check, Close, OpenInNew, Pencil } from 'mdi-material-ui';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Account, AccountUser, EmployeeInfo, EmployeeStatus, TalentIntegrationApp } from 'models';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { paths } from 'routes';
import { inject, WithModalStore, WithToastStore, WithUserStore } from 'stores';
import styles from './styles';
import { WithRouterStore } from '../../stores/RouterStore';
import { Skeleton, Tooltip } from '@mui/material';

interface EmploymentPanelProps
  extends WithStyles<typeof styles>,
    WithRouterStore,
    WithModalStore,
    WithToastStore,
    WithUserStore {
  userId: number;
  account: Account;
  accountUser: AccountUser | null;
  editable?: boolean;
  missingAuthInfo?: TalentIntegrationApp;
}

enum EmployeeIdValueState {
  LOADING = 'loading',
  MISSING_AUTH = 'missing_auth',
  NO_INTEGRATION = 'no_integration',
  HAS_INTEGRATION = 'has_integration',
  NO_EMPLOYEE_INFO = 'no_employee_info',
}

/**
 * Displays the employment panel, which displays a users employment on an account and their owner status.
 */
@inject('modalStore', 'toastStore', 'userStore', 'routerStore')
@observer
class EmploymentPanel extends React.Component<EmploymentPanelProps> {
  constructor(props: EmploymentPanelProps) {
    super(props);
    makeObservable(this);
  }

  static defaultProps = {
    editable: true,
  };

  /** Generate list of user status values from UserStatus enum */
  private employeeStatusList = Object.values(EmployeeStatus).filter(
    (value) => typeof value === 'string',
  ) as string[];

  @observable public accountUserStatus: string =
    this.props.accountUser && this.props.accountUser!.isActive ? 'active' : 'inactive';

  /** Whether the component is currently updating */
  @observable public updating = false;

  /**
   * Employee info for users is not set by default - it has no corresponding row in EmployeeInfo table.
   * It gets created when user is assigned employeeId for the first time.
   */
  @observable public employeeInfo: undefined | EmployeeInfo;

  @observable public loadingEmployeeInfo = true;

  @observable public editingEmployeeId = false;

  @observable private employeeIdEditable = false;

  /**
   * Employee id and status values derive from API response, but we need to have
   * initial values ready (this.employeeInfo), if users decides to cancel update process.
   */
  @observable public employeeIdField: undefined | EmployeeInfo['employeeId'];
  @observable public employeeStatusField: undefined | EmployeeStatus;

  /* Is scope of user that is viewing this component (me) owner? */
  @observable private amIOwner: boolean = this.props.userStore!.scope.kind === 'owner';

  /* Is scope of user that is viewing this component (me) admin? */
  @observable public isAdmin?: boolean = this.props.userStore!.authUser.isAdmin;

  @observable public isTalent?: boolean = this.props.userStore!.spLocations.length ? true : false;

  /* Is scope of user that is viewing this component owner or global owner? */
  @observable public isOwnerScope =
    this.props.userStore!.scope.kind === 'owner' ||
    this.props.userStore!.scope.kind === 'global_owner';

  /** The value of the owner toggle for user that is being viewed */
  @observable public isOwner = Boolean(this.props.accountUser && this.props.accountUser.isActive);

  @observable employeeIdValueState: EmployeeIdValueState = EmployeeIdValueState.LOADING;

  /** Is current user looking at his own workspace */
  @computed get ownWorkspace() {
    return this.props.userId === this.props.userStore!.user!.id;
  }

  @computed get employeeIdValue() {
    const state = this.employeeIdValueState;
    // Show loading progress while waiting for fetching of needed data
    if (state === EmployeeIdValueState.LOADING) {
      return (
        <DP.Value>
          <Skeleton variant="rectangular" width={80} />
        </DP.Value>
      );
    }

    // Show log in with POS button
    if (state === EmployeeIdValueState.MISSING_AUTH) {
      return this.renderLogInWithPos();
    }
    // Show edit option for employee id
    if (state === EmployeeIdValueState.NO_INTEGRATION) {
      return (
        <DP.Value>
          {this.renderEmployeeIdValue()}
          {this.renderEmployeeIdToolbar()}
        </DP.Value>
      );
    }
    // Show synced checkmark without employee id edit option
    if (state === EmployeeIdValueState.HAS_INTEGRATION) {
      return (
        <DP.Value>
          {this.renderEmployeeIdValue()}
          <Tooltip title="Logged-in with POS">
            <Icon color="primary">check</Icon>
          </Tooltip>
        </DP.Value>
      );
    }
    // Show N/A for employee id and edit id option
    if (state === EmployeeIdValueState.NO_EMPLOYEE_INFO) {
      return (
        <DP.Value>
          {this.renderEmployeeIdValue()}
          {this.renderEmployeeIdToolbar()}
        </DP.Value>
      );
    }
  }

  /** On employee id change */
  @action.bound private handleEmployeeIdChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.employeeIdField = event.target.value;
  }

  /**
   * On employee info update (for employeeId). Because there is a possibility this
   * user does not have any employee info at all, we have to check if we're creating
   * or updating employee info and call the corresponding API hook (post vs patch).
   */
  @action.bound private handleEmployeeIdUpdate() {
    if (this.employeeInfo) {
      this.updateEmployeeInfo();
    } else {
      this.createEmployeeInfo();
    }
  }

  /**
   * On employee info update (for status). Status field is shown only if user has
   * employee info already set (by crating employee id). So at this point we're sure
   * we're only updating the employee info and not creating it.
   */
  @action.bound private handleStatusChange(
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) {
    this.employeeStatusField = event.target.value as EmployeeStatus;
    this.updateEmployeeInfo();
  }

  @action.bound private resetEmployeeIdField() {
    this.editingEmployeeId = false;
    this.employeeIdField = this.employeeInfo ? this.employeeInfo.employeeId : '';
  }

  /** Triggers owner change confirmation dialog */
  @action.bound public changeIsOwnerConfirm = flow(function* (this: EmploymentPanel) {
    const dialogTitle = 'Change owner status?';
    const dialogBody = this.isOwner
      ? 'Are you sure you want to remove this user as owner?'
      : 'Are you sure you want to make this user an owner?';
    return yield this.props.modalStore!.confirm(dialogTitle, dialogBody);
  });

  /** Call POST API hook for employee info that creates a new row. */
  @action.bound public createEmployeeInfo = flow(function* (this: EmploymentPanel) {
    const toastStore = this.props.toastStore!;
    try {
      const accountId = this.props.account.id;
      const userId = this.props.userId;
      const employeeId = this.employeeIdField;
      if (employeeId) {
        const resp = yield Api.core.createEmployeeInfo(accountId, userId, employeeId);
        // Set inital employee info state:
        this.employeeInfo = resp.data.data;
        // Set form controlled input value variables:
        this.employeeIdField = resp.data.data.employeeId;
        this.employeeStatusField = resp.data.data.status;
        toastStore.push({ type: 'success', message: `Employee ID successfully updated` });
      }
    } catch (e: any) {
      toastStore.push({ type: 'error', message: e.message });
      this.employeeIdField = this.employeeInfo ? this.employeeInfo.employeeId : '';
    } finally {
      this.editingEmployeeId = false;
    }
  });

  /** Call PATCH API hook for employee info that updates an existing row. */
  @action.bound public updateEmployeeInfo = flow(function* (this: EmploymentPanel) {
    const toastStore = this.props.toastStore!;
    try {
      const accountId = this.props.account.id;
      const userId = this.props.userId;
      const employeeInfoObject = {
        status: this.employeeStatusField,
        employeeId: this.employeeIdField,
      };
      const resp = yield Api.core.updateEmployeeInfo(accountId, userId, employeeInfoObject);
      // Set inital employee info state:
      this.employeeInfo = resp.data.data;
      // Set form controlled input value variables:
      this.employeeIdField = resp.data.data.employeeId;
      this.employeeStatusField = resp.data.data.status;
      toastStore.push({ type: 'success', message: `Employee info successfully updated` });
    } catch (e: any) {
      toastStore.push({ type: 'error', message: e.message });
      this.employeeIdField = this.employeeInfo ? this.employeeInfo.employeeId : '';
    } finally {
      this.editingEmployeeId = false;
    }
  });

  /** Calls the API to toggle the owner status and handles the API call flow logic */
  @action.bound public setIsAccountOwner = flow(function* (
    this: EmploymentPanel,
    setToOwner: boolean,
  ) {
    const toastStore = this.props.toastStore!;
    const accountId = this.props.account.id;
    const userId = this.props.userId;
    // Do nothing if the component is in the middle of sending some data,
    // as this avoids possible inconsistent states.
    if (this.updating) {
      return;
    }
    const oldIsOwner = this.isOwner;
    // Trigger the dialog confirmation
    const confirmed = yield this.changeIsOwnerConfirm();
    if (!confirmed) {
      return;
    }
    this.isOwner = setToOwner;
    try {
      this.updating = true;
      if (setToOwner) {
        yield Api.core.addAccountOwner(accountId, userId);
        toastStore.push({ type: 'success', message: `User successfully set as owner` });
      } else {
        yield Api.core.removeAccountOwner(accountId, userId);
        toastStore.push({ type: 'success', message: `User successfully unset as owner` });
      }
    } catch (e: any) {
      this.props.toastStore!.push({
        type: 'error',
        message: e.response && e.response.data && e.response.data.error.message,
      });
      this.isOwner = oldIsOwner;
    } finally {
      this.updating = false;
    }
  });

  /** Handles the owner switch being toggled */
  @action.bound public handleOwnerSwitchToggled(
    this: EmploymentPanel,
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) {
    this.setIsAccountOwner(checked);
  }

  @action.bound async fetchEmployeeInfo() {
    let resp = undefined;
    try {
      const accountId = this.props.account.id;
      const userId = this.props.userId;
      resp = await Api.core.getEmployeeInfo(userId, accountId);
      if (resp && resp.data) {
        resp = resp.data;
      }
    } catch (e: any) {
      // Do something with the error
    } finally {
      this.loadingEmployeeInfo = false;
    }
    return resp;
  }

  @action.bound initializeEmployeeState(employeeInfo: any) {
    this.employeeInfo = employeeInfo;
    this.employeeIdField = employeeInfo.employeeId;
    this.employeeStatusField = employeeInfo.status;
  }

  @action.bound setEmployeeIdValueState(state: EmployeeIdValueState) {
    this.employeeIdValueState = state;
  }

  redirectExternalAppLogin(missingAuthInfo: TalentIntegrationApp) {
    this.props.userStore!.integrationApps = [missingAuthInfo];
    this.props.routerStore!.history.push(`${paths.externalAppLogin()}?workspace=true`);
  }

  renderEmployeeIdValue() {
    if (this.editingEmployeeId) {
      return (
        <Input
          value={this.employeeIdField}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            this.handleEmployeeIdChange(e)
          }></Input>
      );
    }
    return this.loadingEmployeeInfo ? '' : this.employeeIdField || 'N/A';
  }

  renderEmployeeIdToolbar() {
    if (!(this.isAdmin || this.isOwnerScope)) return;
    if (this.editingEmployeeId) {
      return (
        <>
          <DP.IconButton primary icon={Check} onClick={() => this.handleEmployeeIdUpdate()} />
          <DP.IconButton icon={Close} onClick={() => this.resetEmployeeIdField()} />
        </>
      );
    } else {
      return (
        <DP.IconButton primary icon={Pencil} onClick={() => (this.editingEmployeeId = true)} />
      );
    }
  }

  renderLogInWithPos() {
    const missingAuthInfo = this.props.missingAuthInfo;
    if (!missingAuthInfo) return;
    return (
      <Button
        onClick={() => this.redirectExternalAppLogin(missingAuthInfo)}
        color="primary"
        variant="contained">
        {`Log in with ${missingAuthInfo.name}`}{' '}
      </Button>
    );
  }

  componentDidMount() {
    this.initializeEmployeeValues();
  }

  async fetchAppsForAccount() {
    let apps = undefined;
    try {
      const { data } = await Api.developer.getAppsForAccount(this.props.account.id);
      if (data && data.data && data.data.length) {
        apps = data.data;
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
    return apps;
  }

  async initializeEmployeeValues() {
    if (this.props.missingAuthInfo && this.isTalent) {
      this.setEmployeeIdValueState(EmployeeIdValueState.MISSING_AUTH);
      return;
    }

    const employeeInfo = await this.fetchEmployeeInfo();
    if (employeeInfo) {
      const apps = await this.fetchAppsForAccount();
      this.initializeEmployeeState(employeeInfo);
      if (apps) {
        this.setEmployeeIdValueState(EmployeeIdValueState.HAS_INTEGRATION);
      } else {
        this.setEmployeeIdValueState(EmployeeIdValueState.NO_INTEGRATION);
      }
    } else {
      this.setEmployeeIdValueState(EmployeeIdValueState.NO_EMPLOYEE_INFO);
    }
  }

  render() {
    const classes = this.props.classes;
    const isAdmin = this.props.userStore!.authUser.isAdmin;
    return (
      <DP>
        <DP.Header>
          <DP.Title panel>{this.props.account.name}</DP.Title>
          <DP.Actions>
            {this.updating && <DP.LoadSpinner />}
            {(this.amIOwner || isAdmin) && (
              <Link
                className={classes.linkIcon}
                component={RouterLink}
                to={paths.accountDetails(this.props.account.id.toString()).info()}>
                <Tooltip title="ACCOUNT">
                  <OpenInNew color="primary" fontSize="small" />
                </Tooltip>
              </Link>
            )}
          </DP.Actions>
        </DP.Header>
        <DP.Body>
          <Box display="flex">
            <Box width={'60%'}>
              <DP.Row>
                <DP.Row>
                  {this.employeeIdValue}
                  <DP.Label>Employee ID</DP.Label>
                </DP.Row>
                {(this.amIOwner || this.isAdmin) && (
                  <DP.Row>
                    <DP.Switch
                      disabled={this.ownWorkspace}
                      checked={this.isOwner}
                      onChange={this.handleOwnerSwitchToggled}
                    />
                    <DP.Label>Owner</DP.Label>
                  </DP.Row>
                )}
              </DP.Row>
            </Box>
          </Box>
        </DP.Body>
      </DP>
    );
  }
}

export default withStyles(styles)(EmploymentPanel);
