import Title, { ETitleVariant } from 'components/Title/Title/Title';
import DashboardLayout from 'containers/DashboardLayout';
import useTransfers from './useTransfers';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import FilterBar from 'components/FilterBar';
import Dialog from 'components/Dialog/Dialog';
import ActionDialogContent from './ActionDialogContent/ActionDialogContent';
import TipDetailsDrawer from './TipDetailsDrawer/TipDetailsDrawer';
import { GridColDef } from '@mui/x-data-grid-pro';
import DataGridCell from 'components/DataGridCell/DataGridCell';
import { paths } from 'routes';
import { ChipStatusColors, IBadgeConfig } from 'components/ChipStatusTag';
import { ETransferRequestStatus } from 'models';

const PAGE_TITLE = 'Transfer Requests ';

const badgeConfig: IBadgeConfig = {
  CREATED: ChipStatusColors.PURPLE,
  APPROVED: ChipStatusColors.GREEN,
  REJECTED: ChipStatusColors.RED,
};

const Transfers = () => {
  const {
    action,
    actionDialogProps,
    rejectionReasonProps,
    fetch,
    refetchKey,
    filterBarProps,
    drawerProps,
    setTip,
    actionMenuItems,
  } = useTransfers();

  const columns: GridColDef[] = [
    {
      field: 'from',
      headerName: 'From',
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: ({ value, row }) => (
        <DataGridCell.Route value={value} path={paths.userDetails(row.fromUserId).root()} />
      ),
    },
    {
      field: 'to',
      headerName: 'To',
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: ({ value, row }) => (
        <DataGridCell.Route value={value} path={paths.userDetails(row.toUserId).root()} />
      ),
    },

    {
      field: 'amount',
      headerName: 'Amount',
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: ({ value, row }) => (
        <DataGridCell.Amount amount={value} onClick={() => setTip(row?.tip)} />
      ),
    },
    {
      field: 'requestedByUser',
      headerName: 'Requested By',
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: ({ value, row }) => (
        <DataGridCell.Route value={value} path={paths.userDetails(row?.requestedBy.id).root()} />
      ),
    },
    {
      field: 'resolvedByUser',
      headerName: 'Resolved By',
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: ({ value, row }) =>
        row?.resolvedBy ? (
          <DataGridCell.Route value={value} path={paths.userDetails(row?.resolvedBy.id).root()} />
        ) : (
          'N/A'
        ),
    },
    {
      field: 'accountName',
      headerName: 'Account',
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: ({ value, row }) => (
        <DataGridCell.Route value={value} path={paths.accountDetails(row.accountId).root()} />
      ),
    },
    {
      field: 'locationName',
      headerName: 'Location',
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: ({ value, row }) => (
        <DataGridCell.Route value={value} path={paths.locationDetails(row.locationId)} />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Requested At',
      minWidth: 200,
      flex: 1,
      renderCell: ({ value, row }) => <DataGridCell.Date value={value} />,
    },
    {
      field: 'reason',
      headerName: 'Reason',
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <DataGridCell.TextTooltip value={value} />,
    },
    {
      field: 'rejectionReason',
      headerName: 'Rejection Reason',
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <DataGridCell.TextTooltip value={value} />,
    },
    {
      field: 'reference',
      headerName: 'Reference',
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => (
        <DataGridCell.Route value={value} path={paths.paymentTips(value)} />
      ),
    },
    {
      field: 'resolvedAt',
      headerName: 'Resolved At',
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: ({ value, row }) => <DataGridCell.Date value={value} />,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => (
        <DataGridCell.Status
          label={value}
          background={badgeConfig[value?.toUpperCase() as keyof IBadgeConfig] as ChipStatusColors}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      minWidth: 120,
      align: 'center',
      flex: 1,
      renderCell: ({ row }) => (
        <DataGridCell.ActionMenu
          disabled={row?.status !== ETransferRequestStatus.CREATED}
          tooltipTitle={`Actions can be performed on requests in 'CREATED' status only`}
          menuItems={actionMenuItems(row)}
        />
      ),
    },
  ];

  return (
    <DashboardLayout>
      <Title variant={ETitleVariant.PAGE} mb={3}>
        {PAGE_TITLE}
      </Title>
      <FilterBar {...filterBarProps} />
      <DataGridInfiniteScroll columns={columns} fetch={fetch} refetchKey={refetchKey} />
      <Dialog
        {...actionDialogProps}
        content={
          <ActionDialogContent action={action} rejectionReasonProps={rejectionReasonProps} />
        }
      />
      <TipDetailsDrawer {...drawerProps} />
    </DashboardLayout>
  );
};

export default Transfers;
