import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from '../EditableTitle/styles';
import { inject, WithToastStore, WithUserStore } from 'stores';
import { observer } from 'mobx-react';
import PictureUpload, { PictureUploadLayoutType } from './PictureUpload';
import { action, flow, observable, makeObservable } from 'mobx';
import Api from '../../api';
import { Account } from '../../models';

export interface LogoUploadDialogProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithUserStore {
  account?: Account;
  refreshAccount?: () => void;
  open: boolean;
  onClose: any;
}
@inject('toastStore', 'userStore')
@observer
class LogoUploadDialog extends React.Component<LogoUploadDialogProps> {
  constructor(props: LogoUploadDialogProps) {
    super(props);
    makeObservable(this);
  }

  @observable private uploading = false;
  @observable private uploadProgress = 0;

  @action.bound public onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    this.uploadProgress = percentCompleted;
  };

  @action.bound public handleAccountLogoUpload = flow(function* (
    this: LogoUploadDialog,
    logo: File,
  ) {
    this.uploading = true;
    yield Api.core.uploadAccountLogo(this.props.account!.id, logo, this.onUploadProgress);
    this.props.userStore!.refreshTokenAndUpdateUserData();
    this.props.userStore!.getAccounts();
    if (this.props.refreshAccount) {
      this.props.refreshAccount();
    }

    this.props.toastStore!.push({
      type: 'success',
      message: `Account logo was successfully changed`,
    });
    this.uploading = false;
    this.uploadProgress = 0;
  });

  render() {
    const { open, onClose, classes } = this.props;
    const uploadComponentLayout: PictureUploadLayoutType = 'horizontal';

    return (
      <div>
        {this.props.account && (
          <PictureUpload
            layout={uploadComponentLayout}
            profilePictureUrl={this.props.account!.logo}
            onUpload={this.handleAccountLogoUpload}
            loading={this.uploading}
            progress={this.uploadProgress}
            onClose={onClose}
            open={open}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(LogoUploadDialog);
