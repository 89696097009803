import React from 'react';
import { observer } from 'mobx-react';
import { Box, IconButton, FormLabel, Typography } from '@material-ui/core';
import theme from 'containers/App/theme';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';

interface UpDownCounterProps {
  label: string;
  value: number;
  inc: () => void;
  dec: () => void;
  vertical?: boolean;
}

/** A simple component for selecting a small number */
const UpDownCounter: React.FC<UpDownCounterProps> = observer(
  ({ label, value, inc, dec, vertical }) => {
    const classes = useStyles();
    const className = vertical ? classes.upDownCounterVertical : classes.upDownCounter;
    const paddingBottom = vertical ? 2.8 : 0;
    const color = theme.palette.text.primary;

    return (
      <Box display="flex" className={className}>
        <Box pb={paddingBottom}>
          <FormLabel style={{ color }}>{label}</FormLabel>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box pr={2}>
            <IconButton className={classes.icon} onClick={dec} size="small">
              <FontAwesomeIcon icon={faMinus} />
            </IconButton>
          </Box>
          <Typography variant="h4" component="div">
            {value}
          </Typography>
          <Box pl={2}>
            <IconButton className={classes.icon} onClick={inc} size="small">
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  },
);

export default UpDownCounter;
