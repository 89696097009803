import { Box, CircularProgress, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Api, { getErrorMsg } from 'api';
import Overlay from 'components/Overlay';
import { action, observable, makeObservable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { Hardware, Inventory } from 'models';
import React from 'react';
import { inject, WithToastStore } from 'stores';
import styles from './styles';
import AddItem from './AddItem/AddItem';
import AddItems from './AddItems/AddItems';
import { TabVariant } from 'components/TabBar';
import theme from 'containers/App/theme';
import TabBar, { TTabs } from 'components/TabBar/TabBar';

interface InventoryEntryProps extends WithStyles<typeof styles>, WithToastStore {
  onComplete?: () => void;
  data?: Hardware;
}

const fields = [
  {
    name: 'serial',
    label: 'Serial Number',
    rules: 'required',
    required: true,
  },
  {
    name: 'purchasedOn',
    label: 'Purchase Date',
    placeholder: 'YYYY-MM-DD',
    rules: 'date',
  },
  {
    name: 'vendor',
    label: 'Vendor',
  },
  {
    name: 'owner',
    label: 'Owner',
  },
  {
    name: 'color',
    label: 'Color',
  },
  {
    name: 'cost',
    label: 'Cost',
    rules: 'decimal',
  },
];

/**
 * The component for the inventory items entry in the devices section.
 */
@inject('userStore', 'toastStore')
@observer
class InventoryEntry extends React.Component<InventoryEntryProps> {
  constructor(props: InventoryEntryProps) {
    super(props);
    makeObservable(this);
  }

  /** The hardware currently chosen */
  @observable public hardware: Hardware | null = this.props.data ? this.props.data : null;

  /** The error text for the hardware field */
  @observable public hardwareError: string | null = null;

  @observable private selectedTab: IToggleAddItem['selectedTab'] = 'manual';

  /** Sets the currently chosen hardware */
  @action.bound public setHardware(h: Hardware | null) {
    this.hardware = h;
    this.hardwareError = null;
  }

  /** The inventory items that are currently entered */
  @observable public inventoryItems: Map<string, Partial<Inventory> & Pick<Inventory, 'serial'>> =
    new Map();

  /**
   * Sets the hardwareError field to the `Please choose hardware`
   * string if the hardware is missing.
   */
  @action.bound public setHardwareError(): boolean {
    this.hardwareError = this.hardware ? null : 'Please choose hardware';
    return Boolean(this.hardwareError);
  }

  /** Whether the submission is currenlty in progress */
  @observable public submitting = false;

  /** Sends the whole set of inventory items to the API */
  @action.bound submit = async (items: Inventory[]) => {
    try {
      this.submitting = true;
      await Api.fulfillment.addInventoryItems(items);
      this.props.toastStore!.success(
        `${items.length} inventory item${items.length > 1 ? 's' : ''} added!`,
      );
      this.props.onComplete && this.props.onComplete();
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.submitting = false;
    }
  };

  @action.bound selectTab = (tab: IToggleAddItem['selectedTab']) => {
    this.selectedTab = tab;
  };

  @computed get tabs() {
    return [
      {
        label: 'Manual',
        selected: this.selectedTab === 'manual',
        onClick: () => this.selectTab('manual'),
      },
      {
        label: 'Upload',
        selected: this.selectedTab === 'upload',
        onClick: () => this.selectTab('upload'),
      },
    ];
  }

  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.root}>
        <Overlay display={this.submitting}>
          <CircularProgress />
        </Overlay>{' '}
        <Typography variant="h4" component="h1" style={{ marginBottom: '20px', fontSize: 28 }}>
          {`Add Items`}
        </Typography>
        <ToggleAddItem
          tabs={this.tabs}
          center={true}
          selectedTab={this.selectedTab}
          selectTab={this.selectTab}
        />
        {this.selectedTab === 'manual' ? (
          <AddItem hardware={this.hardware} fields={fields} submit={this.submit} />
        ) : (
          <AddItems
            hardware={this.hardware}
            fields={fields}
            toastStore={this.props.toastStore!}
            setHardwareError={this.setHardwareError}
            submit={this.submit}
          />
        )}
      </Box>
    );
  }
}

interface IToggleAddItem {
  selectedTab: 'manual' | 'upload';
  selectTab: (tab: 'manual' | 'upload') => void;
  center: boolean;
  tabs: TTabs;
}
const ToggleAddItem = ({ selectedTab, selectTab, center, tabs }: IToggleAddItem) => {
  return (
    <TabBar
      center
      gap={5}
      style={{ marginBottom: theme.spacing(4) }}
      variant={TabVariant.UNDERLINE}
      tabs={tabs}
    />
  );
};

export default withStyles(styles)(InventoryEntry);
