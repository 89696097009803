import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { OutlinedInputVariant } from './OutlinedInput';

interface IStyledProps {
  variant: OutlinedInputVariant;
  startAdornment?: boolean;
  startAdornmentObj?: boolean;
  error?: boolean;
  disabled?: boolean;
  multiline?: boolean;
}

const useStyles = makeStyles<Theme, IStyledProps>((theme: Theme) =>
  createStyles({
    textField: {
      height: '100%',
      paddingTop: ({ variant }: IStyledProps) =>
        variant !== 'small' ? theme.spacing(3.25) : theme.spacing(1.5),
      paddingRight: theme.spacing(2),
      paddingBottom: ({ variant }) =>
        variant !== 'small' ? theme.spacing(1.5625) : theme.spacing(1.375),
      '& .MuiInputBase-root': {
        height: ({ multiline }: IStyledProps) => (multiline ? 'auto' : 21),
      },
      '& .MuiInputBase-root .MuiSelect-icon': {
        top: 'auto',
      },
      '& .MuiFormLabel-root': {
        color: ({ disabled }: IStyledProps) =>
          disabled ? theme.palette.button.disabled : theme.palette.common.black,
        height: 21,
        lineHeight: '20px',
      },
      '& .MuiInputLabel-formControl': {
        left: 0,
        transform: 'translate(0, 26px) scale(1)',
      },
      '& .MuiInputBase-input': {
        padding: 0,
        height: 21,
      },
      '& label + .MuiInput-formControl': {
        margin: 0,
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(0, 10px) scale(0.75)',
      },
      '& .MuiInputAdornment-root': {
        color: ({ disabled }: IStyledProps) =>
          disabled ? theme.palette.button.disabled : theme.palette.common.black,
        '& .MuiSvgIcon-colorAction': {
          color: ({ disabled }: IStyledProps) =>
            disabled ? theme.palette.button.disabled : theme.palette.common.black,
        },
      },
    },
    textFieldWrapper: {
      position: 'relative',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: ({ disabled }: IStyledProps) =>
        disabled ? theme.palette.button.disabled : theme.palette.common.black,
      borderRadius: theme.shape.borderRadius,
      height: ({ variant, multiline }) => (multiline ? 'auto' : variant !== 'small' ? 60 : 44),
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
        width: '0 !important',
      },
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
      display: 'flex',
      paddingLeft: theme.spacing(2),
      '& .MuiInputAdornment-positionStart': {
        color: ({ disabled }: IStyledProps) =>
          disabled ? theme.palette.button.disabled : theme.palette.common.black,
        marginTop: 'auto',
        marginBottom: theme.spacing(1.375), // Remove?
        marginRight: 0,
      },
      '& .MuiInputAdornment-positionEnd': {
        color: ({ disabled }: IStyledProps) =>
          disabled ? theme.palette.button.disabled : theme.palette.common.black,
        marginTop: 'auto',
        marginBottom: theme.spacing(1.375),
      },
    },
    muiHelperTextWrapper: {
      margin: 0,
      marginTop: 2,
      fontSize: theme.typography.subtitle1.fontSize,
      color: theme.typography.subtitle1.color,
      display: 'flex',
      justifyContent: 'space-between',
    },
    muiHelperText: {
      color: ({ error }) => (error ? theme.palette.error.main : theme.typography.subtitle1.color),
    },
    startAdornmentWrapper: {
      width: ({ startAdornment, startAdornmentObj }) =>
        startAdornment && startAdornmentObj ? 32 : 16,
      height: '100%',
      display: ({ startAdornment }) => (startAdornment ? 'flex' : 'none'),
      marginRight: ({ startAdornment, startAdornmentObj }) =>
        startAdornment && startAdornmentObj ? theme.spacing(1) : 0,
      paddingBottom: ({ variant }) =>
        variant !== 'small' ? theme.spacing(1.625) : theme.spacing(1.125),
    },
    adornment: {
      '& .MuiSvgIcon-colorAction': {
        color: ({ disabled }: IStyledProps) =>
          disabled ? theme.palette.button.disabled : theme.palette.common.black,
      },
    },
  }),
);

export default useStyles;
