import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import moment from 'moment';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  MenuItem,
} from '@material-ui/core';

import Api, { ApiResponse, RequestMetaData, getErrorMsg } from 'api';
import { inject, WithUserStore, WithToastStore } from 'stores';
import * as models from 'models';
import { EllipsizedValue } from 'services/datagrid';
import { usdToNumericString, adaptForDataGridPro } from 'services';

import LoadingSpinner from 'components/LoadingSpinner';

import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';

import { VerticalStatCard } from 'containers/UserDetails/Stats';
import styles from './styles';

import validatorjs from 'validatorjs';
import { isCurrency } from 'services/validators';
import OutlinedInput from 'components/Input/OutlinedInput';
import Button from 'components/Button/Dialog/Button';
import { RouteComponentProps } from 'react-router-dom';
import { ACL } from 'types';
import {
  faBadgePercent,
  faCalendarStar,
  faMoneyBillTransfer,
  faReceipt,
  faWallet,
} from '@fortawesome/pro-regular-svg-icons';
import { AxiosResponse } from 'axios';
import { EDateFormat } from 'utils/helper';
import theme from 'containers/App/theme';

type MobxForm = any;

/** Definition of form fields for create correction modal form */
const fields = [
  {
    name: 'amount',
    label: 'Correction Amount',
    rules: ['required', 'currency'],
    hooks: {
      onChange: (field: any) => {
        const prefix = '-';
        const delimiter = '.';
        const inputString = field.value;

        // Allowed input characters are numbers, minus sign, and the dot
        const lastChar = inputString[inputString.length - 1];
        const isValidChar = new RegExp(/^[0-9.-]$/).test(lastChar);
        if (!isValidChar) {
          field.set(inputString.slice(0, -1));
        }
        // Negative prefix can only appear as the first character and only once
        const indexOfPrefix = inputString.indexOf(prefix);
        const prefixCount = inputString.split(prefix).length - 1;
        if ((inputString.includes(prefix) && indexOfPrefix !== 0) || prefixCount > 1) {
          field.set(inputString.slice(0, -1));
        }
        // Dot decimal delimiter cannot appear twice
        const delimiterCount = inputString.split(delimiter).length - 1;
        if (delimiterCount > 1) {
          field.set(inputString.slice(0, -1));
        }
        // There can only be two digits behind a dot decimal delimiter
        const indexOfDelimiter = inputString.indexOf(delimiter);
        if (inputString.includes(delimiter) && indexOfDelimiter < inputString.length - 3) {
          field.set(inputString.slice(0, -1));
        }
      },
    },
  },
  {
    name: 'customReason',
    label: 'Your Note for Correction',
    rules: ['required'],
    hooks: {
      onChange: (field: any) => field.validate(),
    },
  },
  {
    name: 'paymentMethod',
    type: 'select',
    rules: ['required'],
    hooks: {
      onChange: (field: any) => field.set(field.value),
    },
  },
];
// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;
const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any }) => {
      validator.register('currency', isCurrency, 'Please enter a valid dollar amount (e.g. -9.32)');
    },
  }),
};
interface FormHooks {
  onSuccess: (form: MobxForm) => void;
  onClear: (form: MobxForm) => void;
}
/** Here we define what kind of props this component takes */
interface BillingProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    WithToastStore,
    RouteComponentProps {
  accountId: number;
  account?: models.Account;
}

/** Annotates transactions with additional data */
function annotateTransactions(transaction: models.Transaction) {
  return {
    ...transaction,
    invoiceNumber: transaction.invoice && transaction.invoice.number,
    processorPaymentId: transaction.charge && transaction.charge.processorPaymentId,
    invoiceRefund: transaction.invoiceRefund && transaction.invoiceRefund.processorRefundId,
    reason: transaction.note,
    createdAt: transaction.createdAt,
  };
}

@inject('userStore', 'toastStore')
@observer
class Billing extends React.Component<BillingProps> {
  constructor(props: BillingProps) {
    super(props);
    makeObservable(this);
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  /** The form object for payment refund */
  @observable private form: MobxForm;

  private hooks: FormHooks = {
    onSuccess: () => this.submitCorrection(),
    onClear: () => {
      this.closeCorrectionModal();
    },
  };

  /** Billing history stats */
  @observable public billingHistoryStats?: models.BillingHistoryStats;

  /** Payment methods received from /payments api */
  @observable public paymentMethods: models.BillingEntity[] = [];

  /** Correction modal state */
  @observable public correctionModalOpen = false;

  /** Whether the form is currently being submitted */
  @observable public submitting = false;

  public fetchBillingHistory = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    const { billingEntityId, ...restActiveFilters } = this.activeFilters;

    let extraData;
    if (billingEntityId) {
      extraData = { billingEntityId };
    }
    return await Api.billing.getBillingHistory(
      this.props.accountId,
      {
        ...rmd,
        filters: {
          ...restActiveFilters,
        },
      },
      extraData,
    );
  }, annotateTransactions);

  /** Billing history stats*/
  @action.bound private fetchBillingHistoryStats = flow(function* (
    this: Billing,
    extraData?: Record<string, number>,
  ) {
    try {
      const { billingEntityId }: Record<string, any> = this.activeFilters;

      if (billingEntityId) {
        extraData = { billingEntityId: parseInt(billingEntityId) };
      }

      const res = yield Api.billing.getBillingHistoryStats(this.props.accountId, extraData);
      if (res !== undefined) {
        this.billingHistoryStats = res.data && res.data.data;
      }
    } catch (error: any) {
      this.props.toastStore!.error(getErrorMsg(error));
    }
  });

  @action.bound private fetchPaymentMethods = flow(function* (this: Billing) {
    try {
      const { data }: AxiosResponse<ApiResponse<models.BillingEntity[]>> =
        yield Api.billing.getPaymentMethods(this.props.accountId);

      this.paymentMethods = data.data || [];

      const newFilters: Filter[] = this.filters.map((filter: Filter) => {
        if (filter.id === 'billingEntityId') {
          const items = this.paymentMethods.map(({ id, code, paymentMethod }) => ({
            label: `${paymentMethod.brand} ${paymentMethod.lastFour} | ${
              paymentMethod.createdAt &&
              moment(new Date(paymentMethod.createdAt)).format(EDateFormat.DEFAULT)
            } | ${code}`,
            value: id.toString(),
          }));
          return Object.assign(filter, { items });
        }
        return filter;
      });

      this.filters = newFilters;
    } catch (error: any) {
      this.props.toastStore!.error(getErrorMsg(error));
    }
  });

  /** Submits the correction form */
  @action.bound public submitCorrection = flow(function* (this: Billing) {
    try {
      this.submitting = true;
      const amount = this.form.$('amount').value;
      const reason = this.form.$('customReason').value;
      const billingEntityId = this.form.$('paymentMethod').value;

      yield Api.billing.createLedgerCorrection(
        this.props.accountId,
        billingEntityId,
        amount,
        reason,
      );

      this.props.toastStore!.push({
        type: 'success',
        message: `Correction for ${amount} dollars was successfully applied`,
      });
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.closeCorrectionModal();
      this.submitting = false;
      // refresh data
      this.fetchBillingHistoryStats();
      this.datagridRefetchKey = Date.now();
    }
  });

  /**
   * To make a correction, an authenticated user needs to have correct permission
   */
  @computed private get canMakeCorrection() {
    return (
      this.props.userStore &&
      this.props.userStore!.hasPermission(ACL.POST_ACCOUNT_LEDGER_CORRECTION)
    );
  }

  /**
   * On modal open, inside payment method select box,
   * Set currently selected payment method from filters, or default if none is selected
   */
  @action.bound private openCorrectionModal() {
    const paymentMethod = this.form.$('paymentMethod');

    if (this.activeFilters.billingEntityId) {
      paymentMethod.set(this.activeFilters.billingEntityId);
    } else {
      const primary = this.paymentMethods.find((it) => it.isPrimary);
      if (primary) {
        paymentMethod.set(primary.id);
      }
    }

    this.correctionModalOpen = true;
  }

  @action.bound private closeCorrectionModal(updateFilters = true) {
    this.correctionModalOpen = false;

    this.form.clear();

    if (updateFilters) {
      this.activeFilters = { ...this.activeFilters };
    }
  }

  /** On datagridRefetchKey change datagrid will refetch the data */
  @observable private datagridRefetchKey = Date.now();

  /** Check user is admin to allow title editing */
  @observable public isAdmin?: boolean = this.props.userStore!.scope.kind === 'admin';

  componentDidMount() {
    this.fetchBillingHistoryStats();
    this.fetchPaymentMethods();
  }

  getLabelAndIconByKey(key: string) {
    // defualt icon is because StatItem expect it
    let item = {
      label: '',
      labelIcon: faWallet,
    };
    switch (key) {
      case 'balance':
        return (item = { label: 'Balance', labelIcon: faWallet });
      case 'charged':
        return (item = { label: 'Charged', labelIcon: faReceipt });
      case 'discounts':
        return (item = { label: 'Discounts', labelIcon: faBadgePercent });
      case 'freeMonths':
        return (item = { label: 'Free Months', labelIcon: faCalendarStar });
      case 'refunded':
        return (item = { label: 'Refunded', labelIcon: faMoneyBillTransfer });
      default:
        return item;
    }
  }

  renderCellColoredValue({ value }: any) {
    const numberValue = parseFloat(usdToNumericString(value));
    return <Typography color={numberValue >= 0 ? 'primary' : 'error'}>{value}</Typography>;
  }

  renderCellEllipsizedValue = ({ value }: any) => {
    const { classes } = this.props;
    return (
      <Box width="100%">
        {this.isAdmin ? (
          <Link
            href={`https://dashboard.stripe.com/payments/${value}`}
            target="_blank"
            rel="noopener noreferrer"
            className={classes && classes.chargeLink}>
            <EllipsizedValue value={value} max={4} />
          </Link>
        ) : (
          <EllipsizedValue value={value} max={4} />
        )}
      </Box>
    );
  };

  gridColumns = [
    {
      headerName: 'Created At',
      field: 'createdAt',
      minWidth: 200,
      flex: 1,
      type: 'createdAt',
      valueGetter: ({ value }: any) =>
        value && moment(new Date(value)).format(EDateFormat.DATE_TIME_FULL),
    },
    { headerName: 'Invoice', field: 'invoiceNumber', minWidth: 120, flex: 1, type: 'number' },
    {
      headerName: 'Charge',
      field: 'processorPaymentId',
      minWidth: 120,
      flex: 1,
      renderCell: this.renderCellEllipsizedValue,
    },
    {
      headerName: 'Refund',
      field: 'invoiceRefund',
      minWidth: 120,
      flex: 1,
      renderCell: this.renderCellEllipsizedValue,
    },
    { headerName: 'Reason', field: 'note', minWidth: 150, flex: 1 },
    {
      headerName: 'Amount',
      field: 'amount',
      minWidth: 120,
      flex: 1,
      renderCell: this.renderCellColoredValue,
    },
    { headerName: 'Entity Balance', field: 'billingEntityBalance', minWidth: 180, flex: 1 },
    {
      headerName: 'Account Balance',
      field: 'balance',
      minWidth: 180,
      flex: 1,
      renderCell: this.renderCellColoredValue,
    },
  ];

  @observable public filters: Filter[] = [
    { display: 'Invoice', id: 'invoiceNumber', label: 'Contains', type: 'text' },
    {
      display: 'Payment Method',
      id: 'billingEntityId',
      label: 'One of',
      type: 'select',
    },
  ];

  renderBillingHistoryStats(): ReactElement[] {
    const gridItems = [];

    if (this.billingHistoryStats) {
      for (const [key, value] of Object.entries(this.billingHistoryStats)) {
        // StatItem expects number in children amount property
        let data = { amount: 0 };
        let node;
        const { label, labelIcon } = this.getLabelAndIconByKey(key);
        // 'freeMonths' is without currency prefix and decimals
        if (key === 'freeMonths') {
          data = { amount: parseFloat(value) };
          node = (
            <Grid item xs={12} md={4} lg>
              <VerticalStatCard iconSize={24} fontAwesomeIcon={labelIcon} title={label}>
                {data.amount}
              </VerticalStatCard>
            </Grid>
          );
        } else {
          data = { amount: parseFloat(usdToNumericString(value)) };
          node = (
            <Grid item xs={12} md={4} lg>
              <VerticalStatCard prefix="$" iconSize={24} fontAwesomeIcon={labelIcon} title={label}>
                {data.amount}
              </VerticalStatCard>
            </Grid>
          );
        }
        gridItems.push(node);
      }
    }
    return gridItems;
  }

  renderForm() {
    const fields = {
      amount: this.form.$('amount'),
      customReason: this.form.$('customReason'),
      paymentMethod: this.form.$('paymentMethod'),
    };
    const { classes } = this.props;
    /**
     * Initial/default value for payment method select input should be set in fields array,
     * but it is getting re-set to 0 when modal opens.
     * Until we came up with proper solution, I will set the initial value here
     */

    return (
      <form onSubmit={this.form.onSubmit}>
        <Box minWidth={576}>
          <DialogTitle>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography style={{ fontSize: 28, fontWeight: 400 }}>Create Correction</Typography>
              {this.submitting && <LoadingSpinner size={24} />}
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box mt={2}>
              <OutlinedInput
                {...fields.amount.bind()}
                error={Boolean(fields.amount.error)}
                helperText={fields.amount.error}
                fullWidth
                InputProps={{
                  startAdornment: '$',
                }}
                required
              />
            </Box>
            <Box mt={2}>
              <OutlinedInput
                placeholder="Reason for refund"
                {...fields.customReason.bind()}
                error={Boolean(fields.customReason.error)}
                helperText={fields.customReason.error}
                multiline
                minRows={1}
                maxRows={1000}
                fullWidth
                required
              />
            </Box>
            <Box mt={2}>
              <OutlinedInput
                {...fields.paymentMethod.bind()}
                label="Payment Method"
                select
                error={Boolean(fields.paymentMethod.error)}
                helperText={fields.paymentMethod.error}
                required
                fullWidth
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      variant: 'elevation',
                      style: { boxShadow: `0 0 24px ${theme.shadows[2]}` },
                    },
                  },
                }}>
                {this.paymentMethods.map((payment: models.BillingEntity) => (
                  <MenuItem
                    key={`paymentMethods-${payment.id}`}
                    value={payment.id}
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}>
                    <Box>
                      {`${payment.paymentMethod.brand} ${payment.paymentMethod.lastFour} 
                    | ${
                      payment.paymentMethod.createdAt &&
                      moment(new Date(payment.paymentMethod.createdAt)).format(EDateFormat.DEFAULT)
                    }`}
                    </Box>
                    <Box sx={{ fontSize: 12, color: theme.palette.text.secondary }}>
                      {payment.code}
                    </Box>
                  </MenuItem>
                ))}
              </OutlinedInput>
            </Box>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button onClick={this.form.onClear} color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={!this.form.isValid}>
              Create
            </Button>
          </DialogActions>
        </Box>
      </form>
    );
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mt={0}>
            {' '}
            <FilterBar
              filters={this.filters}
              onChange={(filters: Record<string, unknown>) => {
                this.activeFilters = filters;
                this.fetchBillingHistoryStats();
              }}
            />
          </Box>
          <Box mt={3}>
            <Grid container direction={'row'} spacing={3}>
              {this.billingHistoryStats && this.renderBillingHistoryStats()}
            </Grid>
          </Box>
          <Box mt={3}>
            <DataGridInfiniteScroll
              columns={this.gridColumns}
              fetch={this.fetchBillingHistory}
              refetchKey={this.activeFilters}
              actions={
                this.canMakeCorrection
                  ? {
                      onAdd: {
                        name: 'Make Correction',
                        action: this.openCorrectionModal,
                      },
                    }
                  : {}
              }
              disableColumnMenu
              pathname={this.props.location.pathname}
            />
          </Box>
        </Grid>
        <Dialog open={this.correctionModalOpen} onClose={() => this.closeCorrectionModal(false)}>
          {this.renderForm()}
        </Dialog>
      </Grid>
    );
  }
}

export default withStyles(styles)(Billing);
