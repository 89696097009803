import React from 'react';
import { action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';

import Api, { getErrorMsg } from 'api';
import { inject, WithToastStore } from 'stores';
import { Account } from 'models';

import AutocompleteSearch from 'components/AutocompleteSearch';

interface AccountSearchProps extends WithToastStore {
  onChange: (a: Account | null) => void;
  fetch?: (rmd: string) => Promise<any>;
  label?: string;
  value?: Account;
  placeholder?: string;
  clearSelection?: { onClear?: () => void; clearDelay: number };
  renderOption?: (account: Account) => React.ReactNode;
  selectedAccounts?: Account[]; // Prevent showing the same accounts after they were selected
  className?: string;
  InputProps?: { disableUnderline?: boolean; className?: string };
}

/** A component that displays a field for searching for accounts */
@observer
@inject('toastStore')
class AccountSearch extends React.Component<AccountSearchProps> {
  constructor(props: AccountSearchProps) {
    super(props);
    makeObservable(this);
  }
  /** Gets the label for an account in the autocomplete serach */
  private getAccountOptionLabel(acc?: Account): string {
    if (!acc) {
      // Sometimes we get undefined here, not sure why
      return '';
    } else {
      return acc.name || '';
    }
  }

  /** Renders the option for accounts */
  @action.bound private renderAccountOption(acc: Account): React.ReactNode {
    if (this.props.renderOption) {
      return this.props.renderOption(acc);
    }
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
        <Box>{acc.name}</Box>
        <Box>{acc.code}</Box>
      </Box>
    );
  }

  /** Fetch the accounts by search string */
  @action.bound public async searchAccounts(search: string): Promise<Account[]> {
    if (search.length === 0) {
      return [];
    }
    try {
      const searchAccounts = this.getFetchMethod();
      const resp = await searchAccounts(search);
      if (resp.data) {
        return this.removeSelectedAccounts(resp.data.data);
      } else {
        return [];
      }
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
      return [];
    }
  }

  isSelected(account: Account) {
    return this.props.selectedAccounts!.find(
      (selectedAccount: Account) => account.id === selectedAccount.id,
    );
  }

  removeSelectedAccounts(data: any) {
    const selectedAccounts = this.props.selectedAccounts;
    if (!selectedAccounts || !selectedAccounts.length || !data.length) return data;
    const filteredData = data.filter((account: Account) => !this.isSelected(account));
    return filteredData;
  }

  getFetchMethod(): (search?: any) => Promise<any> {
    if (this.props.fetch) {
      return this.props.fetch;
    }
    return Api.core.searchAccounts;
  }
  render() {
    const { onChange, label, placeholder, value, clearSelection, InputProps, className } = this.props;
    return (
      <AutocompleteSearch
        className={className}
        fetch={this.searchAccounts}
        onChange={onChange}
        label={label}
        value={value}
        getOptionLabel={this.getAccountOptionLabel}
        getOptionSelected={(option, value) => option.id === value.id}
        renderOption={this.renderAccountOption}
        placeholder={placeholder}
        clearSelection={clearSelection}
        InputProps={InputProps}
      />
    );
  }
}

export default AccountSearch;
