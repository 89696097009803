/* eslint-disable react/react-in-jsx-scope */
import { Component, Fragment } from 'react';
import { observable, action, makeObservable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';

import { inject, WithToastStore, WithAnalyticsStore, WithSettingStore } from 'stores';
import { adaptForDataGridPro } from 'services';
import Api, { RequestMetaData } from 'api';
import { paths } from 'routes';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Link } from '@material-ui/core';

import FilterBar from 'components/FilterBar';
import { Filter } from 'components/FilterBar/FilterBar';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles';
import moment from 'moment';

interface AccountLastActivityMatchParams {
  accountId: string;
}

type ActivityDevicesProps = WithStyles<typeof styles> & // Adds the classes prop
  WithAnalyticsStore &
  WithToastStore & // Adds the userStore prop
  WithSettingStore &
  RouteComponentProps<AccountLastActivityMatchParams>;

@inject('userStore', 'toastStore', 'analyticsStore', 'settingStore')
@observer
class AccountLastActivity extends Component<ActivityDevicesProps> {
  constructor(props: ActivityDevicesProps) {
    super(props);
    this.reactions = [];
    makeObservable(this);
    this.matchParams = this.props.match.params;
  }

  /** We store the account id from the router into this observable */
  @observable public matchParams: AccountLastActivityMatchParams;

  /** Object with numbers of active or inactive devices */
  @observable public devices?: Record<string, string>;

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange = this.props.settingStore!.getDate(this.props.location.pathname);

  /** The account id as captured by the URL params */
  @computed public get accountId(): number {
    return parseInt(this.matchParams.accountId);
  }

  /** Mobx reactions */
  @observable private reactions: (() => void)[];

  /** Fetch device count by location */
  @action.bound public fetchLastActivity = adaptForDataGridPro(
    async (rmd: RequestMetaData) => {
      return await Api.analytics.getAccountsLastActivity({
        ...rmd,
        filters: {
          // fromDate: this.dateRange.fromDate,
          // toDate: this.dateRange.toDate,
          ...this.activeFilters,
        },
      });
    },
    (devicesByLocation: any) => {
      return {
        id: uuidv4(),
        ...devicesByLocation,
      };
    },
  );

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.props.settingStore!.setDate(this.props.location.pathname, range);
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
  }

  componentWillUnmount() {
    this.reactions.forEach((dispose: () => void) => dispose());
  }

  renderLocation({ row, value }: any) {
    return (
      <Link component={RouterLink} to={paths.locationDetails(row.locationId)}>
        {value}
      </Link>
    );
  }

  renderAccount({ row, value }: any) {
    return (
      <Link component={RouterLink} to={paths.accountDetails(row.accountId).root()}>
        {value}
      </Link>
    );
  }

  @computed get gridColumns() {
    const gridColumns = [
      {
        headerName: 'Account',
        field: 'account',
        minWidth: 100,
        flex: 1,
        renderCell: this.renderAccount,
      },
      {
        headerName: 'Location',
        field: 'location',
        minWidth: 100,
        flex: 1,
        renderCell: this.renderLocation,
      },
      {
        headerName: 'First Tip',
        field: 'first',
        minWidth: 100,
        flex: 1,
        filterable: true,
        valueGetter: ({ value }: any) =>
          value && moment(new Date(value)).format('MMM DD, YYYY: h:mm A'),
      },
      {
        headerName: 'Last Tip',
        field: 'last',
        minWidth: 100,
        flex: 1,
        filterable: true,
        valueGetter: ({ value }: any) =>
          value && moment(new Date(value)).format('MMM DD, YYYY: h:mm A'),
      },
    ];
    return gridColumns;
  }

  /** List of available filters for FilterBar component */
  filters: Filter[] = [
    { display: 'Account', id: 'accountName', label: 'Contains', type: 'text' },
    { display: 'Location', id: 'locationName', label: 'Contains', type: 'text' },
    {
      display: 'Tips Count',
      id: 'tipsCount',
      label: 'Contents',
      type: 'range',
      interval: {
        from: { label: 'From', value: 'fromAmount' },
        to: { label: 'To', value: 'toAmount' },
        type: 'number',
      },
    },
    // { display: 'Number of tips ', id: 'numberTips', label: 'Contains', type: 'number' }
  ];

  render() {
    return (
      <Fragment>
        <Box>
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }}
            // externalDateRange={{
            //   predefined: this.dateRange,
            //   onChange: this.updateDateRangeValue,
            // }}
          />
        </Box>
        <DataGridInfiniteScroll
          columns={this.gridColumns}
          fetch={this.fetchLastActivity}
          refetchKey={this.activeFilters}
          sortByField="account"
          disableColumnMenu
          pathname={this.props.location.pathname}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(AccountLastActivity);
