import { Theme, createStyles } from '@material-ui/core';
import { createTheme as createMuiTheme } from '@mui/material';
import { _colors, theme } from 'containers/App/theme';

export const themeTimePicker = createMuiTheme({
  ...theme,
  components: {
    PrivatePickersToolbarText: {
      styleOverrides: {
        root: {
          fontSize: '56px',
          color: _colors.tippyBlack.light,
        },
        selected: {
          color: _colors.tippyDeepPurple.main,
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        content: {
          justifyContent: 'center',
        },
      },
    },
    MuiTimePickerToolbar: {
      styleOverrides: {
        root: {
         padding: 0
        },
        ampmSelection: {
          marginRight: 0,
        },
        ampmLabel: {
          fontSize: '18px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
    MuiClock: {
      styleOverrides: {
        pin: {
          backgroundColor: _colors.tippyGreen.main,
        },
      },
    },
    MuiClockPointer: {
      styleOverrides: {
        root: {
          backgroundColor: _colors.tippyGreen.main,
        },
        thumb: {
          backgroundColor: _colors.tippyGreen.main,
          borderColor: _colors.tippyGreen.main,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
    MuiClockPicker: {
      styleOverrides: {},
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    authorLabel: {
      marginRight: theme.spacing(1),
      textTransform: 'uppercase',
    },
    authorValue: {
      color: theme.palette.secondary.main,
    },
    topicLabel: {
      marginRight: theme.spacing(1),
      textTransform: 'uppercase',
    },
    topicValue: {
      textTransform: 'capitalize',
    },
    listItemKey: {
      textTransform: 'capitalize',
      marginRight: theme.spacing(6),
    },
    listItemValue: {
      display: 'inline',
      textTransform: 'uppercase',
      fontSize: 'small',
    },
    listItemIcon: {
      color: theme.palette.grey[500],
      marginLeft: theme.spacing(2),
    },
    listItem: {
      height: 60,
      border: `1px solid ${theme.palette.common.black}`,
      borderRadius: theme.shape.borderRadius,
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.25),
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(3),
    },
    textField: {
      color: theme.palette.text.secondary,
      border: `1px solid grey`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
    },
    day: {},
  });
