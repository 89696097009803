import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    notAvailableLabel: {
      fontSize: '20px',
      color: theme.palette.text.primary,
      fontWeight: 700,
      lineHeight: '23,36px',
    },
    switch: {
      '& .MuiSwitch-root .MuiSwitch-colorSecondary .Mui-checked': {
        color: theme.palette.primary,
      },
    },
    dialog: {
      '& .MuiDialogTitle-root':{
        paddingTop: 24,
      },
      '& .MuiDialogActions-root': {
        padding: 24,
      },
      '& .MuiPaper-root-MuiDialog-paper': {
        borderRadius: theme.shape.borderRadius,
      },
      '& .MuiDialogContent-root': {
        width: '576px',
      },
      '& .MuiTypography-root-MuiDialogTitle-root': {
        paddingTop: '40px',
        paddingLeft: '24px',
        paddingBottom: '32px',
        fontFamily: 'EuclidCircularB',
        fontSize: theme.typography.h5.fontSize,
        fontWeight: '400',
        lineHeight: '35.5px',
        letterSpacing: '0.25px',
      },
    },
    dialogButton: {
      borderRadius: '8px',
      textTransform: 'capitalize',
    },
    dialogMessage:{
      color: theme.palette.common.black 
    }
  });
