import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, computed, flow, makeObservable} from 'mobx';
import { observer } from 'mobx-react';
import qs from 'qs';

import Api, { getErrorMsg } from 'api';
import { createStripe } from 'services/stripe';
import { inject, WithToastStore } from 'stores';

import { Box } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import AnonLayout from 'components/AnonLayout';
import CreditCardForm from './CreditCardForm';

import styles from './styles';

type UpdateCreditCardProps = WithStyles<typeof styles> &
  RouteComponentProps<{ accountId: string }> &
  WithToastStore;
/**
 * Displays the screen for updating credit card
 */
@inject('toastStore')
@observer
class UpdateCreditCard extends Component<UpdateCreditCardProps> {
  constructor(props: UpdateCreditCardProps){
    super(props);
    makeObservable(this);
  }
  /** Whether stripe.js has been loaded */
  @observable public stripeLoaded = false;
  /** When submiting the form */
  @observable public loading = false;
  /** Successfully form submit*/
  @observable public updateSuccess = false;
  /** Marks stripe.js as loaded */
  @action.bound public setStripeLoaded() {
    this.stripeLoaded = true;
  }

  @computed public get lastFour(): number | any {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).lastFour;
  }

  @computed public get billingEntityId(): number | any {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).billingEntityId;
  }

  @computed public get accountId(): number {
    return parseInt(this.props.match.params.accountId);
  }

  @action.bound public startLoading() {
    this.loading = true;
  }

  @action.bound public endLoading() {
    this.loading = false;
  }
  /** Calls the API for editing a payment method */
  @action.bound public editPaymentMethod = flow(function* (
    this: UpdateCreditCard,
    sourceId: string,
  ) {
    try {
      yield Api.billing.editPaymentMethod(sourceId, this.accountId, this.billingEntityId);
      this.updateSuccess = true;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  componentDidMount() {
    // Stripe init
    createStripe().then(this.setStripeLoaded);
  }

  render() {
    return (
      <AnonLayout inProgress={this.loading} applyFooter>
        <Box>
          {this.stripeLoaded && (
            <CreditCardForm
              startLoader={this.startLoading}
              endLoader={this.endLoading}
              onSourceId={this.editPaymentMethod}
              lastFour={this.lastFour}
              updateSuccess={this.updateSuccess}
              accountId={this.accountId}
            />
          )}
        </Box>
      </AnonLayout>
    );
  }
}

export default withStyles(styles)(UpdateCreditCard);
