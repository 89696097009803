import { FieldValues, UseFormHandleSubmit } from 'react-hook-form';
import { FormBuilder } from './FormBuilder';
import { IFormFieldsConfig } from './FormsConfig';

interface FormContainerProps {
  formFieldsConfig: IFormFieldsConfig[];
  control: any; //Control<FieldValues, unknown>
  errors: any; //FieldErrors<FieldValues>
  marginBottom?: number;
  handleSubmit: UseFormHandleSubmit<FieldValues, undefined>;
  onFormSubmit: (data: FieldValues) => void;
}

export const FormContainer = ({ handleSubmit, onFormSubmit, ...rest }: FormContainerProps) => (
  <form onSubmit={handleSubmit((data) => onFormSubmit(data))} style={{ width: '100%' }}>
    {/* Form builder */}
    <FormBuilder {...rest} />
  </form>
);
