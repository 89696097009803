import React from 'react';
import DP from 'components/DashPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { observer } from 'mobx-react';
import usePhoneNumberPanel from './usePhoneNumberPanel';
import useStyles from './styles';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';
import Dialog from 'components/Dialog/Dialog';
import { valueOrNA } from 'utils/helper';

const PhoneNumberPanel = observer(() => {
  const {
    phoneField,
    submit,
    onExited,
    loading,
    error,
    phone,
    editPhone,
    openPhoneDialog,
    closePhoneDialog,
    phoneIndicatorProps,
  } = usePhoneNumberPanel();
  const { pencilIcon } = useStyles();

  return (
    <>
      <DP>
        <DP.Header>
          <DP.Title panel size="small">
            Phone
          </DP.Title>
          <DP.Actions>
            <FontAwesomeIcon onClick={openPhoneDialog} className={pencilIcon} icon={faPen} />
          </DP.Actions>
        </DP.Header>
        <DP.Body>
          <DP.Row>
            <DP.Value>
              {valueOrNA(phone)}
              <DP.Indicator {...phoneIndicatorProps} />
            </DP.Value>
            <DP.Label>Phone</DP.Label>
          </DP.Row>
        </DP.Body>
      </DP>
      <Dialog
        open={editPhone}
        title={'Phone number'}
        content={
          <OutlinedInput
            variant="phone"
            fullWidth
            error={error}
            {...(phoneField && phoneField.bind())}
          />
        }
        confirmActionName="Continue"
        onCancel={closePhoneDialog}
        onConfirm={submit}
        loading={loading}
        TransitionProps={{
          onExited: onExited,
        }}
        disabled={!!error}
      />
    </>
  );
});

export default PhoneNumberPanel;
