import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormHelperText,
  IconButton,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Close } from 'mdi-material-ui';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import validatorjs from 'validatorjs';
import styles from './styles';
import Api, { getErrorMsg } from 'api';
import { Partner, PARTNER_TYPE, PAYOUT_INTERVAL } from 'models/Partner';
import { Developer } from 'models/Developer';
import { inject, WithToastStore } from 'stores';
import { capitalize } from 'lodash';
import { PAYOUT_PROCESSOR } from 'models/Payout';
import _ from 'lodash';
import { Alert } from 'components/Alert/Alert';
import OutlinedInput from 'components/Input/OutlinedInput';
import Button from 'components/Button/Button';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

type PartnerDrawerProps = WithStyles<typeof styles> &
  WithToastStore & { isOpen: boolean; closeDrawer: () => void; updatePartners: () => void };

interface Rules {
  [key: string]: {
    function: (value: any) => boolean;
    message: string;
  };
}

function getDeveloperRule(fieldName: string) {
  return {
    function: (value: Developer) => {
      if (
        !Object.prototype.hasOwnProperty.call(value, 'id') ||
        !Object.prototype.hasOwnProperty.call(value, 'name')
      )
        return false;
      const { id, name } = value;
      if (typeof id === 'number' && typeof name === 'string') return true;
      return false;
    },
    message: `${fieldName} must be valid.`,
  };
}

const rules: Rules = {
  developer: getDeveloperRule('Developer'),
  account: getDeveloperRule('Account'),
  decimal: {
    function: (value: any) => {
      const mask = RegExp('^[0-9]*(.[0-9]{1,2})?$');
      if (mask.test(value)) return true;
      return false;
    },
    message: 'Number must be an integer or decimal number with 2 decimal places.',
  },
};

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator, form }: { validator: any; form: any }) => {
      Object.keys(rules).forEach((key) =>
        validator.register(key, rules[key].function, rules[key].message),
      );
    },
  }),
};
interface IFormState {
  name?: string;
  description?: string;
  type?: string;
  account?: Developer;
  developer?: Developer;
  processor?: string;
  payoutProcessor?: string;
  payoutDestination?: string;
  feeAmount?: number;
  feePercent?: number;
  payoutInterval?: string;
}

const specificFields = [
  'account',
  'developer',
  'processor',
  'payoutProcessor',
  'payoutDestination',
];

@inject('toastStore')
@observer
class PartnerDrawer extends React.Component<PartnerDrawerProps> {
  constructor(props: any) {
    super(props);
    makeObservable(this);
    this.form = new MobxReactForm({ fields: this.fields }, { plugins, hooks: this.hooks });
    this.getProcessors();
    this.getDevelopers();
  }

  private getProcessors = async () => {
    const { data } = await Api.tips.getProcessors();
    this.processorOptions = data.data;
  };

  private getDevelopers = async () => {
    const { data } = await Api.developer.getDevelopers();
    this.developerOptions = data.data.map((developer: Developer) => {
      return { name: developer.name, id: developer.id };
    });
  };

  private getAccounts = async (query: string) => {
    const { data } = await Api.core.searchAccounts(query);
    const filteredData = data.data.map(
      (account: { id: string; name: string; [key: string]: string }) => {
        return { id: account.id, name: account.name };
      },
    );
    return filteredData;
  };

  private form: any;
  @observable private isFormValid = false;
  @observable private showAlert = false;

  @observable private partnerExists = false;
  @observable private isNameDuplicated = false;
  @observable private accountOptions: { id: number; name: string }[] | undefined = undefined;
  @observable private developerOptions: string[] | undefined = undefined;
  @observable private processorOptions: string[] | undefined = undefined;

  private defaultFormState: IFormState = {
    name: undefined,
    description: undefined,
    type: 'account',
    account: undefined,
    developer: undefined,
    processor: undefined,
    payoutProcessor: undefined,
    payoutDestination: undefined,
    feeAmount: undefined,
    feePercent: undefined,
    payoutInterval: 'none',
  };

  @observable private formState: IFormState = {
    ...this.defaultFormState,
  };

  checkIfFormValid = async (showErrors: boolean, name?: string) => {
    let isNameDuplicated = this.isNameDuplicated;
    if (name === 'name') {
      const duplicate = await this.checkIfDuplicateName(this.formState.name);
      if (duplicate) {
        isNameDuplicated = true;
        this.partnerExists = false;
      } else isNameDuplicated = false;
      if (this.isNameDuplicated !== isNameDuplicated) this.isNameDuplicated = isNameDuplicated;
    }
    const isValid = await this.form.validate({ showErrors });
    const hasError = isValid.hasError || isNameDuplicated;
    this.enableSave(hasError);
    await this.checkIfPartnerExists(hasError);
  };

  allowSelectedFields = (name?: string) => {
    if (!name) return;
    if (name !== 'account' && name !== 'processor' && name !== 'developer') return;
  };

  async checkIfPartnerExists(hasError: false) {
    if (hasError) return;

    let filters: any;
    const { developer, processor, account, type } = this.formState;
    if (developer && developer.id) filters = { ...filters, developerId: developer.id };
    if (account && account.id) filters = { ...filters, accountId: account.id };
    if (processor) filters = { ...filters, processor };
    if (type) filters = { ...filters, type };

    const { data } = await Api.tips.getPartners({ filters });
    if (!(data && data.data && data.data.length)) return (this.partnerExists = false);

    const partnerExists = data.data!.find((partner: Partner) => {
      const { accountId, developerId, processor, type } = partner;
      let compare: Partial<Partner> = { developerId, accountId, type, processor };
      this.removeEmptyProperties(compare);
      return _.isEqual(filters, compare);
    });
    if (partnerExists) this.partnerExists = true;
    else this.partnerExists = false;
  }

  removeEmptyProperties = (compare: any) => {
    Object.keys(compare).forEach((value: string, index: number, array: string[]) => {
      const key = value as keyof Partner;
      if (!compare[key]) delete compare[key];
    });
  };

  enableSave = (hasError: boolean) => {
    if (hasError) return (this.isFormValid = false);
    if (!hasError) return (this.isFormValid = true);
  };

  checkIfDuplicateName = async (name?: string) => {
    if (!name) return;
    const { data } = await Api.tips.searchPartnerByName(name);
    if (data && data.data.length) {
      this.form.$('name').invalidate('Partner name already exists.');
      return true;
    }
    return false;
  };

  private changeSelectedField = (e: any) => {
    const { name, value } = e;
    this.clearSpecificFields(name);
    this.formState[name as keyof IFormState] = value;
    this.checkIfFormValid(false, name);
  };

  private changeDateField = (e: any) => {
    const { name, value } = e;
    this.formState[name as keyof IFormState] = value;
    this.checkIfFormValid(false);
  };

  clearSpecificFields = (name: string) => {
    if (name !== 'type') return;
    specificFields.forEach((field: string) => {
      this.changeSelectedField({ name: field, value: undefined });
      this.form.$(field).clear();
    });
  };

  private changeAutocompleteField = (e: any, newValue: any, fieldName: keyof IFormState) => {
    this.changeSelectedField({ name: fieldName, value: newValue });
    this.form.$(fieldName).set('value', newValue);
    this.form.validate(fieldName, { showErrors: true });
  };

  private getNewAccountOptions = async (e: any, newValue: string) => {
    this.accountOptions = await this.getAccounts(newValue);
  };

  @observable private fields = [
    {
      name: 'name',
      label: 'Name',
      value: this.formState.name,
      rules: 'required|string',
      hooks: {
        onChange: this.changeSelectedField,
      },
      required: true,
    },
    {
      name: 'description',
      label: 'Description',
      value: this.formState.description,
      rules: 'string',
      hooks: {
        onChange: this.changeSelectedField,
      },
    },
    {
      name: 'type',
      label: 'Type',
      type: 'select',
      value: this.formState.type,
      rules: 'required|string',
      required: true,
      hooks: {
        onChange: this.changeSelectedField,
      },
    },
    {
      name: 'account',
      label: 'Account',
      value: this.formState.account,
      rules: `required_if:type,${PARTNER_TYPE.ACCOUNT}|account`,
    },
    {
      name: 'developer',
      label: 'Developer',
      value: this.formState.developer,
      rules: `required_if:type,${PARTNER_TYPE.DEVELOPER}|developer`,
    },
    {
      name: 'processor',
      label: 'Processor',
      value: this.formState.processor,
      rules: `required_if:type,${PARTNER_TYPE.PROCESSOR}|string`,
    },
    {
      name: 'payoutProcessor',
      label: 'Payout processor',
      value: this.formState.payoutProcessor,
      rules: `required_if:type,${PARTNER_TYPE.OTHER}|string`,
      hooks: {
        onChange: this.changeSelectedField,
      },
    },
    {
      name: 'payoutDestination',
      label: 'Payout destination',
      value: this.formState.payoutDestination,
      rules: `required_if:type,${PARTNER_TYPE.OTHER}|string`,
      hooks: {
        onChange: this.changeSelectedField,
      },
    },
    {
      name: 'feeAmount',
      label: 'Amount',
      value: this.formState.feeAmount,
      rules: 'decimal',
      hooks: {
        onChange: this.changeSelectedField,
      },
    },
    {
      name: 'feePercent',
      label: 'Percent',
      value: this.formState.feePercent,
      rules: 'decimal',
      hooks: {
        onChange: this.changeSelectedField,
      },
    },
    {
      name: 'payoutInterval',
      label: 'Payout interval',
      type: 'select',
      value: this.formState.payoutInterval,
      rules: 'string',
      hooks: {
        onChange: this.changeSelectedField,
      },
    },
  ];

  private createPartner = (partner: any) => {
    return Api.tips.createPartner(partner);
  };

  private preparePartnerData = () => {
    let { name, description, type, feeAmount, feePercent, payoutInterval } = this
      .formState as Partner;
    let partner: Partner = {
      name,
      description,
      type,
      feeAmount,
      feePercent,
      payoutInterval,
    };
    if (!partner.feeAmount) delete partner.feeAmount;
    else partner.feeAmount = Number(partner.feeAmount);
    if (!partner.feePercent) delete partner.feePercent;
    else partner.feePercent = Number(partner.feePercent);
    if ((type as string) === PARTNER_TYPE.ACCOUNT) {
      const account = this.formState.account;
      partner = { ...partner, accountId: account!.id };
      return partner;
    }
    if ((type as string) === PARTNER_TYPE.DEVELOPER) {
      const developer = this.formState.developer;
      const payoutProcessor = this.formState.payoutProcessor;
      const payoutDestination = this.formState.payoutDestination;
      partner = { ...partner, developerId: developer!.id };
      if (payoutProcessor) partner = { ...partner, payoutProcessor };
      if (payoutDestination) partner = { ...partner, payoutDestination };
      return partner;
    }
    if ((type as string) === PARTNER_TYPE.PROCESSOR) {
      const processor = this.formState.processor;
      partner = { ...partner, processor };
      return partner;
    }
    if ((type as string) === PARTNER_TYPE.OTHER) {
      const payoutProcessor = this.formState.payoutProcessor;
      const payoutDestination = this.formState.payoutDestination;
      partner = { ...partner, payoutProcessor, payoutDestination };
      return partner;
    }
    return undefined;
  };

  private resetAndCloseDrawer = () => {
    this.form.clear();
    this.formState = { ...this.defaultFormState };
    this.form.$('type').set('value', PARTNER_TYPE.ACCOUNT);
    this.form.$('payoutInterval').set('value', PAYOUT_INTERVAL.NONE);
    this.isNameDuplicated = false;
    this.partnerExists = false;
    this.props.closeDrawer();
  };

  partnerExistsWarning = () => {
    switch (this.formState.type) {
      case PARTNER_TYPE.ACCOUNT:
        return 'account';
      case PARTNER_TYPE.DEVELOPER:
        return 'developer';
      case PARTNER_TYPE.PROCESSOR:
        return 'account';
      default:
        return 'data';
    }
  };

  savePartner = async () => {
    const partner = this.preparePartnerData();
    try {
      if (partner === undefined) throw new Error('Something went wrong. Please try again');
      await this.createPartner(partner);
      this.resetAndCloseDrawer();
      this.props.updatePartners();
      this.props.toastStore!.push({ type: 'success', message: 'Partner saved sucessfully' });
    } catch (error) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(error) });
    }
  };

  acceptPartnerSave = () => {
    this.savePartner();
    this.showAlert = false;
  };

  rejectPartnerSave = () => {
    this.showAlert = false;
  };

  renderOtherFields = (required: boolean) => {
    return (
      <>
        <Box mt={2}>
          <OutlinedInput
            {...this.form.$('payoutProcessor').bind()}
            error={this.form.$('payoutProcessor').error}
            required={this.formState.type === PARTNER_TYPE.OTHER}
            select
            fullWidth>
            {Object.values(PAYOUT_PROCESSOR).map((processor, index) => (
              <MenuItem key={`${processor}-${index}`} value={processor}>
                {capitalize(processor)}
              </MenuItem>
            ))}
          </OutlinedInput>
        </Box>
        <Box mt={2}>
          <OutlinedInput
            {...this.form.$('payoutDestination').bind()}
            error={this.form.$('payoutDestination').error}
            required={this.formState.type === PARTNER_TYPE.OTHER}
            fullWidth
          />
        </Box>
      </>
    );
  };

  private hooks = {
    onSuccess: async () => {
      if (this.partnerExists) this.showAlert = true;
      else this.savePartner();
    },
  };

  render() {
    const { dialogActions, formHelperText } = this.props.classes;
    return (
      <>
        <Drawer
          title="Settings"
          className={this.props.classes.root}
          anchor="right"
          open={this.props.isOpen}>
          <DialogTitle>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography style={{ fontSize: 28 }} component="h1" display="inline">
                Create Partner
              </Typography>
              <IconButton onClick={() => this.resetAndCloseDrawer()}>
                <Close color="inherit" />
              </IconButton>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <form onSubmit={this.form.onSubmit} id="partner-form">
              <Box mt={4}>
                <OutlinedInput
                  {...this.form.$('name').bind()}
                  error={this.form.$('name').error || this.isNameDuplicated}
                  required={this.form.$('name').rules.includes('required')}
                  helperText={
                    this.isNameDuplicated
                      ? 'Partner name already exists'
                      : this.form.$('name').error
                  }
                  fullWidth
                />
              </Box>
              <Box mt={2}>
                <OutlinedInput
                  {...this.form.$('description').bind()}
                  error={this.form.$('description').error}
                  required={this.form.$('description').rules.includes('required')}
                  fullWidth
                />
              </Box>
              <Box mt={2}>
                <OutlinedInput
                  {...this.form.$('type').bind()}
                  error={this.form.$('type').error}
                  required={this.form.$('type').rules.includes('required')}
                  select
                  fullWidth>
                  {Object.values(PARTNER_TYPE).map((type, index) => {
                    if (type === PARTNER_TYPE.TIPPY) return;
                    return (
                      <MenuItem key={`${type}-${index}`} value={type}>
                        {capitalize(type)}
                      </MenuItem>
                    );
                  })}
                </OutlinedInput>
              </Box>
              {this.formState.type === PARTNER_TYPE.ACCOUNT && (
                <Box mt={2}>
                  <Autocomplete
                    options={this.accountOptions || []}
                    onChange={(e: any, newValue) =>
                      this.changeAutocompleteField(e, newValue, 'account')
                    }
                    onInputChange={this.getNewAccountOptions}
                    getOptionLabel={(options) => options.name}
                    renderInput={(params) => (
                      <>
                        <OutlinedInput
                          {...params}
                          ref={params.InputProps.ref}
                          inputProps={params.inputProps}
                          label={this.form.$('account').label}
                          error={this.form.$('account').error}
                          required={this.formState.type === PARTNER_TYPE.ACCOUNT}
                          fullWidth
                        />
                      </>
                    )}
                  />
                </Box>
              )}
              {this.formState.type === PARTNER_TYPE.DEVELOPER && (
                <Box mt={2}>
                  <Autocomplete
                    options={this.developerOptions || []}
                    onChange={(e: any, newValue) =>
                      this.changeAutocompleteField(e, newValue, 'developer')
                    }
                    getOptionLabel={(options: any) => options.name}
                    renderInput={(params) => (
                      <>
                        <OutlinedInput
                          {...params}
                          ref={params.InputProps.ref}
                          inputProps={params.inputProps}
                          label={this.form.$('developer').label}
                          error={this.form.$('developer').error}
                          required={this.formState.type === PARTNER_TYPE.DEVELOPER}
                          fullWidth
                        />
                      </>
                    )}
                  />
                  {this.renderOtherFields(false)}
                </Box>
              )}
              {this.formState.type === PARTNER_TYPE.PROCESSOR && (
                <Box mt={2}>
                  <Autocomplete
                    options={this.processorOptions || []}
                    onChange={(e: any, newValue) =>
                      this.changeAutocompleteField(e, newValue, 'processor')
                    }
                    renderInput={(params) => (
                      <>
                        <OutlinedInput
                          {...params}
                          ref={params.InputProps.ref}
                          inputProps={params.inputProps}
                          label={this.form.$('processor').label}
                          required={this.formState.type === PARTNER_TYPE.PROCESSOR}
                          error={this.form.$('processor').error}
                          fullWidth
                        />
                      </>
                    )}
                  />
                </Box>
              )}
              {this.formState.type === PARTNER_TYPE.OTHER && this.renderOtherFields(true)}
              <Box mt={2}>
                <OutlinedInput
                  {...this.form.$('feeAmount').bind()}
                  error={this.form.$('feeAmount').error}
                  required={this.form.$('feeAmount').rules.includes('required')}
                  fullWidth
                />
              </Box>
              <Box mt={2}>
                <OutlinedInput
                  {...this.form.$('feePercent').bind()}
                  error={this.form.$('feePercent').error}
                  required={this.form.$('feePercent').rules.includes('required')}
                  fullWidth
                />
              </Box>
              <Box mt={2}>
                <OutlinedInput
                  {...this.form.$('payoutInterval').bind()}
                  error={this.form.$('payoutInterval').error}
                  required={this.form.$('payoutInterval').rules.includes('required')}
                  select
                  fullWidth>
                  {Object.values(PAYOUT_INTERVAL).map((interval, index) => (
                    <MenuItem key={`${interval}-${index}`} value={interval}>
                      {capitalize(interval)}
                    </MenuItem>
                  ))}
                </OutlinedInput>
              </Box>
            </form>
          </DialogContent>
          <DialogActions className={dialogActions}>
            <Button
              form="partner-form"
              disabled={!this.isFormValid}
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth={true}>
              Save
            </Button>
            <FormHelperText className={formHelperText} error={this.partnerExists}>
              {this.partnerExists &&
                `Warning: partner with selected ${this.partnerExistsWarning()} already exists.`}
            </FormHelperText>
          </DialogActions>
        </Drawer>
        <Alert
          open={this.showAlert}
          acceptButton="Save"
          rejectButton="Cancel"
          buttonsPosition="flex-end"
          maxWidth="sm"
          onAccept={this.acceptPartnerSave}
          onReject={this.rejectPartnerSave}
          title="Are you sure you want to save partner with slected data?"
          message={`Saving partner with current data means there will be multiple partners with same ${this.partnerExistsWarning()} data.`}
        />
      </>
    );
  }
}

export default withStyles(styles)(PartnerDrawer);
