import React from 'react';
import { Box, Typography } from '@material-ui/core';
import CreditCardIcon from 'components/CreditCardIcon';
import { BillingEntity } from 'models';
import useStyles from './styles';
import NotificationItem from '../NotificationItem/NotificationItem';
import { getSecondaryText } from '../helpers';

export function PaymentItem({
  paymentMethod,
  editPaymentItem,
}: {
  paymentMethod: BillingEntity;
  editPaymentItem: (billingEntity: BillingEntity) => void;
}) {
  const { creditCardIcon } = useStyles();
  return (
    <NotificationItem
      title="Credit Card Expiration"
      body={
        <Box display={'flex'} alignItems={'center'}>
          <CreditCardIcon
            style={{ width: 40, height: 25.5 }}
            className={creditCardIcon}
            brand={paymentMethod!.paymentMethod!.brand!.toLowerCase()}
          />
          <Box display={'flex'} flexDirection={'column'}>
            <Typography variant="body1">
              {' '}
              {paymentMethod!.paymentMethod!.brand!} {paymentMethod!.paymentMethod!.lastFour!}
            </Typography>
            <Typography variant="body2">{getSecondaryText(paymentMethod)}</Typography>
          </Box>
          <Typography variant="body2"></Typography>
        </Box>
      }
      buttons={[{ label: 'Update', onClick: () => editPaymentItem(paymentMethod) }]}
    />
  );
}
