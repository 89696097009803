import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyItems: 'center',
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 172,
      height: 172,
      borderRadius: '50%',
      backgroundColor: theme.palette.divider,
    },
    icon: {
      width: 72,
      height: 72,
      color: theme.palette.text.disabled,
    },
    refreshBtn: {
      position: 'relative',
      color: theme.palette.primary.main,
      cursor: 'pointer',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: 2,
        backgroundColor: theme.palette.primary.main,
        top: `calc(100% + 2px)`,
        left: 0,
      },
    },
    message: {
      marginTop: theme.spacing(5),
    },
  }),
);

export default useStyles;
