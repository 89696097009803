import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Box, Divider, Typography } from '@material-ui/core';
import Button from 'components/Button/Dialog/Button';

interface INotificationItemProps {
  title: string;
  body: ReactNode;
  buttons: { label: string; dataCy?: string; loading?: boolean; onClick: () => any }[];
}
const NotificationItem = ({ title, body, buttons }: INotificationItemProps) => {
  //TODO: add option to remove item from notificationItems in NotificationStore on onClick?
  return (
    <>
      <Box display="flex" alignItems={'center'} justifyContent={'space-between'} mt={2} mb={2}>
        <Box>
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {title}
          </Typography>
          {body}
        </Box>
        <Box display={'flex'} gridGap={8}>
          {buttons.map((button, index) => (
            <Button
              data-cy={button.dataCy}
              variant="contained"
              key={index}
              loading={button.loading}
              onClick={button.onClick}>
              {button.label}
            </Button>
          ))}
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default observer(NotificationItem);
