/**
 * This file contains various validation functions used in the dashboard
 */

/** The US phone regex */
export const US_PHONE_NUMBER_PATTERN =
  /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;

/** The SI phone regex */
export const SI_PHONE_NUMBER_PATTERN = /^\+386\s?([1-9][0-9])\s?([0-9]{3})\s?([0-9]{3})$/;

/** The US zip code regex */
const zipRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;

/** The US currency regex */
const usCurrencyRegex = /^[+-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/;

/** Email regex */
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

/** Tests whether a given string is a valid US phone number */
export const isPhone = (s: string) =>
  US_PHONE_NUMBER_PATTERN.test(s) || SI_PHONE_NUMBER_PATTERN.test(s);

/** Tests whether a given string is a valid US zip code */
export const isZip = (s: string) => new RegExp(zipRegex).test(s);

/** Tests whether a given string is a valid US currency format */
export const isCurrency = (s: string) => new RegExp(usCurrencyRegex).test(s);

/** Tests whether a given string is a valid email format */
export const isEmail = (s: string) => new RegExp(emailRegex).test(s);
