/* eslint-disable @typescript-eslint/no-unused-vars */
import { alpha } from '@mui/material/styles';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    itemAmount: {
      fontWeight: '700 !important',
    },
    bigProgress: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      backgroundColor: alpha('#fff', 0.5),
      zIndex: 500,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    firstItem: {
      paddingTop: 0,
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
