import React from 'react';

import { action, computed, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';

import { paths } from 'routes';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import Api, * as api from 'api';
import * as stores from 'stores';

import DashboardLayout from 'containers/DashboardLayout';

import styles from './styles';
import UserCardList from 'components/UserCardList';

import { setTitle } from 'services';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';

const PAGE_TITLE = 'Users';

let abortController = new AbortController();

type UsersProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps & // Adds the router props (history, match, location)
  stores.WithUserStore; // Adds the userStore prop

/**
 * Renders tab bar (`talent`, `managers`, `owners`) and UserCardList components as
 * routes for each tab. Each UserCardList gets its own fetch function. This view's route
 * scopes are `admin`, `manager` and `owner`. Manager does not see `owners` tab.
 */
@stores.inject('userStore')
@observer
class Users extends React.Component<UsersProps> {
  constructor(props: UsersProps) {
    super(props);
    makeObservable(this);
  }
  /** Is profession color codes legend modal opened? */
  @observable public colorCodeModalOpened = false;

  @computed public get scope(): stores.ScopeType {
    return this.props.userStore!.scope.kind;
  }

  /** The accountId of the current account in scope, if any */
  @computed public get accountId(): number | undefined {
    return this.props.userStore!.currentAccount && this.props.userStore!.currentAccount.id;
  }

  /** The locationId of the current location in scope, if any */
  @computed public get locationId(): number | undefined {
    return (
      this.props.userStore!.currentManagedLocation &&
      this.props.userStore!.currentManagedLocation.id
    );
  }

  @action.bound public fetchUsers(req?: api.RequestMetaData, search?: Record<string, string>) {
    if (this.scope === 'global_owner') {
      return Api.core.getGloballyOwnedUsers(req, search);
    }

    const accountId = (req && req.filters && req.filters.accountId) || this.accountId;
    const locationId = this.locationId;
    const locationUserStatus = accountId || locationId ? 'active' : undefined;
    this.abortApiCallAndSetUpNewController();

    return Api.core.getUsers(
      req,
      { ...search, accountId, locationId, locationUserStatus },
      abortController,
    );
  }

  @action.bound public fetchAccountUsers(
    req?: api.RequestMetaData,
    search?: Record<string, string>,
  ) {
    const accountId = this.accountId!;
    this.abortApiCallAndSetUpNewController();

    return Api.core.getAccountUsers(accountId, req, search, abortController);
  }

  abortApiCallAndSetUpNewController(): void {
    abortController.abort();
    abortController = new AbortController();
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  render() {
    const classes = this.props.classes;
    const fetch = this.accountId ? this.fetchAccountUsers : this.fetchUsers;
    return (
      <DashboardLayout>
        <UserCardList fetch={fetch} ownerView={Boolean(this.accountId)} />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          className={classes.fabContainer}>
          {/* NOTE: We have removed professions selection from the signup flow, so we do not want 
          to display profession colors or color legend for professions anymore. If we ever need this 
          functionality again, see https://meettippy.atlassian.net/browse/TIP-1694 for code changes */}
          {(this.scope === 'manager' || this.scope === 'owner') && (
            <PlusFabButton
              component={RouterLink}
              to={paths.users().invite()}
              dataCy="invite-users-link"
            />
          )}
        </Box>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Users);
