import React from 'react';
import { Button as MuiButton, ButtonProps } from '@material-ui/core';
import { useStyles } from './styles';
import clsx from 'clsx';
import { CircularProgress } from '@mui/material';

export type TButtonProps = ButtonProps & {
  component?: React.ElementType;
  to?: string;
  loading?: boolean;
  textTransform?: React.CSSProperties['textTransform'];
};

export default function Button({
  children,
  loading,
  variant = 'contained',
  textTransform,
  style,
  ...rest
}: TButtonProps) {
  const { font, progress, button, outlinedButton } = useStyles({
    variant,
    disabled: rest.disabled,
  });
  const outlined = !!(variant === 'outlined');
  return (
    <MuiButton
      disableElevation
      {...rest}
      style={{ textTransform, ...style, cursor: 'pointer' }}
      variant={variant}
      className={clsx(outlined ? outlinedButton : button, font, rest.className)}>
      {loading ? <CircularProgress classes={{ circle: progress }} /> : children}
    </MuiButton>
  );
}
