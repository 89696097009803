import {
  Device,
  License,
  PaymentMethod,
  Charge,
  Hardware,
  Inventory,
  InventoryStatus,
  HardwareType,
  CardPayout,
} from 'models';
/** Mock data used for development */

interface Wrapped<T> {
  data: {
    data: T;
    count?: number;
    sortable?: string[];
  };
}
export function wrap<T>(data: T, count?: number, sortable?: string[]): Wrapped<T> {
  return {
    data: {
      data,
      count,
      sortable,
    },
  };
}

export const cardPayouts: Wrapped<CardPayout[]> = wrap(
  [
    {
      id: 52,
      walletId: 1,
      ledgerId: 398,
      transactionId: '398-1589496388702',
      processor: 'netspend',
      amount: '7500',
      processorFee: '0',
      status: 'failed',
      errorCode: 411,
      errorMessage: 'Exceeds max transaction amount or max balance',
      createdAt: '2020-05-14T22:46:28.714Z',
    },
    {
      id: 51,
      walletId: 2,
      ledgerId: 391,
      transactionId: '391-1589496380995',
      processor: 'netspend',
      amount: '36.88',
      processorFee: '0',
      status: 'failed',
      errorCode: 302,
      errorMessage: 'Location was not found',
      createdAt: '2020-05-14T22:46:20.999Z',
    },
    {
      id: 50,
      walletId: 1,
      ledgerId: 398,
      transactionId: '398-1589496372709',
      processor: 'netspend',
      amount: '7500',
      processorFee: '0',
      status: 'failed',
      errorCode: 411,
      errorMessage: 'Exceeds max transaction amount or max balance',
      createdAt: '2020-05-14T22:46:12.690Z',
    },
    {
      id: 49,
      walletId: 2,
      ledgerId: 391,
      transactionId: '391-1589496349506',
      processor: 'netspend',
      amount: '36.88',
      processorFee: '0',
      status: 'failed',
      errorCode: 302,
      errorMessage: 'Location was not found',
      createdAt: '2020-05-14T22:45:49.498Z',
    },
    {
      id: 48,
      walletId: 2,
      ledgerId: 391,
      transactionId: '391-1589496280206',
      processor: 'netspend',
      amount: '36.88',
      processorFee: '0',
      status: 'failed',
      errorCode: 0,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-14T22:44:40.216Z',
    },
    {
      id: 47,
      walletId: 1,
      ledgerId: 398,
      transactionId: '398-1589399268025',
      processor: 'netspend',
      amount: '7500',
      processorFee: '0',
      status: 'failed',
      errorCode: 411,
      errorMessage: 'Exceeds max transaction amount or max balance',
      createdAt: '2020-05-13T19:47:48.028Z',
    },
    {
      id: 46,
      walletId: 1,
      ledgerId: 396,
      transactionId: '396-1589399219238',
      processor: 'netspend',
      amount: '9.22',
      processorFee: '0',
      status: 'processed',
      createdAt: '2020-05-13T19:46:59.219Z',
    },
    {
      id: 45,
      walletId: 1,
      ledgerId: 394,
      transactionId: '394-1589399137437',
      processor: 'netspend',
      amount: '297.94',
      processorFee: '0',
      status: 'processed',
      createdAt: '2020-05-13T19:45:37.429Z',
    },
    {
      id: 44,
      walletId: 1,
      ledgerId: 393,
      transactionId: '393-1589399136298',
      processor: 'netspend',
      amount: '7500',
      processorFee: '0',
      status: 'processed',
      createdAt: '2020-05-13T19:45:36.291Z',
    },
    {
      id: 43,
      walletId: 1,
      ledgerId: 392,
      transactionId: '392-1589399025333',
      processor: 'netspend',
      amount: '7500',
      processorFee: '0',
      status: 'failed',
      errorCode: 301,
      errorMessage: 'Account was not found',
      createdAt: '2020-05-13T19:43:45.314Z',
    },
    {
      id: 42,
      walletId: 2,
      ledgerId: 391,
      transactionId: '391-1589376344023',
      processor: 'netspend',
      amount: '36.88',
      processorFee: '0',
      status: 'failed',
      errorCode: 302,
      errorMessage: 'Location was not found',
      createdAt: '2020-05-13T13:25:44.041Z',
    },
    {
      id: 41,
      walletId: 2,
      ledgerId: 390,
      transactionId: '390-1589376238971',
      processor: 'netspend',
      amount: '27.66',
      processorFee: '0',
      status: 'failed',
      errorCode: 302,
      errorMessage: 'Location was not found',
      createdAt: '2020-05-13T13:23:58.943Z',
    },
    {
      id: 40,
      walletId: 2,
      ledgerId: 389,
      transactionId: '389-1589376198421',
      processor: 'netspend',
      amount: '18.44',
      processorFee: '0',
      status: 'failed',
      errorCode: 302,
      errorMessage: 'Location was not found',
      createdAt: '2020-05-13T13:23:18.404Z',
    },
    {
      id: 39,
      walletId: 1,
      ledgerId: 388,
      transactionId: '388-1589368756524',
      processor: 'netspend',
      amount: '9.22',
      processorFee: '0',
      status: 'failed',
      errorCode: 301,
      errorMessage: 'Account was not found',
      createdAt: '2020-05-13T11:19:16.521Z',
    },
    {
      id: 38,
      walletId: 1,
      ledgerId: 386,
      transactionId: '386-1589368698892',
      processor: 'netspend',
      amount: '9.22',
      processorFee: '0',
      status: 'processed',
      createdAt: '2020-05-13T11:18:18.887Z',
    },
    {
      id: 37,
      walletId: 2,
      ledgerId: 385,
      transactionId: '385-1589368479638',
      processor: 'netspend',
      amount: '9.22',
      processorFee: '0',
      status: 'failed',
      errorCode: 302,
      errorMessage: 'Location was not found',
      createdAt: '2020-05-13T11:14:39.624Z',
    },
    {
      id: 36,
      walletId: 2,
      ledgerId: 383,
      transactionId: '383-1589368186728',
      processor: 'netspend',
      amount: '9.22',
      processorFee: '0',
      status: 'processed',
      createdAt: '2020-05-13T11:09:46.722Z',
    },
    {
      id: 35,
      walletId: 2,
      ledgerId: 381,
      transactionId: '381-1589367968145',
      processor: 'netspend',
      amount: '9.22',
      processorFee: '0',
      status: 'processed',
      createdAt: '2020-05-13T11:06:08.116Z',
    },
    {
      id: 34,
      walletId: 2,
      ledgerId: 379,
      transactionId: '379-1589367540185',
      processor: 'netspend',
      amount: '27.66',
      processorFee: '0',
      status: 'processed',
      createdAt: '2020-05-13T10:59:00.193Z',
    },
    {
      id: 32,
      walletId: 1,
      ledgerId: 376,
      transactionId: '376-1589366943188',
      processor: 'netspend',
      amount: '18.44',
      processorFee: '0',
      status: 'processed',
      createdAt: '2020-05-13T10:49:03.205Z',
    },
    {
      id: 31,
      walletId: 1,
      ledgerId: 375,
      transactionId: '375-1589366833977',
      processor: 'netspend',
      amount: '9.22',
      processorFee: '0',
      status: 'failed',
      errorCode: 0,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-13T10:47:13.966Z',
    },
    {
      id: 30,
      walletId: 1,
      ledgerId: 373,
      transactionId: '373-1589229070050',
      processor: 'netspend',
      amount: '9.22',
      processorFee: '0',
      status: 'processed',
      createdAt: '2020-05-11T20:31:10.095Z',
    },
    {
      id: 29,
      walletId: 1,
      ledgerId: 371,
      transactionId: '371-1589200207896',
      processor: 'netspend',
      amount: '9.22',
      processorFee: '0',
      status: 'processed',
      createdAt: '2020-05-11T12:30:07.881Z',
    },
    {
      id: 28,
      walletId: 1,
      ledgerId: 369,
      transactionId: '369-1589200082345',
      processor: 'netspend',
      amount: '221.28',
      processorFee: '0',
      status: 'processed',
      createdAt: '2020-05-11T12:28:02.310Z',
    },
    {
      id: 27,
      walletId: 1,
      ledgerId: 368,
      transactionId: '368-1589200012650',
      processor: 'netspend',
      amount: '212.06',
      processorFee: '0',
      status: 'failed',
      errorCode: 302,
      errorMessage: 'Location was not found',
      createdAt: '2020-05-11T12:26:52.655Z',
    },
    {
      id: 26,
      walletId: 1,
      ledgerId: 367,
      transactionId: '367-1589199919649',
      processor: 'netspend',
      amount: '202.84',
      processorFee: '0',
      status: 'failed',
      errorCode: 0,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-11T12:25:19.648Z',
    },
    {
      id: 25,
      walletId: 1,
      ledgerId: 366,
      transactionId: '366-1589199224728',
      processor: 'netspend',
      amount: '193.62',
      processorFee: '0',
      status: 'failed',
      errorCode: 302,
      errorMessage: 'Location was not found',
      createdAt: '2020-05-11T12:13:44.708Z',
    },
    {
      id: 24,
      walletId: 1,
      ledgerId: 365,
      transactionId: '365-1589198846657',
      processor: 'netspend',
      amount: '184.4',
      processorFee: '0',
      status: 'failed',
      errorCode: 302,
      errorMessage: 'Location was not found',
      createdAt: '2020-05-11T12:07:26.665Z',
    },
    {
      id: 23,
      walletId: 1,
      ledgerId: 364,
      transactionId: '364-1589198720798',
      processor: 'netspend',
      amount: '175.18',
      processorFee: '0',
      status: 'failed',
      errorCode: 0,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-11T12:05:20.790Z',
    },
    {
      id: 21,
      walletId: 1,
      ledgerId: 359,
      transactionId: '359-1589132740311',
      processor: 'netspend',
      amount: '138.3',
      processorFee: '0',
      status: 'failed',
      errorCode: 0,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-10T17:45:40.289Z',
    },
    {
      id: 20,
      walletId: 1,
      ledgerId: 358,
      transactionId: '358-1588885111537',
      processor: 'netspend',
      amount: '129.08',
      processorFee: '0',
      status: 'failed',
      errorCode: 304,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-07T20:58:31.499Z',
    },
    {
      id: 19,
      walletId: 1,
      ledgerId: 357,
      transactionId: '357-1588881148577',
      processor: 'netspend',
      amount: '119.86',
      processorFee: '0',
      status: 'failed',
      errorCode: 304,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-07T19:52:28.567Z',
    },
    {
      id: 18,
      walletId: 1,
      ledgerId: 356,
      transactionId: '356-1588866097656',
      processor: 'netspend',
      amount: '110.64',
      processorFee: '0',
      status: 'failed',
      errorCode: 302,
      errorMessage: 'Location was not found',
      createdAt: '2020-05-07T15:41:37.645Z',
    },
    {
      id: 17,
      walletId: 1,
      ledgerId: 355,
      transactionId: '355-1588866051578',
      processor: 'netspend',
      amount: '101.42',
      processorFee: '0',
      status: 'failed',
      errorCode: 302,
      errorMessage: 'Location was not found',
      createdAt: '2020-05-07T15:40:51.595Z',
    },
    {
      id: 16,
      walletId: 1,
      ledgerId: 354,
      transactionId: '354-1588865968382',
      processor: 'netspend',
      amount: '92.2',
      processorFee: '0',
      status: 'failed',
      errorCode: 0,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-07T15:39:28.350Z',
    },
    {
      id: 15,
      walletId: 1,
      ledgerId: 353,
      transactionId: '353-1588865928315',
      processor: 'netspend',
      amount: '82.98',
      processorFee: '0',
      status: 'failed',
      errorCode: 0,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-07T15:38:48.309Z',
    },
    {
      id: 14,
      walletId: 1,
      ledgerId: 352,
      transactionId: '352-1588865885456',
      processor: 'netspend',
      amount: '73.76',
      processorFee: '0',
      status: 'failed',
      errorCode: 0,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-07T15:38:05.436Z',
    },
    {
      id: 13,
      walletId: 1,
      ledgerId: 351,
      transactionId: '351-1588865831100',
      processor: 'netspend',
      amount: '64.54',
      processorFee: '0',
      status: 'failed',
      errorCode: 0,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-07T15:37:11.076Z',
    },
    {
      id: 12,
      walletId: 1,
      ledgerId: 350,
      transactionId: '350-1588865735095',
      processor: 'netspend',
      amount: '55.32',
      processorFee: '0',
      status: 'failed',
      errorCode: 0,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-07T15:35:35.079Z',
    },
    {
      id: 11,
      walletId: 1,
      ledgerId: 349,
      transactionId: '349-1588865413981',
      processor: 'netspend',
      amount: '46.1',
      processorFee: '0',
      status: 'failed',
      errorCode: 0,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-07T15:30:14.004Z',
    },
    {
      id: 10,
      walletId: 1,
      ledgerId: 348,
      transactionId: '348-1588865360655',
      processor: 'netspend',
      amount: '36.88',
      processorFee: '0',
      status: 'failed',
      errorCode: 0,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-07T15:29:20.670Z',
    },
    {
      id: 9,
      walletId: 1,
      ledgerId: 347,
      transactionId: '347-1588864602940',
      processor: 'netspend',
      amount: '27.66',
      processorFee: '0',
      status: 'failed',
      errorCode: 0,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-07T15:16:42.926Z',
    },
    {
      id: 8,
      walletId: 1,
      ledgerId: 346,
      transactionId: '346-1588864526195',
      processor: 'netspend',
      amount: '18.44',
      processorFee: '0',
      status: 'failed',
      errorCode: 0,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-07T15:15:26.166Z',
    },
    {
      id: 7,
      walletId: 1,
      ledgerId: 345,
      transactionId: '345-1588864409520',
      processor: 'netspend',
      amount: '9.22',
      processorFee: '0',
      status: 'failed',
      errorCode: 0,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-07T15:13:29.495Z',
    },
    {
      id: 6,
      walletId: 1,
      ledgerId: 343,
      transactionId: '343-1588863811681',
      processor: 'netspend',
      amount: '9.22',
      processorFee: '0',
      status: 'processed',
      createdAt: '2020-05-07T15:03:31.657Z',
    },
    {
      id: 5,
      walletId: 1,
      ledgerId: 341,
      transactionId: '341-1588858982313',
      processor: 'netspend',
      amount: '21.15',
      processorFee: '0',
      status: 'processed',
      createdAt: '2020-05-07T13:43:01.480Z',
    },
    {
      id: 4,
      walletId: 1,
      ledgerId: 340,
      transactionId: '340-1588858802396',
      processor: 'netspend',
      amount: '11.93',
      processorFee: '0',
      status: 'failed',
      errorCode: 0,
      errorMessage: 'Unexpected error occurred while attempting to fund card',
      createdAt: '2020-05-07T13:40:02.422Z',
    },
    {
      id: 3,
      walletId: 1,
      ledgerId: 299,
      transactionId: '299-1588809079563',
      processor: 'netspend',
      amount: '18.95',
      processorFee: '0',
      status: 'processed',
      createdAt: '2020-05-06T23:51:19.577Z',
    },
    {
      id: 2,
      walletId: 1,
      ledgerId: 296,
      transactionId: '296-1588808959716',
      processor: 'netspend',
      amount: '15.06',
      processorFee: '0',
      status: 'processed',
      createdAt: '2020-05-06T23:49:19.717Z',
    },
    {
      id: 1,
      walletId: 1,
      ledgerId: 294,
      transactionId: '294-1588808866530',
      processor: 'netspend',
      amount: '326.56',
      processorFee: '0',
      status: 'processed',
      createdAt: '2020-05-06T23:47:46.536Z',
    },
  ],
  50,
  [
    'id',
    'walletId',
    'ledgerId',
    'status',
    'amount',
    'processor',
    'transactionId',
    'processorFee',
    'createdAt',
  ],
);

export const devices: Wrapped<Device[]> = wrap([
  {
    id: 1,
    identifier: '3131',
    isActive: true,
    os: 'ios',
    app: 'tippy_pro',
    osVersion: '43.23',
    appVersion: '1.03',
    tabletId: 11,
    tablet: {
      id: 1,
      number: 'JSKFUEX',
      serial: 'C02XR4YFSGH5',
      status: InventoryStatus.AVAILABLE,
      hardwareId: 1,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardware: {
        id: 1,
        manufacturer: 'Apple',
        type: HardwareType.TABLET,
        model: 'iPad 9.7"',
        isAvailable: true,
      },
    },
    readerId: 12,
    reader: {
      id: 1,
      number: 'JSKFUEX',
      serial: 'C02XR4YFSGH5',
      status: InventoryStatus.AVAILABLE,
      hardwareId: 1,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardware: {
        id: 1,
        manufacturer: 'TippyReader',
        type: HardwareType.READER,
        model: 'TRI-100',
        isAvailable: true,
      },
    },
    locationId: 13,
    location: {
      id: 13,
      createdAt: '2020-02-11T12:12:12.000Z',
      updatedAt: '2020-02-12T12:12:12.000Z',
      timezone: 'EST',
      name: 'Nailed It Sarasota',
      isActive: true,
      code: '1574',
      pin: '4157',
      accountId: 14,
      account: {
        id: 14,
        createdAt: '2019-10-10T12:12:12.000Z',
        name: 'Nailed It',
        canRegenerateCode: false,
      },
    },
    createdAt: '2020-02-13T12:12:12.000Z',
    updatedAt: '2020-02-17T12:12:12.000Z',
  },
  {
    id: 2,
    identifier: '93B38',
    isActive: true,
    os: 'android',
    app: 'tippy_pro',
    osVersion: '43.21',
    appVersion: '1.03',
    tabletId: 21,
    tablet: {
      id: 1,
      number: 'JSKFUEX',
      serial: 'C02XR4YFSGH5',
      status: InventoryStatus.AVAILABLE,
      hardwareId: 1,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardware: {
        id: 1,
        manufacturer: 'Samsung',
        type: HardwareType.TABLET,
        model: 'Galaxy Tab A 8"',
        isAvailable: true,
      },
    },
    readerId: 22,
    reader: {
      id: 1,
      number: 'JSKFUEX',
      serial: 'C02XR4YFSGH5',
      status: InventoryStatus.AVAILABLE,
      hardwareId: 1,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardware: {
        id: 1,
        manufacturer: 'TippyReader',
        type: HardwareType.READER,
        model: 'TRA-100',
        isAvailable: true,
      },
    },
    locationId: 23,
    location: {
      id: 23,
      createdAt: '2020-02-11T12:12:12.000Z',
      updatedAt: '2020-02-12T12:12:12.000Z',
      timezone: 'EST',
      name: 'Wigging in Wisconsin',
      isActive: true,
      code: '7854',
      pin: '9098',
      accountId: 24,
      account: {
        id: 24,
        createdAt: '2019-10-10T12:12:12.000Z',
        name: 'Wigging Out',
        canRegenerateCode: false,
      },
    },
    createdAt: '2020-02-15T12:12:12.000Z',
    updatedAt: '2020-02-18T12:12:12.000Z',
  },
  {
    id: 3,
    identifier: '9343C',
    isActive: false,
    os: 'ios',
    app: 'tippy_pro',
    osVersion: '43.24',
    appVersion: '1.02',
    tabletId: 31,
    tablet: {
      id: 1,
      number: 'JSKFUEX',
      serial: 'C02XR4YFSGH5',
      status: InventoryStatus.AVAILABLE,
      hardwareId: 1,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardware: {
        id: 1,
        manufacturer: 'Apple',
        type: HardwareType.TABLET,
        model: 'iPad 9.7"',
        isAvailable: true,
      },
    },
    readerId: 32,
    reader: {
      id: 1,
      number: 'JSKFUEX',
      serial: 'C02XR4YFSGH5',
      status: InventoryStatus.AVAILABLE,
      hardwareId: 1,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardware: {
        id: 1,
        manufacturer: 'TippyReader',
        type: HardwareType.READER,
        model: 'TRI-100',
        isAvailable: true,
      },
    },
    locationId: 33,
    location: {
      id: 33,
      createdAt: '2020-02-11T12:12:12.000Z',
      updatedAt: '2020-02-12T12:12:12.000Z',
      timezone: 'EST',
      name: 'Manicures San Fran',
      isActive: true,
      code: '7686',
      pin: '5556',
      accountId: 34,
      account: {
        id: 34,
        createdAt: '2019-10-10T12:12:12.000Z',
        name: 'Manicures R Us',
        canRegenerateCode: false,
      },
    },
    createdAt: '2020-02-14T10:10:10.000Z',
    updatedAt: '2020-02-14T12:12:12.000Z',
  },
  {
    id: 4,
    identifier: '3131C',
    isActive: true,
    os: 'ios',
    app: 'tippy_pro',
    osVersion: '43.23',
    appVersion: '1.03',
    tabletId: 41,
    tablet: {
      id: 1,
      number: 'JSKFUEX',
      serial: 'C02XR4YFSGH5',
      status: InventoryStatus.AVAILABLE,
      hardwareId: 1,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardware: {
        id: 1,
        manufacturer: 'Apple',
        type: HardwareType.TABLET,
        model: 'iPad 9.7"',
        isAvailable: true,
      },
    },
    readerId: 42,
    reader: {
      id: 1,
      number: 'JSKFUEX',
      serial: 'C02XR4YFSGH5',
      status: InventoryStatus.AVAILABLE,
      hardwareId: 1,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardware: {
        id: 1,
        manufacturer: 'TippyReader',
        type: HardwareType.READER,
        model: 'TRI-100',
        isAvailable: true,
      },
    },
    locationId: 43,
    location: {
      id: 43,
      createdAt: '2020-02-11T12:12:12.000Z',
      updatedAt: '2020-02-12T12:12:12.000Z',
      timezone: 'EST',
      name: 'Cascading Stylists NYC',
      isActive: true,
      code: '3453',
      pin: '2344',
      accountId: 44,
      account: {
        id: 44,
        createdAt: '2019-10-10T12:12:12.000Z',
        name: 'Cascading Stylists Inc',
        canRegenerateCode: false,
      },
    },
    createdAt: '2020-02-15T12:12:12.000Z',
  },
  {
    id: 5,
    identifier: '93BC9',
    isActive: true,
    os: 'android',
    app: 'tippy_pro',
    osVersion: '43.22',
    appVersion: '1.04',
    tabletId: 51,
    tablet: {
      id: 1,
      number: 'JSKFUEX',
      serial: 'C02XR4YFSGH5',
      status: InventoryStatus.AVAILABLE,
      hardwareId: 1,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardware: {
        id: 1,
        manufacturer: 'Samsung',
        type: HardwareType.TABLET,
        model: 'Galaxy Tab A 8"',
        isAvailable: true,
      },
    },
    readerId: 52,
    reader: {
      id: 1,
      number: 'JSKFUEX',
      serial: 'C02XR4YFSGH5',
      status: InventoryStatus.AVAILABLE,
      hardwareId: 1,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardware: {
        id: 1,
        manufacturer: 'TippyReader',
        type: HardwareType.READER,
        model: 'TRA-100',
        isAvailable: true,
      },
    },
    locationId: 53,
    location: {
      id: 53,
      createdAt: '2020-02-11T12:12:12.000Z',
      updatedAt: '2020-02-12T12:12:12.000Z',
      timezone: 'EST',
      name: "Harry's of Harlem",
      isActive: true,
      code: '8676',
      pin: '8787',
      accountId: 54,
      account: {
        id: 54,
        createdAt: '2019-10-10T12:12:12.000Z',
        name: "Harry's Hair Emporium",
        canRegenerateCode: false,
      },
    },
    createdAt: '2020-02-15T12:12:12.000Z',
    updatedAt: '2020-02-18T12:12:12.000Z',
  },
  {
    id: 6,
    identifier: '9343X',
    isActive: false,
    os: 'ios',
    app: 'tippy_pro',
    osVersion: '43.23',
    appVersion: '1.03',
    tabletId: 61,
    tablet: {
      id: 1,
      number: 'JSKFUEX',
      serial: 'C02XR4YFSGH5',
      status: InventoryStatus.AVAILABLE,
      hardwareId: 1,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardware: {
        id: 1,
        manufacturer: 'Apple',
        type: HardwareType.TABLET,
        model: 'iPad 9.7"',
        isAvailable: true,
      },
    },
    readerId: 62,
    reader: {
      id: 1,
      number: 'JSKFUEX',
      serial: 'C02XR4YFSGH5',
      status: InventoryStatus.AVAILABLE,
      hardwareId: 1,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardware: {
        id: 1,
        manufacturer: 'TippyReader',
        type: HardwareType.READER,
        model: 'TRI-100',
        isAvailable: true,
      },
    },
    locationId: 63,
    location: {
      id: 63,
      createdAt: '2020-02-11T12:12:12.000Z',
      updatedAt: '2020-02-12T12:12:12.000Z',
      timezone: 'EST',
      name: 'MAG Kansas City',
      isActive: true,
      code: '3453',
      pin: '5656',
      accountId: 64,
      account: {
        id: 64,
        createdAt: '2019-10-10T12:12:12.000Z',
        name: 'Mohawks and Goatees',
        canRegenerateCode: false,
      },
    },
    createdAt: '2020-02-12T12:12:12.000Z',
    updatedAt: '2020-02-13T12:12:12.000Z',
  },
]);

export const paymentMethods: Wrapped<PaymentMethod[]> = wrap([
  {
    id: 1,
    brand: 'Visa',
    isActive: true,
    lastFour: '0569',
    validThru: '04/2023',
  },
  {
    id: 2,
    brand: 'MasterCard',
    isActive: true,
    lastFour: '0114',
    validThru: '1/2020',
  },
  {
    id: 3,
    brand: 'American Express',
    isActive: true,
    lastFour: '0114',
    validThru: '12/2019',
  },
  {
    id: 4,
    brand: 'Unknown',
    isActive: true,
    lastFour: '0114',
    validThru: '10/2020',
  },
  {
    id: 5,
    brand: 'Discover',
    isActive: true,
    lastFour: '0114',
    validThru: '09/2018',
  },
]);

export const licenses: Wrapped<License[]> = wrap([
  {
    id: 1,
    status: 'inactive',
    location: {
      id: 1,
      name: 'Opasna Lokacija 1',
    },
    product: {
      id: 1,
      name: 'Kiosk monthly',
      description: 'Kiosk monthly subscription',
      price: '$24.99',
    },
    expiresAt: '2019-05-16T13:53:07',
    paymentMethodId: paymentMethods.data.data[1].id,
  },
  {
    id: 2,
    status: 'active',
    location: {
      id: 1,
      name: 'Opasna Lokacija 2',
    },
    product: {
      id: 1,
      name: 'Kiosk monthly',
      description: 'Kiosk monthly subscription',
      price: '$24.99',
    },
    expiresAt: '2020-07-20T13:53:07',
    paymentMethodId: paymentMethods.data.data[4].id,
  },
  {
    id: 3,
    status: 'active',
    location: {
      id: 1,
      name: 'Opasna Lokacija 3',
    },
    product: {
      id: 1,
      name: 'Kiosk monthly',
      description: 'Kiosk monthly subscription',
      price: '$24.99',
    },
    expiresAt: '2021-01-03T13:53:07',
    paymentMethodId: paymentMethods.data.data[4].id,
  },
  {
    id: 4,
    status: 'active',
    location: {
      id: 1,
      name: 'Opasna Lokacija 4',
    },
    product: {
      id: 1,
      name: 'Kiosk monthly',
      description: 'Kiosk monthly subscription',
      price: '$24.99',
    },
    expiresAt: '2020-05-16T13:53:07',
    paymentMethodId: paymentMethods.data.data[3].id,
  },
  {
    id: 5,
    status: 'active',
    location: {
      id: 1,
      name: 'Opasna Lokacija 4',
    },
    product: {
      id: 1,
      name: 'Kiosk monthly',
      description: 'Kiosk monthly subscription',
      price: '$24.99',
    },
    expiresAt: '2020-05-16T13:53:07',
    paymentMethodId: paymentMethods.data.data[2].id,
  },
  {
    id: 6,
    status: 'active',
    location: {
      id: 1,
      name: 'Opasna Lokacija 4',
    },
    product: {
      id: 1,
      name: 'Kiosk monthly',
      description: 'Kiosk monthly subscription',
      price: '$24.99',
    },
    expiresAt: '2020-05-16T13:53:07',
    paymentMethodId: paymentMethods.data.data[1].id,
  },
  {
    id: 7,
    status: 'active',
    location: {
      id: 1,
      name: 'Opasna Lokacija 4',
    },
    product: {
      id: 1,
      name: 'Kiosk monthly',
      description: 'Kiosk monthly subscription',
      price: '$24.99',
    },
    expiresAt: '2020-05-16T13:53:07',
    paymentMethodId: paymentMethods.data.data[0].id,
  },
]);

export const charges: Wrapped<Charge[]> = wrap([
  {
    id: 1,
    paymentMethod: paymentMethods.data.data[0],
    amount: 503.05,
    chargeDate: '2019-05-15 00:00:02.017+00',
    invoice: {
      id: 1,
      number: 'TIPPY-124',
    },
  },
  {
    id: 2,
    paymentMethod: paymentMethods.data.data[1],
    amount: 1325.25,
    chargeDate: '2020-01-10 00:00:02.017+00',
    invoice: {
      id: 2,
      number: 'TIPPY-254',
    },
  },
  {
    id: 3,
    paymentMethod: paymentMethods.data.data[2],
    amount: 952.1,
    chargeDate: '2020-01-15 00:00:02.017+00',
    invoice: {
      id: 3,
      number: 'TIPPY-512',
    },
  },
  {
    id: 4,
    paymentMethod: paymentMethods.data.data[3],
    amount: 489.0,
    chargeDate: '2019-12-120 00:00:02.017+00',
    invoice: {
      id: 4,
      number: 'TIPPY-123',
    },
  },
  {
    id: 5,
    paymentMethod: paymentMethods.data.data[4],
    amount: 503.05,
    chargeDate: '2010-01-10 00:00:02.017+00',
    invoice: {
      id: 5,
      number: 'TIPPY-753',
    },
  },
]);

export const inventory: Wrapped<Inventory[]> = wrap(
  [
    {
      id: 1,
      number: 'JSKFUEX',
      serial: 'C02XR4YFSGH5',
      status: InventoryStatus.AVAILABLE,
      hardwareId: 1,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardware: {
        id: 1,
        manufacturer: 'Apple',
        type: HardwareType.TABLET,
        model: 'iPad 9.7"',
        isAvailable: true,
      },
    },
    {
      id: 2,
      number: 'UTHSLEO',
      serial: 'D02YRMYFZGH5',
      status: InventoryStatus.AVAILABLE,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardwareId: 1,
      hardware: {
        id: 1,
        manufacturer: 'Apple',
        type: HardwareType.TABLET,
        model: 'iPad 9.7"',
        isAvailable: true,
      },
    },
    {
      id: 3,
      number: 'PPLWJYW',
      serial: 'C44PRMYFZGH5',
      status: InventoryStatus.AVAILABLE,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardwareId: 1,
      hardware: {
        id: 1,
        manufacturer: 'Apple',
        type: HardwareType.TABLET,
        model: 'iPad 9.7"',
        isAvailable: true,
      },
    },
    {
      id: 4,
      number: 'NNMACAB',
      serial: 'V09XR1YFWYD',
      status: InventoryStatus.AVAILABLE,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardwareId: 2,
      hardware: {
        id: 2,
        manufacturer: 'Samsung',
        type: HardwareType.TABLET,
        model: 'Galaxy Tab A 8"',
        isAvailable: true,
      },
    },
    {
      id: 5,
      number: 'GGMACAB',
      serial: 'V31XRAYFWYD',
      status: InventoryStatus.AVAILABLE,
      lastUpdate: '2020-02-13T13:26:31.864Z',
      hardwareId: 3,
      hardware: {
        id: 3,
        manufacturer: 'Square',
        type: HardwareType.READER,
        model: 'Reader 2',
        isAvailable: true,
      },
    },
  ],
  10,
  [],
);

export const hardware: Wrapped<Hardware[]> = wrap([
  {
    id: 1,
    manufacturer: 'Apple',
    model: 'iPad 7',
    type: HardwareType.TABLET,
    isAvailable: true,
  },
  {
    id: 2,
    manufacturer: 'Apple',
    model: 'iPad 8',
    type: HardwareType.TABLET,
    isAvailable: true,
  },
  {
    id: 2,
    manufacturer: 'Samsung',
    model: 'Galaxy Tab 2',
    type: HardwareType.TABLET,
    isAvailable: true,
  },
  {
    id: 2,
    manufacturer: 'Square',
    model: 'Reader 2',
    type: HardwareType.READER,
    isAvailable: true,
  },
]);

export const paymentIntentNetNoTip = {
  data: {
    data: {
      processor: 'square', // type PaymentProcessor
      mode: 'net', // net || gross
      tips: [
        {
          userUid: 'MJ6VXY4P',
          serviceAmount: '55.7',
          level: 'unknown',
          proposals: {
            serviceAmount: '55.7',
            low: {
              percent: '23.34',
              amount: '13',
              type: 'amount',
              fee: '0.99',
              total: '13.99',
              mode: 'net',
            },
            medium: {
              percent: '28.73',
              amount: '16',
              type: 'amount',
              fee: '1.1',
              total: '17.1',
              mode: 'net',
            },
            high: {
              percent: '34.11',
              amount: '19',
              type: 'amount',
              fee: '1.21',
              total: '20.21',
              mode: 'net',
            },
          },
        },
        {
          userUid: '84K6MM4E',
          serviceAmount: '885.7',
          level: 'unknown',
          proposals: {
            serviceAmount: '885.7',
            low: {
              percent: '20.77',
              amount: '184',
              type: 'amount',
              fee: '7.19',
              total: '191.19',
              mode: 'net',
            },
            medium: {
              percent: '25.97',
              amount: '230',
              type: 'amount',
              fee: '8.86',
              total: '238.86',
              mode: 'net',
            },
            high: {
              percent: '31.16',
              amount: '276',
              type: 'amount',
              fee: '10.53',
              total: '286.53',
              mode: 'net',
            },
          },
        },
        {
          userUid: 'KJR5PK0M',
          serviceAmount: '558.7',
          level: 'custom',
          proposals: {
            serviceAmount: '558.7',
            low: {
              percent: '20.94',
              amount: '117',
              type: 'amount',
              fee: '4.76',
              total: '121.76',
              mode: 'net',
            },
            medium: {
              percent: '26.13',
              amount: '146',
              type: 'amount',
              fee: '5.81',
              total: '151.81',
              mode: 'net',
            },
            high: {
              percent: '31.32',
              amount: '175',
              type: 'amount',
              fee: '6.86',
              total: '181.86',
              mode: 'net',
            },
          },
        },
      ],
      total: {
        fee: '6.43',
        tipAmount: '134.3',
        total: '140.73',
      },
    },
  },
};

export const paymentIntentNetWithTip = {
  data: {
    data: {
      processor: 'square', // type PaymentProcessor
      mode: 'net', // net || gross
      tips: [
        {
          userUid: 'MJ6VXY4P',
          serviceAmount: '55.7',
          level: 'custom',
          amount: '11.7',
          fee: '0.95',
          total: '12.65',
          proposals: {
            serviceAmount: '55.7',
            low: {
              percent: '23.34',
              amount: '13',
              type: 'amount',
              fee: '0.99',
              total: '13.99',
              mode: 'net',
            },
            medium: {
              percent: '28.73',
              amount: '16',
              type: 'amount',
              fee: '1.1',
              total: '17.1',
              mode: 'net',
            },
            high: {
              percent: '34.11',
              amount: '19',
              type: 'amount',
              fee: '1.21',
              total: '20.21',
              mode: 'net',
            },
          },
        },
        {
          userUid: '84K6MM4E',
          serviceAmount: '885.7',
          level: 'custom',
          amount: '77.8',
          fee: '3.34',
          total: '81.14',
          proposals: {
            serviceAmount: '885.7',
            low: {
              percent: '20.77',
              amount: '184',
              type: 'amount',
              fee: '7.19',
              total: '191.19',
              mode: 'net',
            },
            medium: {
              percent: '25.97',
              amount: '230',
              type: 'amount',
              fee: '8.86',
              total: '238.86',
              mode: 'net',
            },
            high: {
              percent: '31.16',
              amount: '276',
              type: 'amount',
              fee: '10.53',
              total: '286.53',
              mode: 'net',
            },
          },
        },
      ],
      total: {
        fee: '6.43',
        tipAmount: '134.3',
        total: '140.73',
      },
    },
  },
};

export const paymentIntentGrossNoTip = {
  data: {
    data: {
      processor: 'square',
      mode: 'gross',
      tips: [
        {
          userUid: 'MJ6VXY4P',
          serviceAmount: '55.7',
          level: 'unknown',
          proposals: {
            serviceAmount: '55.7',
            low: {
              percent: '23.34',
              amount: '13',
              type: 'amount',
              fee: '0.99',
              total: '13',
              mode: 'gross',
            },
            medium: {
              percent: '28.73',
              amount: '16',
              type: 'amount',
              fee: '1.1',
              total: '16',
              mode: 'gross',
            },
            high: {
              percent: '34.11',
              amount: '19',
              type: 'amount',
              fee: '1.21',
              total: '19',
              mode: 'gross',
            },
          },
        },
        {
          userUid: '84K6MM4E',
          serviceAmount: '885.7',
          level: 'unknown',
          proposals: {
            serviceAmount: '885.7',
            low: {
              percent: '20.77',
              amount: '184',
              type: 'amount',
              fee: '7.19',
              total: '184',
              mode: 'gross',
            },
            medium: {
              percent: '25.97',
              amount: '230',
              type: 'amount',
              fee: '8.86',
              total: '230',
              mode: 'gross',
            },
            high: {
              percent: '31.16',
              amount: '276',
              type: 'amount',
              fee: '10.53',
              total: '276',
              mode: 'gross',
            },
          },
        },
      ],
      total: {
        fee: '6.43',
        tipAmount: '134.3',
        total: '140.73',
      },
    },
  },
};

export const paymentIntentGrossWithTip = {
  data: {
    data: {
      processor: 'square',
      mode: 'gross',
      tips: [
        {
          userUid: 'MJ6VXY4P',
          serviceAmount: '55.7',
          level: 'custom',
          amount: '11.7',
          fee: '0.95',
          total: '11.7',
          proposals: {
            serviceAmount: '55.7',
            low: {
              percent: '23.34',
              amount: '13',
              // type: 'amount',
              type: 'percent',
              fee: '0.99',
              total: '13',
              mode: 'gross',
            },
            medium: {
              percent: '28.73',
              amount: '16',
              // type: 'amount',
              type: 'percent',
              fee: '1.1',
              total: '16',
              mode: 'gross',
            },
            high: {
              percent: '34.11',
              amount: '19',
              // type: 'amount',
              type: 'percent',
              fee: '1.21',
              total: '19',
              mode: 'gross',
            },
          },
        },
        {
          userUid: '84K6MM4E',
          serviceAmount: '885.7',
          level: 'medium',
          proposals: {
            serviceAmount: '885.7',
            low: {
              percent: '20.77',
              amount: '184',
              // type: 'amount',
              type: 'percent',
              fee: '7.19',
              total: '184',
              mode: 'gross',
            },
            medium: {
              percent: '25.97',
              amount: '230',
              // type: 'amount',
              type: 'percent',
              fee: '8.86',
              total: '230',
              mode: 'gross',
            },
            high: {
              percent: '31.16',
              amount: '276',
              // type: 'amount',
              type: 'percent',
              fee: '10.53',
              total: '276',
              mode: 'gross',
            },
          },
        },
      ],
      total: {
        fee: '6.43',
        tipAmount: '134.3',
        total: '140.73',
      },
    },
  },
};

export const affiliateConversions: Wrapped<any[]> = wrap([
  {
    id: 1,
    firstName: 'joe',
    lastName: 'rogan',
    count: '22',
    percentTotal: '9.23',
    percentAffiliates: '24.63',
    commission: '100$',
    totalCommissions: '6530$',
    lastSignup: '2020-02-12T12:12:12.000Z',
  },
  {
    id: 2,
    firstName: 'harry',
    lastName: 'smith',
    count: '22',
    percentTotal: '9.23',
    percentAffiliates: '24.63',
    commission: '100$',
    totalCommissions: '6530$',
    lastSignup: '2020-02-12T12:12:12.000Z',
  },
  {
    id: 3,
    firstName: 'mike',
    lastName: 'tyson',
    count: '22',
    percentTotal: '9.23',
    percentAffiliates: '24.63',
    commission: '100$',
    totalCommissions: '6530$',
    lastSignup: '2020-02-12T12:12:12.000Z',
  },
  {
    id: 4,
    firstName: 'tina',
    lastName: 'turner',
    count: '22',
    percentTotal: '9.23',
    percentAffiliates: '24.63',
    commission: '100$',
    totalCommissions: '6530$',
    lastSignup: '2020-02-12T12:12:12.000Z',
  },
]);

export const conversionStats = {
  data: {
    data: {
      count: 435,
      percent: 34.22,
      total: 3482,
    },
  },
};
