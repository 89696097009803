/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, makeObservable, computed } from 'mobx';
import validatorjs from 'validatorjs';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, FormControl, InputAdornment, FormHelperText } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment'; // Material-ui date-picker dependency
import { CakeVariant, MapMarker } from 'mdi-material-ui';
import moment from 'moment';

import { paths } from 'routes';
import { inject, WithToastStore, WithUserStore } from 'stores';
import { User } from 'models';
import Api, { getErrorMsg } from 'api';
import styles from './styles';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import OutlinedInput from 'components/Input/OutlinedInput';
import OutlinedDatePicker from 'components/Input/OutlinedDatePicker';
import SignupLayout, {
  TSignupLayoutAction,
  TSignupLayoutSubAction,
} from 'containers/SignupLayout/SignupLayout';
import { MINIMAL_AGE_REQUIRED } from 'utils/constants';
import { EDateFormat } from 'utils/helper';
import * as models from 'models';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

type TUserTypes = Pick<models.Invitation, 'isManager' | 'isTalent'>;

type PersonalInfo2Props = WithStyles<typeof styles> &
  RouteComponentProps &
  WithToastStore &
  WithUserStore;

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any; form: any }) => {
      validator.register(
        'zip',
        (value: string) => new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/).test(value),
        'Please input a valid zip code',
      );
      validator.setMessages('en', {
        ...validator.getMessages('en'),
        dob: 'Please input a valid last name',
      });
    },
  }),
};

const formId = 'personal-info2-form';

const maxDate = moment();
maxDate.subtract(MINIMAL_AGE_REQUIRED, 'years');

interface PersonalInfoHooks {
  onSuccess: (form: any) => void;
  onClear: (form: any) => void;
}

/**
 * Displays the prompt to enter additional user data
 */
@inject('toastStore', 'userStore')
@observer
class PersonalInfo2 extends React.Component<PersonalInfo2Props> {
  /** Whether the info is currently being submitted */
  @observable public submitting = false;

  @observable public userTypes?: TUserTypes;

  /** The action to submit the current personal info to the server */
  @action.bound public submit = flow(function* (this: PersonalInfo2) {
    const userStore = this.props.userStore!;
    try {
      this.submitting = true;
      const resp = yield Api.core.updateUser(userStore.user!.id, {
        // If the user is an affiliate, dob will be "", so we pass undefined
        dob: this.form.$('dob').value || undefined,
        zip: this.form.$('zip').value,
      });
      const user: User = resp && resp.data && resp.data.data;
      userStore.setUserData(user);

      const locationState: any = this.props.history.location.state;
      // If the user is an affiliate, go to the organization step
      if (userStore.affiliate) {
        this.props.history.push(paths.affiliateSignUpOrg());
      }
      // Else if the user is being onboarded as manager, go to profile picture
      else if (locationState.managerOnboarding) {
        this.props.history.push({
          pathname: paths.signUp().profilePicture(),
          state: {
            managerOnboarding: locationState.managerOnboarding,
          },
        });
      }
      // Else check if user was invited as talent, manager or both and redirect appropriately
      else {
        //If user is invited to all locations as manager only, skip profession step
        if (this.userTypes?.isManager && !this.userTypes?.isTalent) {
          this.props.history.push(paths.signUp().location());
        } else {
          this.props.history.push(paths.signUp().profession());
        }
      }
    } catch (error: any) {
      this.props.toastStore!.push({
        type: 'error',
        message: getErrorMsg(error) || 'An error has occurred',
      });
    } finally {
      this.submitting = false;
    }
  });

  @computed public get signupLayoutProps() {
    const title = 'Personal Info';
    const subtitle = 'Almost there, just a few more things!';
    const action: TSignupLayoutAction = {
      type: 'submit',
      form: formId,
      loading: this.submitting,
      children: 'Continue',
    };
    const subAction: TSignupLayoutSubAction = {
      back: {
        onClick: this.props.history.goBack,
        label: 'Back to previous step',
      },
    };
    return { title, subtitle, action, subAction };
  }

  public constructor(props: PersonalInfo2Props) {
    super(props);
    makeObservable(this);
    const user = props.userStore!.user!;
    const affiliate = props.userStore!.affiliate;
    const dobRules = affiliate ? 'date' : 'required|date';
    const fields = [
      {
        name: 'dob',
        label: 'Date of Birth',
        value: user.dob ? moment(user.dob).format(EDateFormat.DATE_ISO) : '',
        rules: dobRules,
      },
      {
        name: 'zip',
        label: 'Zip',
        value: user.zip,
        rules: ['zip', 'required'],
      },
    ];
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }

  /** The form object */
  private form: any;

  /** The hooks for the form */
  private hooks: PersonalInfoHooks = {
    onSuccess: () => {
      this.submit();
    },
    onClear: (form: any) => {
      form.clear();
    },
  };

  /** Fetch invitations and extract manager and talent flags */
  @action.bound public getUserTypes = async (id: models.User['id']) => {
    try {
      const { data } = await Api.core.getUserInvitations(id);
      if (data) {
        const userTypes = data.data?.reduce(
          (previousValue, invitation: models.Invitation) => {
            if (invitation.isTalent) previousValue.isTalent = true;
            if (invitation.isManager) previousValue.isManager = true;
            return previousValue;
          },
          { isTalent: false, isManager: false },
        );
        this.userTypes = userTypes;
      } else {
        this.userTypes = undefined;
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  };

  componentDidMount() {
    if (!this.props.userStore?.user?.id) return;
    this.getUserTypes(this.props.userStore.user.id);
  }

  render() {
    const { userStore } = this.props;
    const showDobField = !userStore!.affiliate;
    const fields = {
      dob: this.form.$('dob'),
      zip: this.form.$('zip'),
    };
    return (
      <CarouselScreenWrapper submitting={this.submitting}>
        <SignupLayout {...this.signupLayoutProps}>
          <form id={formId} onSubmit={this.form.onSubmit}>
            <Box display={'flex'} flexDirection={'column'} gridGap={16}>
              {showDobField && (
                <FormControl fullWidth>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <OutlinedDatePicker
                      variant="dialog"
                      value={fields.dob.value === '' ? null : fields.dob.value}
                      clearable
                      format={EDateFormat.DATE_LONG}
                      openTo="year"
                      views={['year', 'month', 'date']}
                      label={fields.dob.label}
                      maxDate={maxDate}
                      initialFocusedDate={maxDate}
                      disableFuture
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CakeVariant fontSize="small" color="action" />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(date) =>
                        date && fields.dob.set(moment(date).format(EDateFormat.DATE_ISO))
                      }
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                  <FormHelperText error={true}>{fields.dob.error}</FormHelperText>
                </FormControl>
              )}
              <OutlinedInput
                {...fields.zip.bind()}
                error={fields.zip.error}
                fullWidth
                inputProps={{
                  maxLength: 5,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MapMarker fontSize="small" color="action" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </form>
        </SignupLayout>
      </CarouselScreenWrapper>
    );
  }
}

export default withStyles(styles)(PersonalInfo2);
