import BaseStore from './BaseStore';
import { observable, action, makeObservable, computed } from 'mobx';
import RootStore from './RootStore';
import moment from 'moment';
import { BillingEntity, ITransferRequest, Invitation } from 'models';
import Api, { getErrorMsg } from 'api';
import * as LocalStorage from 'services/localStorage';
import { ACL } from 'types';
import { IRefundRequest } from 'models/Refunds';
import { rootStore } from 'containers/App/App';
import { SYNCHRONIZE_NOTIFICATION_STORE_INTERVAL } from 'utils/constants';

const STORE_NAME = 'notificationStore';
const ITEM_NAME = 'notifications-reminder';
export const NOTIFICATIONS_LS_KEY = `${STORE_NAME}/${ITEM_NAME}-`;

interface IPendingNotification {
  remindOn: string;
}

// Use this to save different notifications (can be dynamic object)
// and then use computed to create an array of mapped items...
interface INotificationItems {
  refundRequests: IRefundRequest[];
  transferRequests: ITransferRequest[];
  expiredPaymentMethods: BillingEntity[];
  invitations: Invitation[];
}

export enum ENotificationType {
  REFUND_REQUEST = 'refund_request',
  TRANSFER_TIP_REQUEST = 'transfer_tip_request',
  EXPIRED_PAYMENT_METHODS = 'expired_payment_methods',
  INVITATION = 'invitation',
  DEFAULT_TYPE = 'default',
}

export interface IRefundRequestNotificationItem {
  numberOfRequests: number;
}
export interface ITransferTipRequestNotificationItem {
  numberOfRequests: number;
}
interface IRefundNotification {
  type: ENotificationType.REFUND_REQUEST;
  item: IRefundRequestNotificationItem;
}

interface ITransferTipRequestNotification {
  type: ENotificationType.TRANSFER_TIP_REQUEST;
  item: IRefundRequestNotificationItem;
}

interface IBillingEntityNotification {
  type: ENotificationType.EXPIRED_PAYMENT_METHODS;
  item: BillingEntity;
}

interface IInvitationNotification {
  type: ENotificationType.INVITATION;
  item: Invitation;
}

export type TNotification =
  | IRefundNotification
  | ITransferTipRequestNotification
  | IBillingEntityNotification
  | IInvitationNotification;

type TNotifications = (
  | IRefundNotification
  | ITransferTipRequestNotification
  | IBillingEntityNotification
  | IInvitationNotification
)[];

const defaultNotificationItems = {
  refundRequests: [],
  transferRequests: [],
  expiredPaymentMethods: [],
  invitations: [],
};

/**
 * Remind later feature applies even to new notifications which come after user clicked 'remind later' option.
 * This could be changed in the future for better user experience.
 *
 * We also do not have a mechanism which deletes 'remind later' value from local storage when all current notifications
 * have been delalt with.
 */
export default class NotificationStore extends BaseStore {
  constructor(props: RootStore) {
    super(props);
    makeObservable(this);
  }
  // Still used only because it is used in some other parts of the app not related to notifications.
  @observable public refundRequests?: any;
  @observable public openNotification = false;
  @observable public login = false;
  @observable public forceClose = false;
  @observable public notificationItems: INotificationItems = { ...defaultNotificationItems };
  @observable public hideNotificationDialog = false;

  /**
   *  When Notifications component is loaded this is set to true. This is a (bad) way
   * to prevent notifications being shown on pages which do not use DashboardLayout. We will need
   * to improve the logic for showing notifications after notification service is up and running
   */
  @observable public canShowNotifications = false;

  @computed get notifications(): TNotifications | undefined {
    const invitations = this.mapToNotificationItem<Invitation>(
      this.notificationItems.invitations,
      ENotificationType.INVITATION,
    );

    let refundNotifications = this.mapToNotificationItem(
      [{ numberOfRequests: this.notificationItems.refundRequests.length || 0 }],
      ENotificationType.REFUND_REQUEST,
    );
    if (!refundNotifications[0]?.item?.numberOfRequests) {
      refundNotifications = [];
    }

    let transferRequestNotifications = this.mapToNotificationItem(
      [{ numberOfRequests: this.notificationItems.transferRequests.length || 0 }],
      ENotificationType.TRANSFER_TIP_REQUEST,
    );
    if (!transferRequestNotifications[0]?.item?.numberOfRequests) {
      transferRequestNotifications = [];
    }

    const expiredPaymentNotifications = this.mapToNotificationItem<BillingEntity>(
      this.notificationItems.expiredPaymentMethods,
      ENotificationType.EXPIRED_PAYMENT_METHODS,
    );

    return [
      ...invitations,
      ...refundNotifications,
      ...transferRequestNotifications,
      ...expiredPaymentNotifications,
    ];
  }

  @computed get openNotificationsDialog() {
    let open = false;
    if (!this.canShowNotifications) return false;
    if (!this.numberOfNotifications) {
      open = false;
      this.setOpenNotification(open);
    } else if (this.openNotification) {
      open = true;
    } else if (this.login && this.showPendingNotifications() && !this.forceClose) {
      open = true;
      this.setOpenNotification(open);
    }
    return open;
  }

  @computed get expiredPaymentMethods() {
    return this.notificationItems.expiredPaymentMethods;
  }

  @computed get numberOfNotifications() {
    return this.notifications?.length;
  }

  @action.bound setHideNotificationDialog(hide: boolean) {
    this.hideNotificationDialog = hide;
  }

  @action.bound showPendingNotifications() {
    if (rootStore.userStore!.authUser.isAdmin) {
      return !!this.notificationItems?.refundRequests.length;
    }
    // check pending notifications for owners
    const pendingNotification = this.checkForPendingNotification();
    if (!pendingNotification) return true;
    return moment(pendingNotification.remindOn).isBefore(new Date());
  }

  @action.bound async getExpiredPaymentMethods() {
    const { data } = await Api.billing.getExpiredPaymentMethods();
    this.notificationItems = { ...this.notificationItems, expiredPaymentMethods: data?.data || [] };

    if (!data?.data?.length) return;
    return data.data as BillingEntity[];
  }

  @action.bound async getCreatedRefundRequests() {
    const { data } = await Api.tips.getRefundRequests({ filters: { status: 'created' } });
    this.notificationItems = { ...this.notificationItems, refundRequests: data?.data || [] };
    if (!data?.data) return;
    this.refundRequests = data.data;
    return data.data;
  }

  @action.bound async getCreatedTransferTipRequests() {
    const { data } = await Api.tips.getTransferTipRequests({ filters: { status: 'created' } });
    this.notificationItems = { ...this.notificationItems, transferRequests: data?.data || [] };
  }

  @action.bound public getInvitations = async () => {
    try {
      const res = await Api.core.getUserInvitations(rootStore.userStore!.user!.id);
      this.notificationItems = { ...this.notificationItems, invitations: res?.data?.data || [] };
    } catch (error) {
      console.error(getErrorMsg(error));
    }
  };

  @action.bound setOpenNotification(open: boolean) {
    this.openNotification = open;
  }

  @action.bound setForceClose(close: boolean) {
    this.forceClose = close;
  }

  @action.bound async onLogin() {
    this.login = true;
  }

  @action.bound async onLogout() {
    this.refundRequests = undefined;
    this.login = false;
    this.forceClose = false;
    this.openNotification = false;
    this.canShowNotifications = false;

    this.resetNotificationItems();
  }

  @action.bound notifyLater() {
    this.removeNotificationReminderFromLocalstorage();
    this.createPendingNotification();
    this.resetFlags();
  }

  @action.bound resetNotificationItems() {
    this.notificationItems = { ...defaultNotificationItems };
  }

  private mapToNotificationItem<T>(
    items: T[],
    type: T extends BillingEntity
      ? ENotificationType.EXPIRED_PAYMENT_METHODS
      : T extends Invitation
      ? ENotificationType.INVITATION
      : ENotificationType.REFUND_REQUEST | ENotificationType.TRANSFER_TIP_REQUEST,
  ) {
    return items.map((item) => ({
      type,
      item,
    }));
  }

  checkForPendingNotification(): IPendingNotification | undefined {
    const userId = rootStore.userStore.user!.id;
    const pendingNotifications = LocalStorage.get(`${NOTIFICATIONS_LS_KEY}${userId}`);
    if (!pendingNotifications) return;
    return pendingNotifications as IPendingNotification;
  }

  private createPendingNotification() {
    const date = this.getFutureDate();
    const remindOn = { remindOn: date };

    this.addNotificationReminderToLocalstorage(remindOn);
  }

  private addNotificationReminderToLocalstorage(remindOn: { remindOn: string }) {
    const userId = rootStore.userStore.user!.id;
    LocalStorage.set(`${NOTIFICATIONS_LS_KEY}${userId}`, remindOn);
  }

  private removeNotificationReminderFromLocalstorage() {
    const userId = rootStore.userStore.user!.id;
    LocalStorage.remove(`${NOTIFICATIONS_LS_KEY}${userId}`);
  }

  private getFutureDate() {
    return moment(new Date(), 'DD-MM-YYYY')
      .add(+5, 'days')
      .toISOString();
  }

  private resetFlags() {
    this.login = false;
    this.setOpenNotification(false);
  }

  async initializeNotificationSystem() {
    const { userStore } = rootStore;
    const { authUser } = userStore;

    this.overrideOldNotifyValue();

    if (authUser.isAdmin) {
      if (userStore!.hasPermission(ACL.ISSUE_REFUND)) {
        this.getCreatedRefundRequests();
      }
      if (userStore!.hasPermission(ACL.TRANSFER_TIP)) {
        this.getCreatedTransferTipRequests();
      }
    } else {
      // Check if user owns accounts
      const { data } = await Api.core.getAccounts();
      if (data?.data?.length) {
        this.getExpiredPaymentMethods();
      }
      this.getInvitations();
    }
  }

  //TODO: remove this after December 2024
  public overrideOldNotifyValue() {
    const userId = rootStore.userStore?.user!.id;
    const ITEM_NAME = 'expiredPaymentMethods';
    const KEY = `${STORE_NAME}/${ITEM_NAME}-`;
    const oldReminder = LocalStorage.get(`${KEY}${userId}`);
    if (oldReminder) {
      this.addNotificationReminderToLocalstorage(oldReminder as IPendingNotification);
    }
    LocalStorage.remove(`${KEY}${userId}`);
  }

  public async init() {
    setInterval(() => {
      if (rootStore?.userStore?.loggedIn) {
        this.initializeNotificationSystem();
      }
    }, SYNCHRONIZE_NOTIFICATION_STORE_INTERVAL);
    this.resetFlags();
  }
}

export interface WithNotificationStore {
  notificationStore?: NotificationStore;
}
