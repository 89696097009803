import theme from 'containers/App/theme';
import React from 'react';

export const scrollbarStyles: any = {
  '&::-webkit-scrollbar': {
    display: 'none',
    width: '0 !important',
  },
  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',
};

export const noUserSelect: any = {
  '-webkit-user-select': 'none' /* Safari */,
  '-ms-user-select': 'none' /* IE 10 and IE 11 */,
  userSelect: 'none',
};

export const dynamicPadding = {
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(6.25),
    paddingRight: theme.spacing(6.25),
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
};
export const hideScrollbar = {
  '&::-webkit-scrollbar': {
    display: 'none',
    width: '0 !important',
  },
  scrollbarWidth: 'none',
} as React.CSSProperties;
