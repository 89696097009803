import React from 'react';
import { CircularProgress } from '@material-ui/core';
import AddPaymentMethod from 'components/PaymentMethodsPanel/AddPaymentMethod';
import { BillingEntity, PaymentMethodAction } from 'models';
import Api, { getErrorMsg } from 'api';
import { inject, observer } from 'mobx-react';
import { WithToastStore } from 'stores/ToastStore';
import { WithNotificationStore } from 'stores/NotificationStore';

interface IEditPaymentMethod {
  paymentMethod: BillingEntity;
  openEditPayment: boolean;
  stripeLoaded: boolean;
  editPayment: (billingEntity?: BillingEntity) => void;
}

type EditPaymentItemProps = IEditPaymentMethod & WithToastStore & WithNotificationStore;

export const EditPaymentItem = inject(
  'notificationStore',
  'toastStore',
)(
  observer(
    ({
      stripeLoaded,
      paymentMethod,
      editPayment,
      notificationStore,
      toastStore,
    }: EditPaymentItemProps) => {
      async function editPaymentMethod(sourceId: string) {
        try {
          await Api.billing.editPaymentMethod(sourceId, paymentMethod.accountId, paymentMethod.id);
          await notificationStore!.getExpiredPaymentMethods();
          editPayment(undefined);
          toastStore!.success('Payment method successfully added');
        } catch (e: any) {
          toastStore!.error(getErrorMsg(e));
          editPayment(undefined);
        }
      }

      return stripeLoaded ? (
        <AddPaymentMethod
          onSourceId={editPaymentMethod}
          onCancel={() => editPayment(undefined)}
          method={PaymentMethodAction.EDIT}
        />
      ) : (
        <CircularProgress />
      );
    },
  ),
);
