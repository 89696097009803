import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogProps,
  ButtonProps,
} from '@material-ui/core';
import Button from 'components/Button/Dialog/Button';
import { observer } from 'mobx-react';
import React from 'react';
import useStyles from './styles';

//TODO: move action properties to common properties called confirm: {...} and cancel: {...} eg. confirm: {name: ..., variant: ..., onConfirm: ...}
export interface IDialogProps extends Omit<DialogProps, 'title'> {
  open: boolean;
  title?: React.ReactNode;
  width?: React.CSSProperties['width'];
  content: React.ReactNode;
  cancelActionName?: string;
  confirmActionName?: string;
  confirmActionVariant?: ButtonProps['variant'];
  loading?: boolean;
  disabled?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}
const Dialog = observer(
  ({
    cancelActionName = 'Cancel',
    confirmActionName = 'Confirm',
    confirmActionVariant = 'contained',
    maxWidth = undefined,
    ...props
  }: IDialogProps) => {
    const { open, title, content, loading, disabled, onCancel, onConfirm, ...rest } = props;
    const width = maxWidth ? 'auto' : props.width;
    const { dialogTitle, dialogContent, dialogActions, root } = useStyles({
      width,
      fullWidth: rest.fullWidth,
    });

    return (
      <MuiDialog classes={{ paper: root }} {...rest} fullWidth maxWidth={maxWidth} open={open}>
        {title && (
          <DialogTitle className={dialogTitle} disableTypography>
            {title}
          </DialogTitle>
        )}
        <DialogContent className={dialogContent}>{content}</DialogContent>
        <DialogActions className={dialogActions}>
          {onCancel && <Button onClick={onCancel}>{cancelActionName}</Button>}
          {onConfirm && (
            <Button
              variant={confirmActionVariant}
              loading={loading}
              disabled={disabled}
              onClick={onConfirm}>
              {confirmActionName}
            </Button>
          )}
        </DialogActions>
      </MuiDialog>
    );
  },
);

export default Dialog;
