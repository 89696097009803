import Api, { ApiResponse, getErrorMsg } from 'api';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';
import { action, computed, flow, observable, toJS, makeObservable } from 'mobx';
import * as models from 'models';
import { ToastStore } from 'stores';
import RootStore from 'stores/RootStore';
import { EInvoiceItemType } from 'types';
export enum SignupStep {
  Owner,
  Account,
  Product,
  Locations,
  Coupons,
  Review,
}

const wait = (time: number) => new Promise((r) => setTimeout(r, time));
export const KIOSK_YEARLY = `KIOSK_YEARLY`;

/** A local store for persisting data throughout the account signup process */
export default class SignupStore {
  /** A reference to the root store */
  private rootStore: RootStore;
  private toastStore: ToastStore;
  constructor(rootStore: RootStore, options?: { selfSignup?: boolean; affiliateSignup?: boolean }) {
    this.selfSignup = Boolean(options && options.selfSignup);
    this.affiliateSignup = Boolean(options && options.affiliateSignup);
    this.rootStore = rootStore;
    this.toastStore = rootStore.toastStore;

    makeObservable(this);
  }
  /** Whether the user is doing a self-signup */
  @observable public selfSignup: boolean;
  /** Whether an affiliate is doing the signup */
  @observable public affiliateSignup: boolean;
  /** The owner object */
  @observable public owner?: models.User;
  /** The current step */
  @observable public currentStep = SignupStep.Owner;
  /**
   * The biggest step that the user visited, we need this for
   * clearing out some UX issues in the account step.
   */
  @observable public maxStep = SignupStep.Owner;
  /**
   * The affiliate for this account. null means there's no affiliate, undefined
   * means it's not loaded yet
   */
  @observable public accountAffiliate?: models.Affiliate | null;
  /** The newly created account */
  @observable public account?: models.Account;
  /** The cart object that we get from the API */
  @observable public cart?: models.Cart;
  /** The id of the kiosk product */
  @observable public kioskProductId?: number;
  /** The currently selected product */
  @observable public selectedProduct: null | Pick<
    models.Product,
    'id' | 'code' | 'name' | 'description'
  > = null;
  /** Whether the cart is being loaded initially */
  @observable public loadingCart = false;
  /** Whether we are currently adding a location */
  @observable public addingLocation = false;
  /** Whether we are currently deleting a location */
  @observable public deletingCartItem = false;
  /** Which promotion id is currently being applied */
  @observable public applyingPromotion?: number;
  /** Whether the process for finalizing the purchase order is under way */
  @observable public finalizingOrder = false;
  /** Whether the order is complete */
  @observable public orderFinalized = false;
  /** A list of all applicable promotions */
  @observable public promotions?: models.Promotion[];
  /** Data preloaded when we click on convert button into lead page */
  @observable public lead?: models.Lead;
  @observable public industry?: models.Industry | null;

  /**
   * The lat and long of the account, we need to store it
   * here as it's not on the account model.
   */
  @observable public accountLatLong: { lat?: number; long?: number } = {};

  /** Init method */
  @action.bound public init() {
    // If this is a self-signup process, do some init for the self sign-up
    if (this.selfSignup) {
      this.selfSignupInit();
    }
  }

  @action.bound public getSettings = flow(function* (this: SignupStore) {
    if (this.account) {
      const resp = yield Api.core.getSettings('account', this.account.id);
      const settings = resp && resp.data && resp.data.data;
      if (settings) {
        this.industry = settings.industry;
      }
    }
  });

  /** Initializes what we need for the self signup process */
  @action.bound public selfSignupInit() {
    // Set the owner to be the user if the owner isn't already present.
    if (!this.owner) {
      const user = this.rootStore.userStore.user;
      this.owner = toJS(user);
    }
  }

  /** Fetches the cart from the server */
  @action.bound public getCart = flow(function* (this: SignupStore, accountId: number) {
    try {
      this.loadingCart = true;
      const resp = yield Api.billing.getCart(accountId);
      this.cart = resp.data.data!;
      // If the cart includes affiliate data, set it as the account affiliate
      this.accountAffiliate = this.cart!.affiliate || null;
    } catch (e: any) {
      this.toastStore!.error(getErrorMsg(e));
    } finally {
      this.loadingCart = false;
    }
  });

  /** Fetches the account from the server */
  @action.bound public getAccount = flow(function* (this: SignupStore, accountId: number) {
    try {
      const resp = yield Api.core.getAccount(accountId);
      this.account = { ...this.account, ...resp.data.data };

      this.getSettings();
    } catch (e: any) {
      this.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Gets the primary owner */
  @action.bound public getOwner = flow(function* (this: SignupStore, accountId: number) {
    try {
      const resp: AxiosResponse<ApiResponse<models.Owner[]>> = yield Api.core.getAccountOwners(
        accountId,
      );
      const owner =
        resp.data.data &&
        resp.data.data.find((user) => user.owners[0] && user.owners[0].type === 'primary');
      if (!owner) {
        throw new Error(`Account doesn't have a primary owner`);
      }
      this.owner = owner;
    } catch (e: any) {
      this.toastStore!.error(getErrorMsg(e));
    }
  });

  /**
   * Gets an affiliate by userId
   */
  @action.bound public getAffiliate = flow(function* (this: SignupStore) {
    // If it's not present in the cart, that means there's no affiliate on this
    // cart so we can set it to null
    if (!this.cart || !this.cart.affiliateUserId) {
      this.setAccountAffiliate(null);
      return;
    }
    // Otherwise, fetch the affiliate
    try {
      const resp = yield Api.marketing.getAffiliateForUser(this.cart!.affiliateUserId);
      if (!isEmpty(resp.data.data)) {
        const t: Partial<models.Affiliate> = resp.data.data;
        if (!t.id) {
          this.setAccountAffiliate(null);
        } else {
          this.setAccountAffiliate(t as models.Affiliate);
        }
      }
      // Set the affiliate to null if it wasn't found
    } catch (e: any) {
      this.setAccountAffiliate(null);
    }
  });

  /**
   * Fetches all the data that we need to display steps LocationSelect and onward.
   * @param accountId The id of the account
   */
  @action.bound public initAccount = flow(function* (this: SignupStore, accountId: number) {
    // If this is an affiliate signup, we can set the accountAffiliate
    // to the current affiliate
    if (this.affiliateSignup) {
      this.accountAffiliate = this.rootStore.userStore!.affiliate;
    }
    yield this.getCart(accountId);
    yield Promise.all([
      // We need to get the cart before we can fetch the affiliate, because
      // the cart contains the affiliate info
      this.getAccount(accountId),
      this.getOwner(accountId),
      this.getPromotions(accountId),
    ]);
  });
  /** Adds a cart item */
  @action.bound public addCartItem = flow(function* (
    this: SignupStore,
    {
      locationId,
      kioskCount,
      productId,
      shippingAddressId,
      invoiceItemType,
    }: {
      locationId: number;
      kioskCount: number;
      productId: number;
      shippingAddressId?: number;
      invoiceItemType: EInvoiceItemType;
    },
  ) {
    try {
      const account = this.account!;
      // Add the item to the cart
      const cartResponse: AxiosResponse<ApiResponse<models.Cart>> = yield Api.billing.addToCart({
        accountId: account.id,
        productId,
        quantity: kioskCount,
        locationId,
        shippingAddressId,
        invoiceItemType,
      });
      // Set the cart to the API's response
      this.cart = cartResponse.data.data!;
    } catch (e: any) {
      this.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Adds a cart item */
  @action.bound public addShippingAddress = flow(function* (
    this: SignupStore,
    locationId: number,
    shippingAddress: Partial<models.ShippingAddress>,
  ) {
    try {
      // Add the item to the cart
      yield Api.core.createShippingAddress(locationId, shippingAddress);
      // Set the cart to the API's response
    } catch (e: any) {
      this.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Adds a new location to the cart */
  @action.bound public addLocation = flow(function* (
    this: SignupStore,
    location: Partial<models.Location>,
    kioskCount: number,
    productId: number,
    invoiceItemType: EInvoiceItemType,
    shippingAddress?: Partial<models.ShippingAddress>,
  ) {
    try {
      this.addingLocation = true;
      // Call the API endpoint to create the location
      const resp: AxiosResponse<ApiResponse<models.Location[]>> = yield Api.core.createLocation(
        location,
      );
      const createdLocations = resp.data.data;
      if (!createdLocations || !createdLocations[0]) {
        this.toastStore!.error('Invalid server response');
        return;
      }
      // Remember the newly created location
      const createdLocation = createdLocations[0]!;

      if (shippingAddress?.address) {
        // Add shipping address
        const { data } = yield Api.core.createShippingAddress(createdLocation.id, shippingAddress);
        if (data && data.data && data.data.length) {
          shippingAddress.id = data.data[0].id;
        }
      }

      // Add it to the cart
      yield this.addCartItem({
        locationId: createdLocation.id,
        kioskCount,
        productId,
        shippingAddressId: shippingAddress?.id,
        invoiceItemType,
      });
      return createdLocation;
    } catch (e: any) {
      this.toastStore!.error(getErrorMsg(e));
    } finally {
      this.addingLocation = false;
    }
  });
  /** Deletes a specific cart item */
  @action.bound public deleteCartItem = flow(function* (this: SignupStore, item: models.CartItem) {
    try {
      this.deletingCartItem = true;
      // Delete the cart item
      const resp = yield Api.billing.deleteFromCart(this.account!.id, item.id);
      this.cart = resp.data.data;
      yield Api.core.deleteLocation(item.location!.id);
    } catch (e: any) {
      this.toastStore!.error(getErrorMsg(e));
    } finally {
      this.deletingCartItem = false;
    }
  });

  /** Updates the cart on the server */
  @action.bound public updateCartItem = flow(function* (
    this: SignupStore,
    itemId: number,
    cartItem: Partial<models.CartItem>,
  ) {
    try {
      const { data }: AxiosResponse<ApiResponse<models.Cart>> = yield Api.billing.updateCartItem(
        this.cart!.accountId,
        itemId,
        cartItem,
      );
      // Set the cart to the API's response
      this.cart = data.data;
      this.toastStore!.success('Invoice type changed');
    } catch (e: any) {
      this.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Fetches all promotions that are applicable for this sale */
  @action.bound public getPromotions = flow(function* (this: SignupStore, accountId: number) {
    try {
      // If we're doing the self-signup, the user can't fetch all promotions,
      // but have to enter their codes manually.
      if (this.selfSignup) {
        if (this.cart) {
          this.promotions = this.cart.promotions.map((cartPromotion) => ({
            ...cartPromotion.promotion,
            isApplied: true,
          }));
        }
        return;
      }
      const resp = yield Api.billing.getCartPromotions(accountId);
      this.promotions = resp.data.data;
    } catch (e: any) {
      this.toastStore!.error(getErrorMsg(e));
    }
  });

  /**
   * Adds a promotion as a possible applicable promotion and
   * adds it to the cart if possible.
   */
  @action.bound public addPromotion = flow(function* (
    this: SignupStore,
    promotion: models.Promotion,
  ) {
    // If the list of promotions doesn't exist, create it
    if (this.promotions === undefined) {
      this.promotions = [];
    }
    // Check if the promotion exists in the list of promotions
    if (this.promotions.find((p) => p.id === promotion.id)) {
      return;
    }
    yield this.togglePromotion(promotion);
  });

  /**
   * Adds or removes a promotion from the cart.
   */
  @action.bound public togglePromotion = flow(function* (
    this: SignupStore,
    promotion: models.Promotion,
  ) {
    if (this.applyingPromotion) return;
    try {
      this.applyingPromotion = promotion.id;
      let resp: AxiosResponse<ApiResponse<models.Cart>>;
      if (this.activePromotionIds.includes(promotion.id)) {
        // Get the cartPromotion id for this promotion
        const cartPromotion = this.cart!.promotions.find(
          (cartPromotion) => cartPromotion.promotionId === promotion.id,
        );
        if (!cartPromotion) {
          throw new Error('Promotion does not exist in cart');
        }
        resp = yield Api.billing.unapplyPromotion(this.account!.id, cartPromotion.id);
      } else {
        resp = yield Api.billing.applyPromotion(this.account!.id, promotion.id);
      }
      this.cart = resp.data.data!;
      // If promotion was applied successfully to the cart and we've added
      // a different affiliate, we should also update the affiliate ui value
      if (this.cart.affiliate) {
        this.accountAffiliate = this.cart.affiliate;
      } else {
        this.accountAffiliate = null;
      }
      yield this.getPromotions(this.cart!.accountId);
      return promotion;
    } catch (e: any) {
      this.toastStore!.error(getErrorMsg(e));
      return false;
    } finally {
      this.applyingPromotion = undefined;
    }
  });

  /**
   * Adds the owner to the first location if the owner is supposed
   * to be a talent as well (the checkbox in the first step was checked)
   */
  @action.bound public addOwnerToLocation = flow(function* (this: SignupStore, locationId: number) {
    try {
      yield Api.core.addUserToLocation(this.owner!.id, locationId);
    } catch (e: any) {
      this.toastStore.error(getErrorMsg(e));
    }
  });

  /**
   * Creates conversions for each location. Conversions are created only for account
   * signup's that are directly associated with affiliate or signup's that are initiated
   * via leads conversion functionality (they have lead id and affiliate or campaign id)
   */
  @action.bound public createConversions = flow(function* (this: SignupStore) {
    // If current acc signup was not initiated via a lead and
    // has no affiliate associated do not create conversion
    if (!this.accountAffiliate && !this.lead) return;

    const affiliateId = this.accountAffiliate ? this.accountAffiliate.id : undefined;
    const leadId = this.lead ? this.lead.id : undefined;
    const campaignId = this.lead && this.lead.campaign ? this.lead.campaign.id : undefined;
    const commissionFee =
      this.lead && this.lead.affiliate ? Number(this.lead.affiliate.commissionFee) : undefined;

    const additionalConversionPostData = {
      affiliateId: affiliateId,
      leadId: leadId,
      campaignId: campaignId,
      amount: commissionFee,
    };

    yield Promise.all(
      this.cart!.items.map((item) => {
        // If there is a promotion present for the affiliate, we'll send its id
        const promotion =
          item.promotions &&
          item.promotions.find((promotion) => promotion.affiliateId === affiliateId);
        const locationId = item.location.id;
        const promotionId = promotion && promotion.id;
        return Api.marketing.createConversion({
          locationId,
          promotionId,
          ...additionalConversionPostData,
        });
      }),
    );
    // Go over each location and
    yield Promise.resolve();
  });

  /** Finalizes the order by adding owner to location, creating conversions and sending a purchase order */
  @action.bound public finalizeOrder = flow(function* (this: SignupStore) {
    try {
      this.finalizingOrder = true;
      yield Promise.all([
        // Create the conversions
        this.createConversions().then(this.finalizeCart),
        // Make this last for a bit so that it looks impressive
        wait(5000),
      ]);
      // Send the purchase email to the customer
      this.orderFinalized = true;
    } catch (e: any) {
      this.toastStore.error(getErrorMsg(e));
    } finally {
      this.finalizingOrder = false;
    }
  });

  @action.bound public finalizeSelfSignup = flow(function* (this: SignupStore) {
    try {
      yield this.createConversions();
      this.orderFinalized = true;
    } catch (e: any) {
      this.toastStore.error(getErrorMsg(e));
    } finally {
      this.finalizingOrder = false;
    }
  });

  /** Sends a purchase order to the owner */
  @action.bound public finalizeCart = flow(function* (this: SignupStore) {
    yield Api.billing.finalizeCart(this.account!.id, this.owner!.email, this.owner!.firstName!);
  });

  /** Goes to the locations step */
  @action.bound public goToLocationsStep() {
    this.currentStep = SignupStep.Locations;
  }

  /** Sets the owner */
  @action.bound public setOwner(owner: models.User) {
    this.owner = owner;
  }

  /** Sets the account affiliate */
  @action.bound public setAccountAffiliate(a: models.Affiliate | null) {
    // If acc affiliate was set before and newly selected acc affiliate is not the same
    // as previous, then remove any active promotions owned by previous affiliate
    if (this.accountAffiliate && this.accountAffiliate !== a) {
      // Loop through all activated promotions ...
      for (const index in this.activePromotionIds) {
        const activePromotionId = this.activePromotionIds[index];
        if (this.promotions) {
          // ... for each activated promotion, loop through available promotions ...
          for (const promotion of this.promotions) {
            // ... and if that promotion belongs to affiliate that is being removed ...
            if (
              promotion.affiliateId === this.accountAffiliate.id &&
              promotion.id === activePromotionId
            ) {
              // ... toggle activated promotions (remove the promotion)
              this.togglePromotion(promotion);
            }
          }
        }
      }
    }
    this.accountAffiliate = a;
    this.getPromotions(this.cart!.accountId);
    Api.billing.updateCart(this.cart!.accountId, { affiliateUserId: a ? a.userId : null });
  }
  /** Sets the account */
  @action.bound public setAccount(a: models.Account) {
    this.account = a;
  }
  /** Sets the step */
  @action.bound public setStep(s: SignupStep) {
    this.currentStep = s;
    if (this.currentStep > this.maxStep) {
      this.maxStep = s;
    }
  }
  /** Sets the cart to the provided value */
  @action.bound public setCart(cart: models.Cart) {
    this.cart = cart;
  }

  /** Sets the product to the provided value */
  @action.bound public setSelectedProduct(p: models.Product) {
    this.selectedProduct = p;
  }

  /** The display name for the affiliate */
  @computed public get affiliateName(): string | undefined {
    const user = this.accountAffiliate && this.accountAffiliate.user;
    if (!user) return undefined;
    return `${user.firstName} ${user.lastName} (${this.accountAffiliate!.code})`;
  }
  /** The address for the account */
  @computed public get accountAddress() {
    if (!this.account) return undefined;
    const { address, zip, state, city, address2 } = this.account;
    return `${address}, ${city},${address2 ? ` ${address2},` : ''} ${zip} ${state}`;
  }
  /** The number of locations */
  @computed public get locationCount(): number {
    if (!this.cart) return 0;
    return this.cart.items.length;
  }
  /** Whether to show a spinner over the cart preview */
  @computed public get cartInProgress(): boolean {
    return (
      this.addingLocation ||
      this.deletingCartItem ||
      this.loadingCart ||
      Boolean(this.applyingPromotion)
    );
  }
  /** Whether to show the cart preview */
  @computed public get showCart() {
    const cart = this.cart;
    return cart && cart.items && cart.items.length > 0;
  }

  @computed public get activePromotionIds(): number[] {
    if (!this.cart) return [];
    if (this.cart.items.length === 0) return [];
    return this.cart.promotions.map((cartPromotion) => cartPromotion.promotion.id);
  }

  @computed public get activePromotionTypes(): models.PromotionType[] {
    if (!this.cart) return [];
    if (this.cart.items.length === 0) return [];
    const promotionTypeSet = new Set(
      this.cart.promotions.map((cartPromotion) => cartPromotion.type),
    );
    return [...promotionTypeSet.values()];
  }

  @computed public get promotionsAnnotated():
    | (models.Promotion & {
        active?: boolean;
        disabled?: boolean;
      })[]
    | undefined {
    if (!this.promotions) return [];
    if (!this.cart) return [];
    if (this.cart.items.length === 0) return this.promotions;
    return this.promotions.map((promotion) => ({
      ...promotion,
      active: Boolean(this.activePromotionIds.find((id) => id === promotion.id)),
      disabled: !promotion.canBeApplied && !promotion.isApplied,
    }));
  }

  /**
   * Whether all the data that we need for steps when the owner and account
   * are already created (steps 3, 4 and 5).
   */
  @computed public get accountReady(): boolean {
    return (
      Boolean(this.account) &&
      Boolean(this.cart) &&
      Boolean(this.owner) &&
      // The accountAffiliate can be null if the sale has no affiliate,
      // but it is undefined while the affiliate is being loaded
      this.accountAffiliate !== undefined
    );
  }

  /** The product id for the first cart item */
  @computed public get cartProductId(): number | undefined {
    if (this.cart && this.cart.items && this.cart.items.length && this.cart.items.length > 0) {
      return this.cart.items[0].product.id;
    }
    return undefined;
  }

  /** Whteher this signup is coming from an admin */
  @computed public get adminSignup(): boolean {
    return !this.selfSignup;
  }
}
