import UserStore from './UserStore';
import RouterStore from './RouterStore';
import UiStore from './UiStore';
import ToastStore from './ToastStore';
import ModalStore from './ModalStore';
import AnalyticsStore from './AnalyticsStore';
import PayoutSourceStore from './PayoutSourceStore';
import EnrollStore from './EnrollStore';
import AccountInfoStore from './AccountInfo';
import SettingStore from './SettingStore';
import NotificationStore from './NotificationStore';
import StationsStore from './StationsStore';
import BankAccountWizzardStore from './BankAccountWizzardStore';
import MfaStore from './MfaStore';
import MfaDialogStore from './MfaDialogStore';
import GlobalDialogStore from './GlobalDialogStore';

/**
 * The root store initializes all other stores and holds references
 * to them. When constructing the other stores, it passes itself
 * as an argument to their constructor. This way, stores can communicate
 * with each other via the root store.
 */
export default class RootStore {
  public userStore: UserStore;
  public routerStore: RouterStore;
  public uiStore: UiStore;
  public mfaStore: MfaStore;
  public globalDialogStore: GlobalDialogStore;
  public mfaDialogStore: MfaDialogStore;
  public toastStore: ToastStore;
  public modalStore: ModalStore;
  public analyticsStore: AnalyticsStore;
  public payoutSourceStore: PayoutSourceStore;
  public enrollStore: EnrollStore;
  public accountInfoStore: AccountInfoStore;
  public settingStore: SettingStore;
  public notificationStore: NotificationStore;
  public stationsStore: StationsStore;
  public bankAccountWizzardStore: BankAccountWizzardStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.routerStore = new RouterStore(this);
    this.uiStore = new UiStore(this);
    this.mfaStore = new MfaStore(this);
    this.globalDialogStore = new GlobalDialogStore(this);
    this.mfaDialogStore = new MfaDialogStore(this);
    this.toastStore = new ToastStore(this);
    this.modalStore = new ModalStore(this);
    this.analyticsStore = new AnalyticsStore(this);
    this.payoutSourceStore = new PayoutSourceStore(this);
    this.enrollStore = new EnrollStore(this);
    this.accountInfoStore = new AccountInfoStore(this);
    this.settingStore = new SettingStore(this);
    this.notificationStore = new NotificationStore(this);
    this.stationsStore = new StationsStore(this);
    this.bankAccountWizzardStore = new BankAccountWizzardStore(this);

    this.enrollStore.init();
    this.userStore.init();
    this.routerStore.init();
    this.mfaStore.init();
    this.globalDialogStore.init();
    this.mfaDialogStore.init();
    this.uiStore.init();
    this.toastStore.init();
    this.modalStore.init();
    this.payoutSourceStore.init();
    this.accountInfoStore.init();
    this.settingStore.init();
    this.notificationStore.init();
    this.stationsStore.init();
    this.bankAccountWizzardStore.init();
  }
}
