import React from 'react';
import { useEffect, useRef } from 'react';
import { calculateAndSetMinWidth, move, startDragging, stopDragging } from './helpers';
import { TTabBarProps } from './TabBar';

const useTabBar = ({ tabs }: Pick<TTabBarProps, 'tabs'>) => {
  const buttonGroupRef = useRef<any>();
  const itemRefs = useRef(tabs.map(() => React.createRef<HTMLButtonElement>()));

  const getButtonBaseRef = (index: number) => {
    return itemRefs.current[index];
  };
  useEffect(() => {
    calculateAndSetMinWidth(itemRefs);
  }, []);

  const draggingProps = {
    onMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      startDragging(e, buttonGroupRef.current!);
    },
    onMouseMove: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      move(e, buttonGroupRef.current!, buttonGroupRef);
    },

    onMouseUp: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      stopDragging(e, buttonGroupRef);
    },
  };

  return { draggingProps, buttonGroupRef, getButtonBaseRef };
};

export default useTabBar;
