import {
  Box,
  Grid,
  Link,
  Typography,
  IconButton,
  Tooltip,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { action, computed, flow, observable, makeObservable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
  ReactStripeElements,
} from 'react-stripe-elements';
import { inject, WithUserStore, WithNotificationStore } from 'stores';
import StripeElementWrapper from './StripeElementWrapper';
import styles from './styles';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { BillingEntity, Cart } from 'models';
import { PaymentMethodItem } from '../../components/PaymentMethodsPanel';
import { Skeleton } from '@mui/material';
import { Close } from 'mdi-material-ui';
import Button from 'components/Button/Button';
import theme from 'containers/App/theme';
import OutlinedInput from 'components/Input/OutlinedInput';
import { RouteComponentProps } from 'react-router-dom';
import { paths } from 'routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

interface CheckoutFormProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    WithUserStore,
    WithNotificationStore {
  onPayment: (sourceId: Record<string, string>) => void;
  billingEntities?: BillingEntity[];
  cart?: Cart;
  isOwner: boolean;
  onOpenLogin: (status: boolean) => void;
}

/**
 * Renders a complete checkout form for inputting credit card data.
 * When the data is submitted, calls the onPayment function with the source.
 */

export interface RadioCompProps {
  billingEntity: BillingEntity;
  isAdmin?: boolean;
}

const RadioComponent = ({ billingEntity, isAdmin }: RadioCompProps) => {
  return (
    <Box width="100%">
      <PaymentMethodItem key={billingEntity.id} isAdmin={isAdmin} flexSecondary>
        {billingEntity}
      </PaymentMethodItem>
    </Box>
  );
};

@inject('userStore', 'notificationStore')
@observer
class CheckoutForm extends React.Component<
  CheckoutFormProps & ReactStripeElements.InjectedStripeProps
> {
  constructor(props: CheckoutFormProps & ReactStripeElements.InjectedStripeProps) {
    super(props);
    makeObservable(this);
    reaction(
      () => this.props.billingEntities,
      (billingEntities) => {
        if (billingEntities && billingEntities.length > 0) {
          const primary = billingEntities.find(({ isPrimary }) => isPrimary);
          this.payMethodSelected = primary ? JSON.stringify(primary) : '';
          const activeBillingEntities = [...billingEntities].sort(
            (a, b) => Number(b.isPrimary) - Number(a.isPrimary),
          );
          this.isNewPayMethod = false;
          this.heightRadio = billingEntities.length > 3 ? '270px' : 'auto';

          if (this.props.notificationStore?.expiredPaymentMethods?.length) {
            const expiredMethods: string[] =
              this.props.notificationStore?.expiredPaymentMethods.map(
                ({ id, code, paymentMethod }) => {
                  return `${id}-${code}-${paymentMethod.id}`;
                },
              );

            this.billingEntities = activeBillingEntities.map((be) => {
              return {
                isExpired: expiredMethods.includes(`${be.id}-${be.code}-${be.paymentMethod.id}`),
                ...be,
              };
            });
          } else {
            this.billingEntities = activeBillingEntities;
          }
        } else {
          this.billingEntities = [];
        }
      },
    );
  }

  @observable private billingEntities?: BillingEntity[];

  @observable private expiredPaymentMethods: string[] = [];

  @observable private isNewPayMethod = true;

  @observable private payMethodSelected = '';
  @observable private heightRadio = 'auto';

  /** Name on card */
  @observable public name = {
    value: '',
    touched: false,
  };

  /** Zip number */
  @observable public zip = {
    value: '',
    touched: false,
  };

  @action.bound private updateInputValue(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    this.payMethodSelected = e.target.value;
  }

  /** Handles the name on card update */
  @action.bound public updateName(e: React.ChangeEvent<HTMLInputElement>) {
    this.name.value = e.target.value;
  }

  /** Mark the name as touched on blur */
  @action.bound public handleNameBlur() {
    if (this.name.value !== '') {
      this.name.touched = true;
    }
  }

  /** Mark the name as touched on blur */
  @action.bound public handleZipBlur() {
    if (this.zip.value !== '') {
      this.zip.touched = true;
    }
  }

  /** Updates the zip field */
  @action.bound public updateZip(e: React.ChangeEvent<HTMLInputElement>) {
    this.zip.value = e.target.value;
  }

  /** Marks the fields that we're controlling as touched */
  @action.bound public markFormTouched() {
    this.zip.touched = true;
    this.name.touched = true;
  }

  /** Submits the form */
  @action.bound public submit = flow(function* (this: CheckoutForm) {
    // Mark the form as touched and try to create a source.
    this.markFormTouched();
    const resp = yield this.props.stripe!.createSource({
      type: 'card',
      currency: 'USD',
      owner: { name: this.name.value, address: { postal_code: this.zip.value } },
    });
    // If the response has a source, call the onPayment function with it
    if (resp.source) {
      this.props.onPayment({ paymentSourceId: resp.source.id });
    }
  });

  @computed public get nameError() {
    return this.name.touched && this.name.value.length < 1 && `The card holder's name is required`;
  }

  @computed public get zipError() {
    return this.zip.touched && !/^[0-9]{5}(?:-[0-9]{4})?$/.test(this.zip.value) && 'Invalid zip';
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (this.isNewPayMethod) {
      this.submit();
    } else {
      const cardSeleted = JSON.parse(this.payMethodSelected);
      this.props.onPayment({ paymentMethodId: `${cardSeleted.paymentMethod.id}` });
    }
  };

  handleTabMethodPay = (status: boolean) => {
    this.isNewPayMethod = status;
  };

  redirectToSignIn = () => {
    this.props.history.push({
      pathname: paths.signIn(),
      search: `?redirect=${this.props.location.pathname}`,
    });
  };

  render() {
    const classes = this.props.classes;
    const showIconButton =
      this.isNewPayMethod && this.billingEntities && this.billingEntities?.length > 0;

    return (
      <form onSubmit={this.handleSubmit}>
        <Box
          className="checkout"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between">
          <Box
            pb={6}
            display="flex"
            justifyContent={showIconButton ? 'space-between' : 'center'}
            alignItems="center">
            <Typography variant="h5" component="h1">
              Pay with card
            </Typography>

            {showIconButton && (
              <Tooltip title={'Close'} placement="top" enterDelay={500} key={'Add'}>
                <IconButton onClick={() => this.handleTabMethodPay(false)}>
                  <Close fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Grid container spacing={2}>
            {this.props.isOwner ? (
              <Grid item xs={12}>
                <Box display="flex" style={{ gap: '1rem' }}>
                  <Skeleton animation="wave" variant="circular" width={40} height={40} />
                  <Box>
                    <Skeleton animation="wave" width={100} />
                    <Skeleton animation={false} />
                  </Box>
                </Box>
              </Grid>
            ) : (
              <>
                {!this.isNewPayMethod && this.billingEntities && this.billingEntities.length > 0 && (
                  <Box width="100%">
                    <Box>
                      <FormControl
                        className={classes.checkoutCardsForm}
                        component="fieldset"
                        style={{
                          height: `${this.heightRadio}`,
                        }}>
                        <RadioGroup value={this.payMethodSelected} onChange={this.updateInputValue}>
                          {this.billingEntities.map((billingEntity) => (
                            <Box display="flex" key={billingEntity.id}>
                              <FormControlLabel
                                key={billingEntity.id}
                                value={JSON.stringify(billingEntity)}
                                control={<Radio color="primary" />}
                                label={undefined}
                                disabled={billingEntity.isExpired ? billingEntity.isExpired : false}
                              />
                              <RadioComponent
                                isAdmin={this.props.userStore?.isAdmin}
                                billingEntity={billingEntity}
                              />
                            </Box>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    {!this.isNewPayMethod && (
                      <Box mt={3} pr={1} display="flex" justifyContent={'flex-end'}>
                        <Button
                          className={classes.addPaymentMethodButton}
                          variant="text"
                          onClick={() => this.handleTabMethodPay(true)}
                          startIcon={
                            <FontAwesomeIcon style={{ height: 16, width: 16 }} icon={faPlus} />
                          }>
                          Add payment method
                        </Button>
                      </Box>
                    )}
                  </Box>
                )}
                {this.isNewPayMethod && (
                  <>
                    <Grid item xs={12}>
                      <OutlinedInput
                        value={this.name.value}
                        onChange={this.updateName}
                        error={Boolean(this.nameError)}
                        helperText={this.nameError}
                        onBlur={this.handleNameBlur}
                        autoFocus
                        label="Name on card"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StripeElementWrapper label="Card number" component={CardNumberElement} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <StripeElementWrapper label="Exp" component={CardExpiryElement} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <StripeElementWrapper label="CVC" component={CardCVCElement} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <OutlinedInput
                        value={this.zip.value}
                        onBlur={this.handleZipBlur}
                        error={Boolean(this.zipError)}
                        helperText={this.zipError}
                        onChange={this.updateZip}
                        label="Zip"
                        InputProps={{ inputProps: { maxLength: 5 } }}
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
            <Grid item xs={12}>
              {this.isNewPayMethod && !this.props.userStore?.loggedIn && this.props.cart?.ecc && (
                <Box mt={2}>
                  <Box mb={2}>
                    <Typography align="center" style={{ color: theme.palette.text.secondary }}>
                      Already a member? Sign In to select an existing card.
                    </Typography>
                  </Box>
                  <Button
                    type="button"
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={this.redirectToSignIn}>
                    Sign In
                  </Button>
                </Box>
              )}
              <Box mt={3}>
                <Button
                  style={{ marginTop: 0 }}
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained">
                  Complete order
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" className={classes.smallText}>
                By completing the order you agree to the Tippy
                <Link href="https://www.meettippy.com/terms-of-service/">
                  &nbsp; Terms of Service
                </Link>
                &nbsp; and
                <Link href="https://www.meettippy.com/privacy-policy">&nbsp; Privacy Policy</Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </form>
    );
  }
}

export default withStyles(styles)(injectStripe(CheckoutForm));
