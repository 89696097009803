import { CircularProgress } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import OutlinedInput from 'components/Input/OutlinedInput';
import debounce from 'lodash/debounce';
import { action, flow, observable, runInAction, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

export type CancellablePromise<T> = Promise<T> & { cancel(): void };
export interface AutocompleteSearchProps<T> {
  fetch: (string: string) => Promise<T[]>;
  getOptionLabel: (t?: T) => string;
  getOptionSelected?: (option: T, value: T) => boolean;
  onChange: (t: T | null) => void;
  onInput?: (i: string) => void;
  renderOption?: (t: T) => React.ReactNode;
  onClose?: () => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: React.RefObject<any>;
  icon?: (props: SvgIconProps) => JSX.Element;
  value?: T | null;
  placeholder?: string;
  label?: string;
  error?: boolean;
  helperText?: React.ReactNode | null;
  hideIcon?: boolean;
  freeSolo?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  debounceTime?: number;
  className?: string;
  InputProps?: { disableUnderline?: boolean; className?: string; dataCy?: string };
  clearSelection?: { onClear?: () => void; clearDelay: number };
  dataCy?: string;
}

/**
 * An autocomplete text box for searching any kind of dataset.
 * @example
 * // It's suggested to use this syntax to input the type parameter
 * class UserSearch extends AutocompleteSearch<User> {};
 * // Once that's done, you can use it like so:
 * <UserSearch
 *   fetch={fetchUsers}
 *   onChange={user => console.log('got new user', user)}
 *   getOptionLabel={user => (user && aff.firstName) || ''}
 *   placeholder="Enter name, email or nickname"
 * />
 */
@observer
class AutocompleteSearch<T> extends React.Component<AutocompleteSearchProps<T>> {
  static defaultProps = {
    debounceTime: 1000,
  };

  constructor(props: AutocompleteSearchProps<T>) {
    super(props);
    makeObservable(this);
  }

  /** The fetch promise */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private fetchPromise?: CancellablePromise<any>;

  /** The search text */
  @observable public searchText = '';

  /** The array of results */
  @observable public results: T[] = [];

  /** Whether results are being loaded */
  @observable public loading = false;

  /** Whether the search is open */
  @observable public searchOpen = false;

  /** Key is updated whenever autocomplete component needs to reset */
  @observable public resetKey = Date.now();

  /** Calls props.fetch, but in a flow context, so that we get a cancellable promise */
  @action.bound public cancellableFetch = flow(function* (this: AutocompleteSearch<T>) {
    return yield this.props.fetch(this.searchText);
  });

  /** Updates the search text and triggers the fetching */
  @action.bound public updateSearchText(e: React.ChangeEvent<HTMLInputElement>) {
    // In any case we want to set the search text, clear previous results as they
    // are not relevant anymore and cancel the previous promise for fetching the
    // data, if it exists.
    this.searchText = e.target.value;
    this.results = [];
    this.fetchPromise && this.fetchPromise.cancel();
    // If the search text is not empty, do a debounced fetch, but pretend
    // that we're fetching right away
    if (this.searchText.length > 0) {
      this.loading = true;
      this.fetchDebounced();
      // If the query is empty, stop the pending debounced fetch and set
      // loading to false immediately
    } else {
      this.loading = false;
      this.fetchDebounced.cancel();
    }
  }

  /** Fetches the search data */
  @action.bound public fetch = () => {
    // Call props.fetch that's been converted to a cancellable form
    this.fetchPromise = this.cancellableFetch();
    // We use catch here because try/catch blocks were giving some
    // weird behavior and not catching
    this.fetchPromise
      .catch((e) => {
        // If this wasn't the result of a cancelled flow, throw
        // an error, otherwise do nothing
        if (e.message !== 'FLOW_CANCELLED') {
          throw e;
        }
      })
      .then((resp) => {
        runInAction(() => {
          // Once we have the data, set the results to it. Weirdly, the data
          // is sometimes undefined even if the function always returns defined
          // values, so just do nothing if it's undefined, that works OK
          if (resp !== undefined) {
            this.results = resp;
            this.loading = false;
          }
        });
      });
  };
  /** The debounced version of the fetch */
  public fetchDebounced = debounce(this.fetch, this.props.debounceTime);

  /** Opens the search box */
  @action.bound public openSearch() {
    // Set search open to true and call fetch and immediately
    // flush it. That's like calling the un-debounced version,
    // only any previously waiting debounced versions are flushed.
    this.searchOpen = true;
    this.loading = true;
    this.fetchDebounced();
    this.fetchDebounced.flush();
  }

  /** Closes the search box */
  @action.bound public closeSearch() {
    // Cancel both the debounced function and any fetches
    // that are currently going on
    this.fetchDebounced.cancel();
    this.fetchPromise && this.fetchPromise.cancel();
    // Reset the state
    this.results = [];
    this.loading = false;
    this.searchOpen = false;
    // Call the callback, if provided
    this.props.onClose && this.props.onClose();
  }

  /** Handle change in the Autocomplete component */
  @action.bound public handleOnChange(e: React.ChangeEvent<{}>, v: string | T | null) {
    if (!v) {
      this.searchText = '';
    }
    if (v !== undefined && typeof v !== 'string') {
      this.props.onChange(v);
    }
    if (this.props.clearSelection) {
      this.clear();
    }
  }

  clear = debounce(this.clearSearch, this.props.clearSelection?.clearDelay);

  @action.bound public clearSearch() {
    this.resetKey = Date.now();
    this.searchText = '';
    const clearSelection = this.props.clearSelection;
    if (clearSelection && clearSelection.onClear) {
      clearSelection.onClear();
    }
  }

  /** Handle input change in the Autocomplete component */
  @action.bound public handleOnInputChange(e: React.ChangeEvent<{}>, v: string) {
    this.props.onInput && this.props.onInput(v);
  }

  render() {
    const {
      getOptionLabel,
      getOptionSelected,
      renderOption,
      error,
      helperText,
      label,
      placeholder,
      freeSolo,
      value,
      autoFocus,
      disabled,
      InputProps,
      onBlur,
      onFocus,
      className,
      dataCy,
    } = this.props;
    const disableUnderline = InputProps && InputProps.disableUnderline;

    return (
      <Autocomplete
        key={this.resetKey}
        className={className}
        loading={this.loading}
        options={this.results}
        filterOptions={(options) => options}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        renderOption={renderOption}
        open={this.searchOpen}
        onOpen={this.openSearch}
        onClose={this.closeSearch}
        onInputChange={this.handleOnInputChange}
        value={value}
        ref={this.props.ref}
        freeSolo={freeSolo}
        disabled={disabled}
        onChange={this.handleOnChange}
        renderInput={(params) => (
          <OutlinedInput
            {...params}
            dataCy={dataCy}
            error={error}
            helperText={helperText}
            label={label}
            disabled={disabled}
            placeholder={placeholder}
            onBlur={onBlur}
            autoFocus={autoFocus}
            onFocus={onFocus}
            onChange={this.updateSearchText}
            InputProps={{
              classes: {
                input: InputProps?.className,
              },
              ...params.InputProps,
              disableUnderline,
              endAdornment: (
                <React.Fragment>
                  {this.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {!disabled && params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            fullWidth
          />
        )}
      />
    );
  }
}

export default AutocompleteSearch;
