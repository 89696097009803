import { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: 570,
    },
    pencilIcon: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  });
});

export default useStyles;
