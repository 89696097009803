import { Theme, createStyles, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    addLicensesDialog: {
      width: 800,
    },
    textSecondary: {
      color: theme.palette.grey[500],
    },
    noDataText: {
      color: theme.palette.text.secondary,
    },
    addLocationDisabled: {
      borderColor: theme.palette.common.black,
    },
    dialogActions: {
      padding: theme.spacing(2),
    },
    faInfoCircle: {
      color: theme.palette.common.black,
      width: '24px',
      height: '24px',
    },
  });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    addLicensesDialog: {
      width: 800,
    },
    textFirst: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20,29',
    },
    textSecondary: {
      color: theme.palette.grey[600],
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '17,75',
    },
    textTertiary: {
      color: theme.palette.grey[400],
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '17,75',
      fontStyle: 'italic',
    },
  }),
);
