import React from 'react';
import { Box, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import useUserSection from './useUserSection';
import useStyles from './styles';
import UserProfile from 'containers/DashboardLayout/UserProfile/UserProfile';
import clsx from 'clsx';
import { paths } from 'routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faArrowRightToBracket,
  faCircleUser,
} from '@fortawesome/pro-regular-svg-icons';
import { CompanyCode } from 'containers/DashboardLayout/CompanyCode/CompanyCode';
import { observer } from 'mobx-react';
import { rootStore } from 'containers/App/App';

interface IUserSectionProps {
  drawerOpen: boolean;
}
const UserSection = ({ drawerOpen }: IUserSectionProps) => {
  const { wrapper, menu, menuClose } = useStyles({ open: drawerOpen });
  const {
    mobileView,
    handleUserMenuClick,
    handleUserMenuClose,
    userMenuButton,
    logout,
    animation,
    anchorReference,
    anchorPosition,
  } = useUserSection();

  const handleMyAccountClick = () => {
    mobileView && rootStore.uiStore.closeDrawer();
  };

  const addCloseMenuClass = mobileView && !userMenuButton;
  return (
    <>
      <Box className={clsx(wrapper, animation)} data-cy="user-menu-link">
        <UserProfile onClick={handleUserMenuClick} />
        {mobileView && <CompanyCode />}
      </Box>

      <Menu
        id="user-menu"
        className={clsx({ [menu]: mobileView, [menuClose]: addCloseMenuClass })}
        anchorEl={userMenuButton}
        anchorReference={anchorReference}
        anchorPosition={anchorPosition}
        open={Boolean(userMenuButton)}
        onClose={handleUserMenuClose}
        style={{ display: 'flex', justifyContent: 'center' }}>
        <MenuItem
          onClick={handleMyAccountClick}
          component={RouterLink}
          to={paths.myAccount().root()}>
          <ItemContent icon={faCircleUser} label={'My account'} />
        </MenuItem>
        <MenuItem onClick={logout}>
          <ItemContent icon={faArrowRightToBracket} label={'Log Out'} />
        </MenuItem>
      </Menu>
    </>
  );
};

const ItemContent = ({ icon, label }: { icon: IconDefinition; label: string }) => {
  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box display={'flex'} alignItems={'center'} mr={1} width={30} height={24}>
        <FontAwesomeIcon style={{ height: '100%' }} icon={icon} />
      </Box>
      <ListItemText>{label}</ListItemText>
    </Box>
  );
};

export default observer(UserSection);
