import { ReactNode } from 'react';
import { Box, Container, Paper, CircularProgress, Typography } from '@material-ui/core';
import logo from 'images/pig_green_white.svg';

import { rootStore } from 'containers/App/App';
import useStyles from './styles';
import { observer } from 'mobx-react';

export type TAnonLayoutIcon = { src: string; mt?: number } | JSX.Element;
interface AnonLayoutProps {
  children: ReactNode;
  inProgress?: boolean;
  applyFooter?: boolean;
  icon?: TAnonLayoutIcon;
  iconMargin?: number;
  padding?: number;
  hideIcon?: boolean;
}
/**
 * The layout wrapper to use for screens that are anon-only, so
 * login, signup, etc.
 */
function AnonLayout({
  children,
  inProgress = false,
  applyFooter = false,
  icon,
  iconMargin,
  padding,
  hideIcon,
}: AnonLayoutProps) {
  const mobileView = rootStore.uiStore.mobileView;
  const pt = icon && 'mt' in icon ? icon.mt : undefined;
  const classes = useStyles({ mobileView, pt, iconMargin });
  const style = mobileView ? { minHeight: '100%' } : undefined;

  return (
    <Box className={classes.root} style={style}>
      <Icon icon={icon} hideIcon={hideIcon} applyFooter={applyFooter} className={classes.pig} />
      <Container className={classes.container}>
        <Paper className={classes.paper}>
          <Box className={classes.insideBox} p={padding}>
            {inProgress && (
              <Box className={classes.loader}>
                <CircularProgress />
              </Box>
            )}
            {children}
          </Box>
        </Paper>
      </Container>
      {applyFooter && (
        <Box className={classes.footer}>
          <Box pt={3}>
            <Typography align="center" variant="subtitle1">
              If you have any questions or concerns, don’t hesitate to contact your Tippy Success
              Agent at 1.855.831.2323 or support@meettippy.com.
            </Typography>
          </Box>
          <Box pt={2}>
            <Typography align="center" variant="subtitle1">
              Made by Direct Tips Operations LLC | 2755 E Oakland Park Blvd. Ste 300, Ft.
              Lauderdale, FL 33306
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

interface IIcon {
  icon?: TAnonLayoutIcon;
  className: string;
  applyFooter: boolean;
  hideIcon?: boolean;
}
function Icon(props: IIcon): any {
  const { icon, className, applyFooter, hideIcon } = props;
  if (hideIcon) return null;
  if (icon && !('src' in icon)) return icon;
  return (
    <img
      src={icon?.src || logo}
      alt="Tippy"
      className={className}
      style={{ marginTop: applyFooter ? 'auto' : 'none' }}
    />
  );
}

export default observer(AnonLayout);
