import React from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { inject, WithUserStore, WithToastStore } from 'stores';

import DashboardLayout from 'containers/DashboardLayout';
import styles from './styles';

import { setTitle } from 'services';

import DownloadCSVReport from './DownloadCSVReport/DownloadCSVReport';
import Title from 'components/Title';
import ReportCard from './ReportCard/ReportCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileInvoice,
  faFileInvoiceDollar,
  faFileLines,
  faFileSpreadsheet,
} from '@fortawesome/pro-regular-svg-icons';

const PAGE_TITLE = 'Reports';
/** Here we define what kind of props this component takes */
type LocationsProps = WithStyles<typeof styles> & // Adds the classes prop
  WithUserStore & // Adds the userStore prop
  WithToastStore;

export enum ReportType {
  REBATE_REPORT = 'rebate_report',
  PAYROLL_RUN_REPORT = 'payroll_run_report',
  TIPPY_SALON_REPORT = 'tippy_salon_report',
  DEVICES_INVENTORY = 'devices_inventory',
}
@inject('userStore', 'toastStore')
@observer
class Reports extends React.Component<LocationsProps> {
  constructor(props: LocationsProps) {
    super(props);
    makeObservable(this);
  }

  @observable open = false;
  @observable isGlobalOwner = this.props.userStore!.scope.kind === 'global_owner';
  @observable reportType?: ReportType;

  @action.bound togglePreferences = () => {
    this.open = !this.open;
  };

  @action.bound closeDownloadCSVReport = () => {
    this.reportType = undefined;
  };

  @action.bound setReportType = (reportType: ReportType) => {
    this.reportType = reportType;
  };

  renderReportPreferencesDialog = () => {
    const isAdmin = this.props.userStore?.scope.kind === 'admin';
    const isOwner = this.props.userStore?.scope.kind === 'owner';
    const isManager = this.props.userStore?.isManager;
    const reportType = this.reportType;
    let accountFilter = false;
    let locationFilter = false;
    let hardwareTypeFilter = false;
    let title = '';
    switch (reportType) {
      case ReportType.PAYROLL_RUN_REPORT:
        title = `Payroll run report`;
        accountFilter = isAdmin ? true : false;
        locationFilter = isAdmin || isOwner || isManager ? true : false;
        break;
      case ReportType.TIPPY_SALON_REPORT:
        title = `Tippy salon report`;
        break;
      case ReportType.REBATE_REPORT:
        title = `Rebate report`;
        accountFilter = this.isGlobalOwner || isAdmin;
        break;
      case ReportType.DEVICES_INVENTORY:
        title = `Devices Inventory`;
        locationFilter = isAdmin ? true : false;
        hardwareTypeFilter = isAdmin ? true : false;
        // accountFilter = isAdmin ? true : false;
        break;
    }
    return (
      <DownloadCSVReport
        title={title}
        scope={this.props.userStore?.scope.kind}
        reportType={reportType}
        onClose={this.closeDownloadCSVReport}
        accountFilter={accountFilter}
        locationFilter={locationFilter}
        hardwareTypeFilter={hardwareTypeFilter}
      />
    );
  };

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  render() {
    const isOwner = this.props.userStore!.isOwner;
    const isGlobalOwner = this.props.userStore!.isGlobalOwner;
    const isAdmin = this.props.userStore!.isAdmin;
    return (
      <DashboardLayout>
        <Box>
          <Title mb={3}>{PAGE_TITLE}</Title>
          <Box>
            <Grid container spacing={3} alignItems="stretch" alignContent="stretch">
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <ReportCard
                  icon={faFileInvoiceDollar}
                  name="PAYROLL RUN REPORT"
                  description="Use this report to share employees income received with your payroll
                    company. Payroll reports will reflect any refunds or adjustments in the
                    selected date range."
                  onClick={() => this.setReportType(ReportType.PAYROLL_RUN_REPORT)}
                />
              </Grid>
              {(isOwner || isGlobalOwner || isAdmin) && (
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <ReportCard
                    iconColor={'yellow'}
                    icon={faFileSpreadsheet}
                    name="REBATE REPORT"
                    description="Download this rebate report for desired time range."
                    onClick={() => this.setReportType(ReportType.REBATE_REPORT)}
                  />
                </Grid>
              )}

              {isAdmin && (
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <ReportCard
                    iconColor={'purple'}
                    icon={faFileInvoice}
                    name="INVENTORY REPORT"
                    description="Data Inventory Devices"
                    onClick={() => this.setReportType(ReportType.DEVICES_INVENTORY)}
                  />
                </Grid>
              )}
              {isAdmin && (
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <ReportCard
                    icon={faFileLines}
                    name="TIPPY SALON REPORT"
                    description="Salon report description"
                    onClick={() => this.setReportType(ReportType.TIPPY_SALON_REPORT)}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
        {this.reportType && this.renderReportPreferencesDialog()}
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Reports);
