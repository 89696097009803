import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    buttonLink: {
      fontSize: 14,
      border: 'none',
      textDecoration: 'underline',
      textTransform: 'none',
      padding: 0,
      height: 'auto',
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    button: {
      fontSize: 18,
      fontWeight: 400,
      textTransform: 'none',
      '&:hover': {
        background: 'none',
      },
    },
    filterBox: {
      display: 'flex',
      '& div': {
        marginLeft: 'auto',
        minWidth: '250px',
      },
    },
    greyText: {
      color: theme.palette.grey[500],
    },
    institutionLogo: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginRight: theme.spacing(2),
    },
    alignRight: {
      marginLeft: 'auto',
    },
    chargeLink: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    chargeButton: {
      marginLeft: theme.spacing(4),
    },
    spinner: {
      textAlign: 'center',
    },
    selectInput: {
      fontSize: '16px',
      '& .MuiSelect-selectMenu': {
        display: 'flex',
        '& span:nth-of-type(2)': {
          marginLeft: 'auto',
        },
      },
    },
    backdrop: {
      backgroundColor: theme.palette.background.default,
      zIndex: theme.zIndex.drawer + 1,
    },
    stepList: {
      paddingLeft: theme.spacing(2),
    },
    workspaceRoot: {},
    divider: {
      margin: '24px 0',
    },
    label: {
      backgroundColor: 'lightgray',
      padding: '8px 24px',
      borderRadius: '18px',
      textTransform: 'uppercase',
    },
    rebateCancelText: {
      marginTop: theme.spacing(2),
    },
    rebateTextSpacingSmall: {
      marginTop: theme.spacing(2),
    },
    rebateSpacingMedium: {
      marginTop: theme.spacing(6),
    },
    rebateSpacingBottomMedium: {
      marginBottom: theme.spacing(6),
    },
    rebateSpacingBig: {
      marginTop: theme.spacing(8),
    },
    rebateCancelInput: {
      marginTop: theme.spacing(4),
    },
    pig: {
      height: theme.spacing(4),
    },
    primaryBackgroundColor: {
      background: theme.palette.primary.main,
    },
    enrollAvatar: {
      marginRight: theme.spacing(1),
      height: '40px',
      width: '40px',
    },
    marginRightSmall: {
      marginRight: theme.spacing(1),
    },
    enrollBackdrop: {
      position: 'absolute',
      background: 'transparent',
    },
    pendingIntegrationsDialog: {
      background: 'transparent',
      padding: '10px',
    },
    pendingIntegrationsDialogContent: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '25px',
      height: '100px',
    },
    dialogTitle: {
      padding: theme.spacing(3),
      paddingBottom: 0,
    },
    dialogActions: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(5),
    },
    dialogContent: {
      padding: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      position: 'relative',
    },
    pl0: {
      paddingLeft: 0,
    },
    checkbox: {
      padding: 0,
      paddingRight: theme.spacing(1),
    },
    availableItem: {
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
    },
    cardWrapper: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cardActions: {
      marginTop: 'auto',
      paddingTop: theme.spacing(4),
      width: '100%',
    },
    cancelButton: {
      marginTop: theme.spacing(3),
    },
    bankAccountCardBody: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxHeight: '300px',
      overflowY: 'scroll',
      position: 'relative',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      flexGrow: 1,
    },
    subtitle3: {
      fontSize: theme.typography.subtitle3.fontSize,
      color: theme.typography.subtitle3.color,
    },
    addBankButton: {
      background: 'none',
      color: theme.palette.primary.main,
      height: 'auto',
      padding: 0,
      '&:hover': {
        background: 'none',
      },
    },
    bankAccountItem: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    successIcon: {
      color: theme.palette.primary.main,
      width: '170px !important',
      height: '170px !important',
    },
    gridItem: {
      width: 'calc(50% - 12px)',
      flexGrow: 1,
      minWidth: '268px',
      [theme.breakpoints.down('md')]: { width: '100%' },
    },
  });
