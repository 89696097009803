import React from 'react';
import { observable, action, computed, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import Api, { getErrorMsg } from 'api';

import { Box, Dialog, IconButton, Tooltip, Typography } from '@material-ui/core';
import { CheckCircleOutline, CircleOutline, Plus, AlertOutline, Pencil } from 'mdi-material-ui';

import DP from 'components/DashPanel';
import { PaymentMethod, BillingEntity, PaymentMethodAction } from 'models';
import { inject, WithToastStore, WithModalStore } from 'stores';
import { createStripe } from 'services/stripe';

import styles, { useStyles } from './styles';

import AddPaymentMethod from './AddPaymentMethod';
import CreditCardIcon from 'components/CreditCardIcon';
import moment from 'moment';
import { EmptyPanelMessage } from 'components/EmptyPanelMessage/EmptyPanelMessage';

/** Represents a payment method in the list */
export const PaymentMethodItem = observer(
  ({
    children,
    itemProps,
    removePaymentMethod,
    editPaymentMethod,
    sendUpdateRequest,
    isAdmin,
    flexSecondary,
  }: {
    flexSecondary?: boolean;
    children: BillingEntity;
    itemProps?: ItemProps;
    isAdmin?: boolean;
    removePaymentMethod?: (billingEntityId: number) => void;
    editPaymentMethod?: (billingEntityId: number) => void;
    sendUpdateRequest?: (billingEntityId: number) => void;
  }) => {
    const classes = useStyles();

    const billingEntity = children as BillingEntity & {
      needsUpdating?: boolean;
    };
    const { paymentMethod } = billingEntity;
    const menu = [
      {
        label: <Typography>Edit</Typography>,
        onClick: () => editPaymentMethod && editPaymentMethod(billingEntity.id),
      },
      {
        label: <Typography color="error">Remove</Typography>,
        onClick: () => removePaymentMethod && removePaymentMethod(billingEntity.id),
      },
    ];

    if (isAdmin) {
      menu.push({
        label: <Typography>Send update request</Typography>,
        onClick: () => sendUpdateRequest && sendUpdateRequest(billingEntity.id),
      });
    }

    const brand = paymentMethod.brand || 'Other';
    const lastFour = paymentMethod.lastFour;
    const validThru = paymentMethod.validThru;
    const formattedAddDate = moment(paymentMethod.createdAt).format('MM/DD/YYYY');
    const [month, year] = (paymentMethod.validThru || '').split('/');
    let secondaryText = null;
    if (month && year) {
      const expirationDate = new Date();
      expirationDate.setMonth(parseInt(month) - 1);
      expirationDate.setFullYear(parseInt(year));

      const isExpired = new Date() > expirationDate;
      secondaryText = `${isExpired ? 'Expired' : 'Expires'} on ${validThru}`;
    }

    const addDate = `Added on ${formattedAddDate}`;
    return (
      <DP.ListItem
        key={paymentMethod.id}
        icon={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ fontSize: '21px' }}>
            <CreditCardIcon brand={brand.toLowerCase()} />
          </Box>
        }
        primary={
          <Box display="flex" flexDirection="row">
            <Typography>
              {brand} {lastFour}
            </Typography>
            {billingEntity.needsUpdating && (
              <Box ml={1} display="flex" flexDirection="row" alignItems="center">
                <Tooltip title={'Warning! Payment method needs to be updated'}>
                  <AlertOutline fontSize="small" color="error" />
                </Tooltip>
                <IconButton
                  size="small"
                  onClick={() => editPaymentMethod && editPaymentMethod(billingEntity.id)}>
                  <Pencil color="primary" fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
        }
        secondary={
          <Box
            paddingRight="2rem"
            color={billingEntity.isExpired && billingEntity.isExpired ? 'red' : ''}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography>{secondaryText}</Typography>
              <Typography className={classes.textTertiary}>{addDate}</Typography>
            </Box>
          </Box>
        }
        className={
          flexSecondary ? { containerSecondary: classes.listTextSecondaryFlex } : undefined
        }
        menu={itemProps && itemProps?.hideMenu !== true ? menu : undefined}
        rightIcon={
          itemProps &&
          itemProps!.selected &&
          (itemProps!.selected === paymentMethod.id ? (
            <CheckCircleOutline color="primary" />
          ) : (
            <CircleOutline color="primary" />
          ))
        }
        onClick={
          itemProps && itemProps!.onItemClick
            ? () => {
                itemProps!.onItemClick!(paymentMethod);
              }
            : undefined
        }
      />
    );
  },
);

interface ItemProps {
  selected?: number;
  hideMenu?: boolean;
  rightIcon?: JSX.Element;
  onItemClick?(paymentMethod: PaymentMethod): void;
}

interface PaymentMethodsPanelProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithModalStore {
  accountId: number;
  panelName?: string;
  hideCount?: boolean;
  hidePaper?: boolean;
  itemProps?: ItemProps;
  onAdd?: (sourceId: string) => void;
  onUpdate?: (sourceId: string, billingEntityId: number) => void;
  paymentMethods?: (BillingEntity & { needsUpdating?: boolean })[];
  isAdmin?: boolean;
  refetchPaymentMethods?: VoidFunction;
}

@inject('toastStore', 'modalStore')
@observer
class PaymentMethodsPanel extends React.Component<PaymentMethodsPanelProps> {
  constructor(props: PaymentMethodsPanelProps) {
    super(props);
    makeObservable(this);
  }
  private panelName = 'Payment Methods';

  @observable public inProgress = true;

  @observable public billingEntitiesResp?: BillingEntity[];

  @computed private get billingEntities() {
    return this.props.paymentMethods || this.billingEntitiesResp;
  }

  // If one of the payment methods is due for update,
  // disable 'add payment method' icon button so owners
  // don't get confused and accidentally add a new entity
  @computed private get addPaymentMethodDisabled() {
    const bList = this.billingEntities as (BillingEntity & {
      needsUpdating?: boolean;
    })[];
    return bList ? bList.filter((b) => b.needsUpdating).length > 0 : false;
  }

  @observable public stripeLoaded = false;

  @observable public showingAddPaymentMethodDialog = false;

  @observable public editingBillingEntityId: number | null = null;

  @action.bound public getPaymentMethods = flow(function* (this: PaymentMethodsPanel) {
    try {
      this.inProgress = true;
      const accountId = this.props.accountId;
      const resp = yield Api.billing.getPaymentMethods(accountId);
      if (resp && resp.data) {
        this.billingEntitiesResp = resp.data.data;
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.inProgress = false;
    }
  });

  @action.bound public showAddPaymentMethod() {
    this.showingAddPaymentMethodDialog = true;
  }

  @action.bound public hideAddPaymentMethod() {
    this.showingAddPaymentMethodDialog = false;
    if (this.editingBillingEntityId) {
      this.editingBillingEntityId = null;
    }
  }

  @action.bound public setStripeLoaded() {
    this.stripeLoaded = true;
  }

  @action.bound public addPaymentMethod = flow(function* (
    this: PaymentMethodsPanel,
    sourceId: string,
  ) {
    const accountId = this.props.accountId;

    try {
      yield Api.billing.addPaymentMethod(sourceId, accountId);
      this.props.onAdd && this.props.onAdd(sourceId);
      yield this.getPaymentMethods();
      this.hideAddPaymentMethod();
      this.props.toastStore!.success('Payment method successfully added');
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.hideAddPaymentMethod();
    }
  });

  @action.bound public editPaymentMethod = flow(function* (
    this: PaymentMethodsPanel,
    sourceId: string,
  ) {
    if (this.editingBillingEntityId) {
      const accountId = this.props.accountId;
      try {
        yield Api.billing.editPaymentMethod(sourceId, accountId, this.editingBillingEntityId);
        yield this.getPaymentMethods();
        this.props.refetchPaymentMethods && this.props.refetchPaymentMethods();
        this.hideAddPaymentMethod();
        this.props.toastStore!.success('Payment method successfully added');
      } catch (e: any) {
        this.props.toastStore!.error(getErrorMsg(e));
        this.hideAddPaymentMethod();
      }
    }
  });

  @action.bound public editBillingEntity = (billingEntityId: number) => {
    this.editingBillingEntityId = billingEntityId;
    this.showAddPaymentMethod();
  };

  @action.bound public removePaymentMethod = flow(function* (
    this: PaymentMethodsPanel,
    billingEntityId: number,
  ) {
    const accountId = this.props.accountId;
    try {
      const confirm = yield this.props.modalStore!.confirm(
        'Remove Payment Method',
        `Are you sure you want to remove this payment method?`,
        { confirmLabel: 'Yes', cancelLabel: 'No' },
      );
      if (confirm) {
        yield Api.billing.removePaymentMethod(accountId, billingEntityId);
        this.props.refetchPaymentMethods && this.props.refetchPaymentMethods();
        this.billingEntitiesResp = this.billingEntitiesResp!.filter(
          (method) => method.id !== billingEntityId,
        );
        this.props.toastStore!.success('Payment method successfully removed');
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public sendUpdateRequest = flow(function* (
    this: PaymentMethodsPanel,
    billingEntityId: number,
  ) {
    const accountId = this.props.accountId;
    try {
      const confirm = yield this.props.modalStore!.confirm(
        'Send an email to owner',
        `Are you sure you want to send an email to edit this payment method?`,
        { confirmLabel: 'Send', cancelLabel: 'Cancel' },
      );
      if (confirm) {
        yield Api.billing.updateCreditCard(accountId, billingEntityId);
        this.props.refetchPaymentMethods && this.props.refetchPaymentMethods();
        this.props.toastStore!.success('Email successfully sent');
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @computed private get count() {
    const hideCount = this.props.hideCount || false;
    if (hideCount) {
      return undefined;
    }
    return this.billingEntities && this.billingEntities.length;
  }

  @computed get numberOfBillingEntities() {
    if (this.billingEntities) {
      return this.billingEntities.length;
    } else {
      return 0;
    }
  }

  componentDidMount() {
    this.panelName = this.props.panelName || this.panelName;

    if (!this.props.paymentMethods) {
      this.getPaymentMethods();
    } else {
      this.billingEntitiesResp = this.props.paymentMethods;
      this.inProgress = false;
    }
    createStripe().then(this.setStripeLoaded);
  }

  render() {
    const { hidePaper = false, itemProps } = this.props;

    return (
      <>
        <Dialog open={this.showingAddPaymentMethodDialog} onClose={this.hideAddPaymentMethod}>
          {this.stripeLoaded && (
            <AddPaymentMethod
              onSourceId={
                this.editingBillingEntityId ? this.editPaymentMethod : this.addPaymentMethod
              }
              onCancel={this.hideAddPaymentMethod}
              method={
                this.editingBillingEntityId ? PaymentMethodAction.EDIT : PaymentMethodAction.ADD
              }
            />
          )}
        </Dialog>
        <DP hidePaper={hidePaper}>
          <DP.Header>
            <DP.Title count={this.count} light>
              {this.panelName}
            </DP.Title>
            <DP.Actions>
              {this.props.onAdd && !this.addPaymentMethodDisabled && (
                <DP.IconButton
                  icon={Plus}
                  primary
                  tooltip="Add payment method"
                  onClick={this.showAddPaymentMethod}
                />
              )}
            </DP.Actions>
          </DP.Header>

          {this.inProgress ? (
            <DP.List>
              <DP.Loading items={5} variant="circleWithTwoLines" />
            </DP.List>
          ) : this.billingEntities && this.billingEntities?.length > 0 ? (
            <Box>
              <DP.List height={this.numberOfBillingEntities < 5 ? 'short' : 'normal'}>
                {this.billingEntities &&
                  this.billingEntities.map((billingEntity) => (
                    <PaymentMethodItem
                      key={billingEntity.id}
                      itemProps={itemProps || {}}
                      removePaymentMethod={this.removePaymentMethod}
                      editPaymentMethod={this.editBillingEntity}
                      sendUpdateRequest={this.sendUpdateRequest}
                      isAdmin={this.props.isAdmin}>
                      {billingEntity}
                    </PaymentMethodItem>
                  ))}
              </DP.List>
            </Box>
          ) : (
            <DP.Body>
              <Box display={'flex'} alignContent={'center'}>
                <EmptyPanelMessage panelTitle={this.panelName} />
              </Box>
            </DP.Body>
          )}
        </DP>
      </>
    );
  }
}

export default withStyles(styles)(PaymentMethodsPanel);
