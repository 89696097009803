import { OutlinedInputVariant } from 'components/Input/OutlinedInput/OutlinedInput';
import { UseControllerProps } from 'react-hook-form';

/**
 * Form fields Names
 */
export enum EFormFields {
  EMAIL = 'email',
  NAME = 'name',
  PHONE = 'phone',
}

/**
 * Form fields Labels
 */
export enum EFormFieldLabels {
  EMAIL = 'Email',
  FIRST_NAME = 'First Name',
  NAME = 'Name',
  PHONE = 'Phone',
}

/**
 * Form field Config, used in Form Builder
 */
export interface IFormFieldsConfig {
  name: string;
  rules?: UseControllerProps['rules'];
  label: string;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: OutlinedInputVariant;
  size?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}

/**
 * Form fields Error Messages
 */
export const ErrorMessagesForms = {
  FIELD_REQUIRED: (field: EFormFieldLabels) => `${field} is required.`,
  EMAIL_NOT_VALID: 'Email is not valid',
  PASSWORDS_NO_MATCH: 'The passwords do not match.',
  PHONE_NOT_VALID: 'Phone number is not valid',
  TEXT_NOT_VALID: 'Confirmation text is not valid',
  URL_NOT_VALID: 'Not valid URL',
};
