import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { observable, action, computed, toJS, makeObservable } from 'mobx';

import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { paths } from 'routes';

import { User, Location } from 'models';
import { inject, WithUserStore, WithToastStore } from 'stores';

import { RequestMetaData, PagedApiResponse } from 'api';

import DP from 'components/DashPanel';
import UsersTab from './UsersTab';

import styles from './styles';
import { AxiosResponse } from 'axios';
import { Box, Divider, IconButton } from '@material-ui/core';
import TabBar, { TabVariant } from 'components/TabBar/TabBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons';

type TabType = 'talent' | 'managers' | 'owners';
/**
 * Represents a list of users of a certain type. A tab will
 * appear for each of those.
 */
interface UsersPanelProps extends WithStyles<typeof styles>, WithUserStore, WithToastStore {
  variant?: TabVariant;
  location?: Location;
  fullHeight?: boolean;
  fullWidth?: boolean;
  displayInviteManagersButton?: boolean;
  fetchTalents?: (
    meta?: RequestMetaData,
    search?: string,
  ) => Promise<AxiosResponse<PagedApiResponse<User>>>;
  talent?: User[];
  fetchManagers?: (
    meta?: RequestMetaData,
    search?: string,
  ) => Promise<AxiosResponse<PagedApiResponse<User>>>;
  managers?: User[];
  fetchOwners?: (
    meta?: RequestMetaData,
    search?: string,
  ) => Promise<AxiosResponse<PagedApiResponse<User>>>;
  owners?: User[];
}

/**
 * Renders tab bar with three tabs (talent, managers, owners) and container for selected tab.
 */
@inject('userStore', 'toastStore')
@observer
class UsersPanel extends React.Component<UsersPanelProps> {
  constructor(props: UsersPanelProps) {
    super(props);
    makeObservable(this);
  }
  /** The index of the currently selected group of users */
  @observable private selectedTab: TabType = 'talent';

  /** Is invite manager modal open?  */
  @observable public managerInviteOpen = false;

  @computed public get showOwners(): boolean {
    if (!this.props.fetchOwners && !this.props.owners) {
      return false;
    }
    return true;
  }

  /** If current user can send email invitation to onboard a new manager */
  @computed public get canSendInvite(): boolean {
    const isAdmin = this.props.userStore!.authUser.isAdmin;
    const isOwner = this.props.userStore!.scope.kind === 'owner';
    return Boolean((isOwner || isAdmin) && this.props.displayInviteManagersButton);
  }

  @computed public get tabs() {
    return [
      {
        label: 'Employees',
        onClick: this.selectTab('talent'),
        selected: this.selectedTab === 'talent',
      },
      {
        label: 'Managers',
        onClick: this.selectTab('managers'),
        selected: this.selectedTab === 'managers',
      },
      {
        label: 'Owners',
        onClick: this.selectTab('owners'),
        selected: this.selectedTab === 'owners',
        hide: !this.showOwners,
      },
    ];
  }

  /** Selects a tab */
  @action.bound private selectTab = (value: TabType) => () => {
    this.selectedTab = value;
  };

  renderTalentTab() {
    if (this.props.fetchTalents) {
      return <UsersTab fetch={this.props.fetchTalents} pageSize={20} type="talent" />;
    }
    return <UsersTab>{this.props.talent}</UsersTab>;
  }

  renderManagersTab() {
    if (this.props.fetchManagers) {
      return <UsersTab fetch={this.props.fetchManagers} pageSize={20} />;
    }
    return <UsersTab>{this.props.managers}</UsersTab>;
  }

  renderOwnersTab() {
    if (this.props.fetchOwners) {
      return <UsersTab fetch={this.props.fetchOwners} pageSize={20} />;
    }
    return <UsersTab>{this.props.owners}</UsersTab>;
  }

  render() {
    const { fullHeight, fullWidth } = this.props;

    return (
      <DP fullHeight={fullHeight} fullWidth={fullWidth}>
        <DP.Header>
          <DP.Title panel>Users</DP.Title>
          {this.canSendInvite && (
            <IconButton
              size="small"
              color="primary"
              component={RouterLink}
              to={{
                pathname: paths.users().invite(),
                state: { location: toJS(this.props.location) },
              }}>
              <FontAwesomeIcon height={18} icon={faUserPlus} />
            </IconButton>
          )}
        </DP.Header>
        <Box mt={2} mb={2}>
          <TabBar
            variant={TabVariant.UNDERLINE}
            className={this.props.classes.tabBar}
            tabs={this.tabs}
            center
          />
        </Box>
        <Divider />
        {this.selectedTab === 'talent' && this.renderTalentTab()}
        {this.selectedTab === 'managers' && this.renderManagersTab()}
        {this.selectedTab === 'owners' && this.showOwners && this.renderOwnersTab()}
      </DP>
    );
  }
}

export default withStyles(styles)(UsersPanel);
