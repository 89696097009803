import { Avatar, Box, Button, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Api, { ApiResponse } from 'api';
import { AxiosResponse } from 'axios';
import DP from 'components/DashPanel';
import TippyPlaidLink from 'components/PlaidLink';
import { DotsVertical } from 'mdi-material-ui';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { BankAccount } from 'models';
import moment from 'moment';
import React from 'react';
import { BankStatus, getStatus, statusMessages } from 'services/banks';
import { inject, WithModalStore, WithToastStore, WithUserStore } from 'stores';
import styles from './styles';
import PrimaryBadge from 'components/PrimaryBadge';

interface BankAccountPanelProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithUserStore,
    WithModalStore {
  children: BankAccount;
  isPrimary: boolean;
  onSetAsPrimary?: () => void;
  fetchBankAccounts?: () => void;
}

/**
 * Displays a panel for a single bank account
 */
@inject('toastStore', 'userStore', 'modalStore')
@observer
class BankAccountPanel extends React.Component<BankAccountPanelProps> {
  constructor(props: BankAccountPanelProps) {
    super(props);
    makeObservable(this);
  }

  @observable public fetchingToken = false;
  @observable public activatingAccount = false;
  @observable public makingPrimary = false;
  @observable public token?: string;
  @observable public menuOpen: HTMLElement | null = null;
  componentDidMount() {
    this.fetchBankAccount();
  }
  /**
   * Fetches this same bank account from the API. We need this because we
   * also get a public token in the repsonse, which we need to continue
   * with verification.
   */
  @action.bound public fetchBankAccount = flow(function* (this: BankAccountPanel) {
    // but we only do this if user needs to interact with bank account for verification via plaid
    if (this.status === 'pending_manual_verification' || this.status === 'action_required') {
      try {
        this.fetchingToken = true;
        const resp: AxiosResponse<ApiResponse<BankAccount>> = yield Api.core.getBankAccount(
          this.props.children.id,
        );
        if (resp.data.data) {
          this.token = resp.data.data.linkToken;
        }
      } finally {
        this.fetchingToken = false;
      }
    }
  });

  /** Activates a bank account after verification */
  @action.bound public activateBankAccount = flow(function* (this: BankAccountPanel) {
    // Tell the API that the bank account can now be marked as active
    try {
      this.activatingAccount = true;
      yield Api.core.activateBankAccountV2(this.props.children.id);
      this.props.toastStore!.push({ type: 'success', message: 'Account successfully verified!' });
      this.props.fetchBankAccounts && this.props.fetchBankAccounts();
    } finally {
      this.activatingAccount = false;
    }
  });

  /**
   * Sets the bank account as primary
   */
  @action.bound public makePrimary = flow(function* (this: BankAccountPanel) {
    try {
      this.makingPrimary = true;
      this.menuOpen = null;
      yield Api.core.makeBankAccountPrimary(this.props.children.id);
      if (this.props.onSetAsPrimary) {
        this.props.onSetAsPrimary();
      }
      this.props.toastStore!.success('Bank account set as primary!');
      this.props.fetchBankAccounts && this.props.fetchBankAccounts();
    } finally {
      this.makingPrimary = false;
    }
  });

  @action.bound public handleOpenMenu(event: React.MouseEvent<HTMLButtonElement>) {
    this.menuOpen = event.currentTarget;
  }

  @action.bound public handleCloseMenu() {
    this.menuOpen = null;
  }

  /** The current status of the bank account component */
  @computed get status(): BankStatus {
    const bankAccount = this.props.children!;
    return getStatus(bankAccount);
  }

  /** Gets the status message for displaying in DP.Value */
  @computed get statusMsg(): string {
    return statusMessages[this.status].msg;
  }

  /** Gets the tooltip based on the current status */
  @computed get tooltip(): string {
    return statusMessages[this.status].tooltip;
  }

  /** The indicator color based on the status */
  @computed get indicatorColor(): 'red' | 'green' | 'grey' {
    if (this.status === 'active') {
      return 'green';
    } else if (this.status === 'inactive') {
      return 'red';
    } else {
      return 'grey';
    }
  }

  /** Whether things are loading in this component */
  @computed get loading(): boolean {
    return this.fetchingToken || this.activatingAccount || this.makingPrimary;
  }

  /**
   * The text for the butotn that triggers the Plaid Link interface.
   */
  @computed get actionText(): string | undefined {
    if (this.status === 'action_required') {
      return 'Log in';
    }
    if (this.status === 'pending_manual_verification') {
      return 'Verify';
    }
    return undefined;
  }

  @computed public get showPlaidLink(): boolean {
    return this.status === 'pending_manual_verification' || this.status === 'action_required';
  }

  @computed public get showMakePrimary(): boolean {
    return this.props.children.isPrimary === false && this.status === 'active';
  }

  @computed public get showActionsMenu(): boolean {
    return this.showMakePrimary;
  }

  @computed public get showChip(): boolean {
    return !this.showPlaidLink && this.props.isPrimary;
  }

  renderActionsMenu() {
    return (
      <>
        <IconButton size="small" onClick={this.handleOpenMenu}>
          <DotsVertical fontSize="small" />
        </IconButton>
        <Menu anchorEl={this.menuOpen} open={Boolean(this.menuOpen)} onClose={this.handleCloseMenu}>
          {this.showMakePrimary && <MenuItem onClick={this.makePrimary}>Set as primary</MenuItem>}
        </Menu>
      </>
    );
  }

  renderChip() {
    const classes = this.props.classes;
    return <PrimaryBadge />;
  }

  renderPlaidLink() {
    if (this.status !== 'pending_manual_verification' && this.status !== 'action_required') {
      return null;
    }
    if (!this.token || this.loading) {
      return <DP.LoadSpinner />;
    }
    return (
      <TippyPlaidLink token={this.token} onSuccess={this.activateBankAccount}>
        <Button variant="contained" color="primary" size="small">
          {this.actionText}
        </Button>
      </TippyPlaidLink>
    );
  }
  render() {
    const classes = this.props.classes;
    const bankAccount = this.props.children;
    return (
      <DP>
        <DP.Header>
          <Box display="flex" flexDirection="row" alignItems="center">
            {bankAccount.institutionLogo && (
              <Avatar
                className={classes.institutionLogo}
                src={`data:image/png;base64,${bankAccount.institutionLogo}`}
              />
            )}
            <Typography noWrap className={this.props.classes.headerTitle}>
              {bankAccount.accountName}
            </Typography>
          </Box>
          <DP.Actions>
            {this.loading ? (
              <DP.LoadSpinner />
            ) : (
              <>
                {this.showPlaidLink && this.renderPlaidLink()}
                {this.showChip && this.renderChip()}
                {this.renderChip()}
                {this.showActionsMenu && this.renderActionsMenu()}
              </>
            )}
          </DP.Actions>
        </DP.Header>
        <DP.Body>
          <DP.Row>
            <DP.Value>{moment(bankAccount.createdAt).format(`MMMM Do, YYYY`)}</DP.Value>
            <DP.Label>Linked on</DP.Label>
          </DP.Row>
          <DP.Row>
            <DP.Value>{bankAccount.accountMask}</DP.Value>
            <DP.Label>Last 4 digits</DP.Label>
          </DP.Row>
          <DP.Row>
            <DP.Value>
              <span className={classes.status}>{this.statusMsg}</span>
            </DP.Value>
            <DP.Label>Status</DP.Label>
          </DP.Row>
        </DP.Body>
      </DP>
    );
  }
}

export default withStyles(styles)(BankAccountPanel);
