import {
  ButtonBase,
  ButtonBaseProps,
  ButtonGroup,
  ButtonGroupProps,
  LinkProps,
} from '@material-ui/core';
import { useStyles } from './styles';
import clsx from 'clsx';
import React from 'react';
import { slideIntoView } from './helpers';
import theme from 'containers/App/theme';
import useTabBar from './useTabBar';
import { generateDataCy } from 'utils/helper';

export enum TabVariant {
  CONTAINED = 'contained',
  UNDERLINE = 'underline',
}

export type TTabs = ITab[];

interface ITab extends ButtonBaseProps {
  label: string;
  selected: boolean;
  hide?: boolean;
  dataCy?: string;
  component?: React.FC<LinkProps> | React.ElementType;
  to?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export type TTabBarProps = {
  tabs: ITab[];
  center?: boolean;
  gap?: number;
  mb?: number;
  hide?: boolean;
  variant?: TabVariant;
} & Pick<ButtonGroupProps, 'className' | 'style'>;
const TabBar = ({
  tabs,
  center,
  gap,
  mb,
  hide,
  variant = TabVariant.CONTAINED,
  className,
  style,
}: TTabBarProps) => {
  const { draggingProps, buttonGroupRef, getButtonBaseRef } = useTabBar({ tabs });
  const { buttonGroup, tabBase, contained, underline } = useStyles({
    center,
    gap,
  });
  const variantStyle = variant === TabVariant.CONTAINED ? contained : underline;
  const marginBottom = mb ? theme.spacing(mb) : undefined;

  if (hide) return null;

  return (
    <ButtonGroup
      ref={buttonGroupRef}
      {...draggingProps}
      fullWidth
      className={clsx(buttonGroup, className)}
      style={{ marginBottom, ...style }}>
      {tabs.map(({ label, dataCy, selected, hide, ...rest }, index) => {
        if (hide) return null;

        const ref = getButtonBaseRef(index);
        return (
          <ButtonBase
            draggable={false}
            innerRef={ref}
            key={label}
            data-cy={dataCy || generateDataCy(label, 'tab button')}
            {...rest}
            onClick={(e) => {
              slideIntoView(ref, buttonGroupRef);
              rest.onClick && rest.onClick(e);
            }}
            className={clsx(tabBase, variantStyle, {
              selected: selected,
            })}>
            <span className="label">{label}</span>
          </ButtonBase>
        );
      })}
    </ButtonGroup>
  );
};

export default TabBar;
